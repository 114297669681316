import { UserData, orgData } from "../../utilities/types/types";

class Auth {
  /**
   * De-authenticate a user.
   * @description Removes a token from localStorage.
   */
  static deAuthenticateUser(): void {
    try {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("organization");
    } catch (error) {
      console.error("Error while de-authenticating user:", error);
    }
  }

  /**
   * Save current organization.
   * @description Save organization data in local storage.
   * @param org - Organization data to be stored.
   */
  static storeOrg(org: orgData): void {
    try {
      localStorage.setItem("organization", JSON.stringify(org));
    } catch (error) {
      console.error("Error while storing organization data:", error);
    }
  }

  static storeUser(user: UserData): void {
    try {
      localStorage.setItem("user", JSON.stringify(user));
    } catch (error) {
      console.error("Error while storing user data:", error);
    }
  }

  /**
   * Authenticate an organization.
   * @description Save organization data in local storage.
   * @param data - Authentication data containing organization data.
   */
  static authenticateOrg(data: { data: orgData }): void {
    try {
     
      const organization = data.data.name;
   
      localStorage.setItem("organization", organization);
     
      // CrossDomainStorage.sendMessage(data);
    } catch (error) {
      console.error("Error while authenticating organization:", error);
    }
  }

    static authenticateUser(data: { token: string; data: UserData; } ): void {

    try {
      const { token, data: userData } = data;
     
  
      localStorage.setItem("token", token);
      localStorage.setItem("user", JSON.stringify(userData));
      //  CrossDomainStorage.sendMessage(data);
    } catch (error) {
      console.error("Error while authenticating user:", error);
    }
  }
  

  /**
   * Check if a user is authenticated.
   * @returns {boolean} True if a token is saved in Local Storage, false otherwise.
   */
  static isUserAuthenticated(): boolean {
    try {
      const token = localStorage.getItem("token");

      return Boolean(token);
    } catch (error) {
      console.error("Error while checking user authentication:", error);
      return false;
    }
  }

  /**
   * Retrieve token from local storage.
   * @returns {string | boolean} User token if available, false otherwise.
   */
  static getToken(): string | boolean {
    try {
      const token = localStorage.getItem("token");
      return token || false;
    } catch (error) {
      console.error("Error while retrieving token:", error);
      return false;
    }
  }

  /**
   * Retrieve organization from local storage.
   * @returns {string | boolean} User organization if available, false otherwise.
   */
  static getOrganization(): string | boolean {
    try {
      const organization = localStorage.getItem("organization");
      return organization || false;
    } catch (error) {
      console.error("Error while retrieving organization:", error);
      return false;
    }
  }
}

export default Auth;
