import React from "react";
import { Button } from "../../../utilities/button";
import { ReactSVG } from "react-svg";
import success from "../../../asset/payrollSuccess.svg";
import { Link } from "react-router-dom";
import { Payroll } from "../../../utilities/types/types";

function RunPayrollHeader({
  isPayrollRun,
  totalAmount,
  payrollData,
}: {
  isPayrollRun: boolean;
  totalAmount: string;
  payrollData: Payroll;
}) {
  return (
    <div className="">
      {isPayrollRun ? (
        <div className="">
          <div className="">
            <p>{totalAmount}</p> <p>Total Payroll</p>
          </div>
          <div className="">
            <Button className="">Back to Dashboard</Button>
            <p> {payrollData.employeePayDate[0]}</p>
          </div>
        </div>
      ) : (
        <div className="flex justify-between px-8 py-6">
          <div className="">
            <div className="">
              <h3 className="text-[28px] font-medium pb-1">
                Bravo, you did it 🎉
              </h3>
              <p className="">
                You’ve successfully sent payroll for the month of{" "}
                {payrollData.payrollDate}
              </p>
            </div>
            <div className="">
              <div className="flex py-6 gap-14">
                <div className="py-4">
                  <p className="">Employee Pay Date</p>
                  <p className="text-2xl font-medium ">
                    {payrollData.employeePayDate}
                  </p>
                </div>
                <div className="py-4">
                  <p className="">Total Payroll</p>
                  <p className="text-2xl font-medium">{totalAmount}</p>
                </div>
              </div>
              <div className="border border-transparent bg-greenDark w-[179px] text-white mr-4 rounded-lg px-[16px] py-[10px]">
                <Link to={"/admin/dashboard"}>
                  <Button className="">Back to Dashboard</Button>
                </Link>
              </div>
            </div>
          </div>
          <div className="">
            <ReactSVG src={success} />
          </div>
        </div>
      )}
    </div>
  );
}

export default RunPayrollHeader;
