import { useEffect, useState } from "react";

import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import logoActive from "../asset/No-code Logo.svg";
import inActive from "../asset/openIcon .svg";
// import logoInactive from '../asset/smallLogo.svg';
import Active from "../asset/leftDoubleArrow.svg";
import Main from "./side/sideComponent/sideMain";
import MainInactive from "./side/sideComponent/mainInactive";
import { useAppDispatch, useAppSelector } from "../redux/hook/authHook";
import { getAdminInfo, setAdmin } from "../redux/slices/auth/adminDetails";

function Navbar() {
  const [active, setActive] = useState(false);
  const dispatch = useAppDispatch();

  const showMore = () => {
    setActive(true);
  };

  const showLess = () => {
    setActive(false);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(getAdminInfo());
        dispatch(setAdmin(response.payload));
      } catch (error) {
        // Handle error
      }
    };

    fetchData();
  }, [dispatch]);

  const admin = useAppSelector((state) => state.admin.admin);

  return (
    <div className={`navbar ${active ? "active" : ""} w-[80px]`}>
      <div
        className={`
          "animate duration-300 border-r border-gray-700 relative flex flex-col pt-5 max-h-screen group " 
          }`}
      >
        <div className="px-2 pb-7">
          {active ? (
            admin?.logo ? (
              <Link to="/admin/dashboard">
                <div className="pl-5">
                  <img
                    src={admin.logo}
                    alt="Logo"
                    className="  object-contain h-[88px] rounded-full w-40 "
                  />
                </div>
              </Link>
            ) : (
              <ReactSVG src={logoActive} />
            )
          ) : (
            <ReactSVG
              src={inActive}
              onClick={showMore}
              className="border border-[#475467] rounded-[4px] p-4"
            />
          )}
        </div>
        {active && (
          <ReactSVG
            src={Active}
            onClick={showLess}
            className="absolute text-2xl p-4 text-white border border-[#475467] rounded-[4px] cursor-pointer right-5 top-4  "
          />
        )}
        {active ? (
          <div>
            <Main />
          </div>
        ) : (
          <div>
            <MainInactive active={showMore} />
          </div>
        )}
      </div>
    </div>
  );
}
export default Navbar;
