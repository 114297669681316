import React, { useState } from "react";
import { FormikProps } from "formik";
import { ReactSVG } from "react-svg";
import { FormValues } from "./newPaygrade";
import PayrollSelect from "../../../utilities/payrollSelect";
import dropdown from "../../../asset/dropdown copy.svg";

interface StepTwoProps {
  previousStep: () => void;
  nextStep: () => void;
  formikProps: FormikProps<FormValues>;
}

const StepTwo: React.FC<StepTwoProps> = ({
  previousStep,
  nextStep,
  formikProps,
}) => {
  const benefits = ["Basic", "Housing", "Transport", "Medical", "Insurance"];

  const options = [
    { value: "₦", label: "Naira" },
    { value: "$", label: "USD" },
    { value: "%", label: "%" },
  ];

  const [visibleDropdowns, setVisibleDropdowns] = useState<number[]>([]);

  const isNextButtonDisabled = Object.values(formikProps.values).some(
    (value) => !value
  );

  return (
    <div className="">
      <p className="text-lg font-medium">Benefits</p>

      <i className="text-red-500 ">
        Note: The total value of all benefits must equate to the previously
        entered net pay.
      </i>

      {benefits.map((benefit, index) => (
        <div key={index} className="border rounded-lg my-7 ">
          <div
            className="flex items-center justify-between px-6 py-4 font-medium"
            onClick={() =>
              setVisibleDropdowns((prevDropdowns) =>
                prevDropdowns.includes(index)
                  ? prevDropdowns.filter((dropdown) => dropdown !== index)
                  : [...prevDropdowns, index]
              )
            }
          >
            <div>{benefit}</div>
            <div className="">
              <ReactSVG src={dropdown} />
            </div>
          </div>
          {visibleDropdowns.includes(index) && (
            <div className="px-6 py-4 border-t ">
              <PayrollSelect
                formik={formikProps}
                options={options}
                fieldName={`${benefit}`}
                className="w-full"
                label="Amount/Percentage of Gross Earnings"
              />
            </div>
          )}
        </div>
      ))}

      <div className="flex justify-between pb-7">
        <button
          type="button"
          className="mt-7 border rounded-lg px-[18px] py-[10px] "
          onClick={previousStep}
        >
          Previous
        </button>

        <button
          type="button"
          className={`mt-7 border bg-greenDark text-white rounded-lg px-[18px] py-[10px] 
          `}
          onClick={nextStep}
          // disabled={isNextButtonDisabled}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default StepTwo;
