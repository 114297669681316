import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { EarningFormValue } from '../../../admin/payroll/settings/customEarning';
import Payroll_API from '../../../api/payroll';

export const createCustomEarningForm = createAsyncThunk(
  'customEarningForm',
  async (data: EarningFormValue, thunkAPI) => {
    try {
      const response = await Payroll_API.updateCustomEarning(data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

const customEarningFormSlice = createSlice({
  name: 'customEarningForm',
  initialState: {
    customEarningMessage: '',
    earnings: '',
    error: null,
  },
  reducers: {
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createCustomEarningForm.fulfilled, (state, action) => {
      // Handle successful message sending
      state.customEarningMessage = action.payload;
      state.error = null;
    });
    // builder.addCase(customEarningForm.rejected, (state, action) => {
    //   // Handle rejection or error in sending message
    //   state.error = action.payload;
    // });
  },
});

export const { setErrors, resetForm } = customEarningFormSlice.actions;

export default customEarningFormSlice.reducer;
export type RootState = ReturnType<typeof customEarningFormSlice.reducer>;
