// src/features/onboardingFormSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {

  ExpenseId,
  ExpenseValues,
} from '../../../utilities/types/types';
import Expense_API from '../../../api/expenseService';

export const editExpensesForm = createAsyncThunk(
  'edit/expense',
  async (
    { data, expenseId }: { data: ExpenseValues; expenseId: ExpenseId },
    thunkAPI
  ) => {
    try {
      const response = await Expense_API.updateExpense(data, expenseId);

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
const editExpenseSlice = createSlice({
  name: 'editExpense',
  initialState: {
    formData: {
      description: '',
      category: '',
      expenseDate: '',
      amount: '',
      employee: '',
      paidBy: '',
      tax: '',
      file: [],
      notes: '',
    },
    error: null,
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.formData = {
        description: '',
        category: '',
        expenseDate: '',
        amount: '',
        employee: '',
        paidBy: '',
        tax: '',
        file: [],
        notes: '',
      };
      state.error = null;
    },
  },
});

export const { setFormData, setErrors, resetForm } = editExpenseSlice.actions;

export default editExpenseSlice.reducer;
export type RootState = ReturnType<typeof editExpenseSlice.reducer>;
