import React, { useState, ChangeEvent } from "react";
import { FormikErrors } from "formik";
import "../../../styles/customInput.css";

type RadioOption = string;

export interface CustomInputProps {
  id: string;
  disabled?: boolean;
  placeholder?: string;
  label?: string;
  type:
    | "text"
    | "number"
    | "select"
    | "password"
    | "email"
    | "tel"
    | "date"
    | "textarea"
    | "color"
    | "radio"
    | "checkbox";
  name: string;
  value?: string | number | Date | boolean;
  // onChange: (
  //   e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>
  // ) => void;
  onChange: (e: React.ChangeEvent<any>) => void;
  checked?: boolean | RadioOption;
  error?: string | FormikErrors<Date>;
  className?: string;
  wrapperClassName?: string;
  options?: string[];
  selectOptions?: { value: string; label: string }[];
}

const CustomInput: React.FC<CustomInputProps> = ({
  id,
  disabled,
  placeholder,
  label,
  type,
  name,
  className,
  wrapperClassName,
  value,
  checked,
  onChange,
  selectOptions,
  error,
  options,
}) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prevState) => !prevState);
  };

  type ConvertibleValue = string | number | Date | boolean | undefined;

  const convertValueToString = (val: ConvertibleValue): string => {
    if (typeof val === "number") return val.toString();
    if (typeof val === "boolean") return val ? "true" : "false";
    if (val instanceof Date) return val.toISOString();
    if (val === undefined) return "";
    return val.toString();
  };
  return (
    <div className="mb-4">
      {type !== "checkbox" && type !== "radio" && (
        <label htmlFor={id} className="block font-normal text-sm pb-[6px]">
          {label}
        </label>
      )}
      {type === "select" ? (
        <select
          id={id}
          name={name}
          value={convertValueToString(value)}
          // value={convertValueToString(value)}
          onChange={onChange}
          className={`${className}`}
          disabled={disabled}
        >
          <option value="">{placeholder}</option>
          {selectOptions &&
            selectOptions.map((option) => (
              <option key={option.value} value={option.value}>
                {option.label}
              </option>
            ))}
        </select>
      ) : type === "textarea" ? (
        <div className={wrapperClassName}>
          <textarea
            id={id}
            name={name}
            value={convertValueToString(value)}
            onChange={onChange}
            placeholder={placeholder}
            className={className}
            disabled={disabled}
            rows={10}
          />
        </div>
      ) : type === "radio" ? (
        <div className="flex items-center">
          {options?.map((radioOption) => (
            <div key={radioOption} className="flex items-center mr-4">
              <input
                type="radio"
                id={`${id}_${radioOption}`}
                name={name}
                value={radioOption}
                checked={checked === radioOption}
                onChange={onChange}
                className={className}
                disabled={disabled}
              />
              <label
                htmlFor={`${id}_${radioOption}`}
                className={`ml-2 ${value ? "text-greenDark" : ""}`}
              >
                {radioOption}
              </label>
            </div>
          ))}
        </div>
      ) : type === "checkbox" ? (
        <div className="flex items-center">
          <input
            type="checkbox"
            id={id}
            name={name}
            checked={value as boolean}
            onChange={onChange}
            className={`text-greenDark ${className}`}
            disabled={disabled}
          />
          <label
            htmlFor={id}
            className={`ml-2 ${value ? "text-greenDark" : ""}`}
          >
            {label}
          </label>
        </div>
      ) : (
        <div className={wrapperClassName}>
          <input
            type={showPassword ? "text" : type}
            id={id}
            name={name}
            value={convertValueToString(value)}
            onChange={onChange}
            placeholder={placeholder}
            className={className}
            disabled={disabled}
          />
          {type === "password" && (
            <button
              type="button"
              className="inputBtn"
              onClick={togglePasswordVisibility}
            >
              {showPassword ? (
                <svg
                  width="16"
                  height="13"
                  viewBox="0 0 16 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M0.666016 6.49996C0.666016 6.49996 3.33268 1.16663 7.99935 1.16663C12.666 1.16663 15.3327 6.49996 15.3327 6.49996C15.3327 6.49996 12.666 11.8333 7.99935 11.8333C3.33268 11.8333 0.666016 6.49996 0.666016 6.49996Z"
                    stroke="#98A2B3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M7.99935 8.49996C9.10392 8.49996 9.99935 7.60453 9.99935 6.49996C9.99935 5.39539 9.10392 4.49996 7.99935 4.49996C6.89478 4.49996 5.99935 5.39539 5.99935 6.49996C5.99935 7.60453 6.89478 8.49996 7.99935 8.49996Z"
                    stroke="#98A2B3"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              ) : (
                <svg
                  width="16"
                  height="17"
                  viewBox="0 0 16 17"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_1007_36940)">
                    <path
                      d="M6.59935 3.32663C7.05824 3.21921 7.52806 3.16552 7.99935 3.16663C12.666 3.16663 15.3327 8.49996 15.3327 8.49996C14.928 9.25703 14.4454 9.96978 13.8927 10.6266M9.41268 9.91329C9.22958 10.1098 9.00878 10.2674 8.76345 10.3767C8.51812 10.486 8.25328 10.5448 7.98474 10.5495C7.7162 10.5543 7.44946 10.5049 7.20042 10.4043C6.95139 10.3037 6.72516 10.154 6.53525 9.96406C6.34533 9.77414 6.19561 9.54792 6.09502 9.29888C5.99443 9.04985 5.94503 8.78311 5.94977 8.51457C5.95451 8.24602 6.01329 7.98119 6.1226 7.73586C6.23191 7.49053 6.38952 7.26972 6.58602 7.08663M0.666016 1.16663L15.3327 15.8333M11.9593 12.46C10.8197 13.3286 9.43209 13.8099 7.99935 13.8333C3.33268 13.8333 0.666016 8.49996 0.666016 8.49996C1.49528 6.95456 2.64544 5.60436 4.03935 4.53996L11.9593 12.46Z"
                      stroke="#98A2B3"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_1007_36940">
                      <rect
                        width="16"
                        height="16"
                        fill="white"
                        transform="translate(0 0.5)"
                      />
                    </clipPath>
                  </defs>
                </svg>
              )}
            </button>
          )}
        </div>
      )}
      {typeof error === "string" ? (
        <div className="mt-1 text-red-500 lg:w-[400px]">{error}</div>
      ) : (
        <div className="mt-1 text-red-500">{JSON.stringify(error)}</div>
      )}
    </div>
  );
};

export default CustomInput;
