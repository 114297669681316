import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Employee_API from '../../../api/employeeService';
import { Employee, UserData } from '../../../utilities/types/types';


// Define the type for your slice state
interface EmployeeState {
  employees: Employee[];
}

// Create an async thunk to fetch employee data
export const getAllEmployees = createAsyncThunk(
  'employees/allEmployees',
  async () => {
    try {
      const response = await Employee_API.getAll();
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Create a slice
const userString = localStorage.getItem("user");
const user: UserData | null = userString ? JSON.parse(userString) : null;const token = localStorage.getItem('token');

const employeeSlice = createSlice({
  name: 'employees/allEmployees',
  initialState: {
    employees: [],
   isAuthenticated: !!user && !!token,
  } as EmployeeState,
  reducers: {
    setAllEmployees: (state, { payload }) => {
      state.employees = payload;
    },
  },
  //   const user: UserData | null = JSON.parse(localStorage.getItem("user")!);
  // const token = localStorage.getItem("token");

  // const initialState= {
  //   employees: [],
  //   isAuthenticated: !!user && !!token,
  // } as EmployeeState

  // const employeeSlice = createSlice({
  //   name: "employees/allEmployees",
  //   initialState: initialState,
  //   reducers: {
  //     setAllEmployees: ( state,
  //       { payload }: { payload: Partial<typeof initialState> }) => {
  //       state.employees = payload.employees ?? state.employees;
  //        state.isAuthenticated = payload.isAuthenticated ?? state.isAuthenticated;
  //     },
  //   },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(allEmployees.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setAllEmployees } = employeeSlice.actions;

export default employeeSlice.reducer;

// Selectors
// export const selectEmployees = (state:EmployeeState) => state.employees.employees;
// export const selectEmployeesStatus = (state:EmployeeState) =>
//   state.employees.status;
// export const selectEmployeesError = (state:EmployeeState) => state.employees.error;