
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Payroll_API from '../../../api/payroll';
import { PayScheduleValues } from '../../../utilities/types/types';

export const payScheduleSettings = createAsyncThunk(
  'paySchedule-settings',
  async (data: PayScheduleValues, thunkAPI) => {
    try {
      const response = await Payroll_API.paySchedule(data);

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
const payScheduleSlice = createSlice({
  name: 'paySchedule-settings',
  initialState: {
    formData: {
      payFrequency: '',
      dayOfTheMonth: '',
      firstPaydate: '',
      autoPayroll: false,
    },
    error: null,
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.formData = {
        payFrequency: '',
        dayOfTheMonth: '',
        firstPaydate: '',
        autoPayroll: false,
      };
      state.error = null;
    },
  },
});

export const { setFormData, setErrors, resetForm } = payScheduleSlice.actions;

export default payScheduleSlice.reducer;
export type RootState = ReturnType<typeof payScheduleSlice.reducer>;
