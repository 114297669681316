import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Employee_API from '../../../api/employeeService';

interface Analytic {
  totalEmployees: number;
  activeEmployees: number;
  deactivatedEmployees: number;
  customers: number;
}

// Define the type for your slice state
interface AnalyticState {
  analytics: Analytic | null;
}

// Create an async thunk to fetch employee data
export const getAnalytics = createAsyncThunk('analytics', async () => {
  try {
    const response = await Employee_API.analytics();
    return response.data;
  } catch (error) {
    throw error;
  }
});

// Create a slice
const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: {
    analytics: null,
  } as AnalyticState,
  reducers: {
    setAnalytics: (state, { payload }) => {
      state.analytics = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(analytics.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setAnalytics } = analyticsSlice.actions;

export default analyticsSlice.reducer;

// Selectors
// export const selectEmployees = (state:EmployeeState) => state.employees.employees;
// export const selectEmployeesStatus = (state:EmployeeState) =>
//   state.employees.status;
// export const selectEmployeesError = (state:EmployeeState) => state.employees.error;
