import React from "react";
import { LeaveAnalytics } from "../../utilities/types/types";

interface TaskValidationProps {
  data: LeaveAnalytics | null;
}

const TaskValidation: React.FC<TaskValidationProps> = ({ data }) => {
  return (
    <div className="p-4 border rounded-lg border-[#EAECF0] w-[25%]">
      <div>
        <p className="text-xl font-medium ">Task Validation</p>
        <div className="pt-4 pb-[63px]">
          <p className="text-4xl font-medium">
            <p>{data?.totalLeaves}</p>
          </p>
          <p className="text-[#667085]">
            You have {data?.requestedLeaves} task awaiting approval
          </p>
        </div>
      </div>
      <div>
        <div className="flex items-center gap-2 py-2">
          <div
            className="text-center text-white border border-transparent rounded-full bg-greenDark"
            style={{
              width: `${
                20 + (data?.approvedLeaves ?? "").toString().length * 10
              }px`,
              height: `${
                20 + (data?.approvedLeaves ?? "").toString().length * 10
              }px`,
            }}
          >
            <p className="pt-[3px]">{data?.requestedLeaves}</p>
          </div>
          <div className="flex gap-4">
            <p className="text-greenDark ">Request Expense</p>
            <div> </div>
          </div>
        </div>
        <div className="flex items-center gap-2 py-2">
          <div
            className="text-center text-white border border-transparent rounded-full bg-greenDark"
            style={{
              width: `${
                20 + (data?.approvedLeaves ?? "").toString().length * 10
              }px`,
              height: `${
                20 + (data?.approvedLeaves ?? "").toString().length * 10
              }px`,
            }}
          >
            <p className="pt-[3px]">{data?.approvedLeaves}</p>
          </div>

          <div className="flex gap-4">
            <p className="text-greenDark">Approved Leaves</p>
            <div></div>
          </div>
        </div>
        <div className="flex items-center gap-2 py-2">
          <div
            className="text-center text-[#344054] border border-transparent rounded-full bg-[#f2f4f7]"
            style={{
              width: `${
                20 + (data?.approvedLeaves ?? "").toString().length * 10
              }px`,
              height: `${
                20 + (data?.approvedLeaves ?? "").toString().length * 10
              }px`,
            }}
          >
            <p className="pt-[3px]"> {data?.declinedLeaves}</p>
          </div>

          <div className="flex gap-4">
            <p className="text-greenDark">Rejected Leaves</p>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskValidation;
