import { EmployeeId, ExitManagement } from '../utilities/types/types';
import axiosService from './axios/axios';

const API_URL_ARCHIVED = `${process.env.REACT_APP_API_BASE_URL}exit/`;
export default class Archived_API {
  static async archivedEmployeesForm(
    data: ExitManagement,
    employeeId: EmployeeId
  ): Promise<any> {
    return await axiosService({
      method: 'POST',
      data: data,
      url: `${API_URL_ARCHIVED}archive/${employeeId}`,
    });
  }
}
