import Header from './header';
import { Outlet } from 'react-router-dom';
import Navbar from './sidebar';

const Layout = () => {
  return (
    <>
      <div className='flex w-screen font-switzer text-grayText'>
        <div className=''>
          <Navbar />
        </div>
        <div className='w-[100%] lg:w-full xl:px-8 h-screen overflow-scroll'>
          <Header />
          <main>
            <Outlet />
          </main>
        </div>
      </div>
    </>
  );
};

export default Layout;
