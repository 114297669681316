import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Expense } from '../../../utilities/types/types';
import Expense_API from '../../../api/expenseService';

// Define the type for your slice state
interface CompanyExpenseState {
  companyExpense: Expense[];
}

// Create an async thunk to fetch employee data
export const getCompanyExpense = createAsyncThunk(
  'reimbursed-expense',
  async () => {
    try {
      const response = await Expense_API.getPaidByCompany();
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Create a slice
const CompanyExpenseSlice = createSlice({
  name: 'reimbursed-expense',
  initialState: {
    companyExpense: [],
  } as CompanyExpenseState,
  reducers: {
    setCompanyExpenses: (state, { payload }) => {
      state.companyExpense = payload;
    },
  },
});
export const { setCompanyExpenses } = CompanyExpenseSlice.actions;

export default CompanyExpenseSlice.reducer;
