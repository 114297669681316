import React, { useEffect } from 'react';
import Breadcrumbs from '../../../utilities/breadcrumb';
import TitleSection from '../../../utilities/headers/titleSection';
import add from '../../../asset/add.svg';
import Paygrade from './paygradeTable';
import { useAppDispatch, useAppSelector } from '../../../redux/hook/authHook';
import {
  getPaygradeData,
  setPaygradeData,
} from '../../../redux/slices/paygrade/getPaygradeTable';

function PayGradeIndex() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPaygradeData()).then((response) => {
      dispatch(setPaygradeData(response.payload));
    });
  }, [dispatch]);
  const paygrade = useAppSelector((state) => {
    return state.paygradeTable.paygrade;
  });
  const rowCount = paygrade.length;
  return (
    <div>
      <TitleSection
        title='Paygrade'
        greenButtonText='Create New Paygrade'
        greenBtnLink='/admin/new-paygrade'
        greenButtonIcon={add}
      />
      <Paygrade paygrade={paygrade} rowCount={rowCount} />
    </div>
  );
}

export default PayGradeIndex;
