import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { useAppDispatch, useAppSelector } from '../redux/hook/authHook';
import {
  getAllEmployees,
  setAllEmployees,
} from '../redux/slices/employes/employesSlice';
import {
  getAnalytics,
  setAnalytics,
} from '../redux/slices/employes/analyticsSlice';
import AllEmployeesTable from '../admin/employes/employeeTable/allEmployeesTable';
import {
  getAllEmployeesCount,
  setAllEmployeesCount,
} from '../redux/slices/auth/dashbordChart';
import { getAdminInfo, setAdmin } from '../redux/slices/auth/adminDetails';
import { ButtonIconLink } from '../utilities/button';
import Chart from '../admin/overview/diagram/charts/chart';
import Tabs, { ContentItem } from '../admin/overview/tabs';
import ReactDatePicker from 'react-datepicker';
import add from '../asset/add.svg';
import calendar from '../asset/calendar.svg';
import { ReactSVG } from 'react-svg';

function AdminDashboard() {
  const dispatch = useAppDispatch();
  const [startDate, setStartDate] = useState<Date | null>(new Date());

  const formatYear = (date: Date) => {
    return format(date, 'yyyy');
  };

  useEffect(() => {
    dispatch(getAllEmployees()).then((response) => {
      dispatch(setAllEmployees(response.payload));
    });

    dispatch(getAnalytics()).then((response) => {
      dispatch(setAnalytics(response.payload));
    });
    dispatch(getAdminInfo()).then((response) => {
      dispatch(setAdmin(response.payload));
    });
    dispatch(
      getAllEmployeesCount({
        startDate: formatYear(startDate || new Date()),
      })
    ).then((response) => {
      dispatch(setAllEmployeesCount(response.payload));
    });
  }, [dispatch, startDate]);

  const employees = useAppSelector((state) => state.employee.employees);
  const rowCount = employees.length;
  const analytics = useAppSelector((state) => state.analytics.analytics);

  const admin = useAppSelector((state) => state.admin.admin);
  const chart = useAppSelector((state) => state.employeeCount.allEmployeeCount);
  const contents: ContentItem[] = [
    {
      heading: 'Total Employee',
      figures: analytics?.totalEmployees,
      className: 'bg-blueLight px-7 border border-transparent rounded-lg w-48',
      id: 1,
    },
    {
      heading: 'Active Employee',
      figures: analytics?.activeEmployees,
      className:
        'bg-yellowLight px-7 border border-transparent rounded-lg w-48',
      id: 2,
    },
    {
      heading: 'Inactive Employee',
      figures: analytics?.deactivatedEmployees,
      className: 'bg-greenDash px-7 border border-transparent rounded-lg w-48',
      id: 3,
    },
  ];

  return (
    <div>
      <div className='py-12'>
        <div className='flex justify-between '>
          <div>
            <h3 className='text-lg font-medium xl:text-3xl text-grayText'>
              Welcome back, {admin?.firstName}
            </h3>
            <p className='text-base font-normal text-[#667085]'>
              Manage and track employee’s performance.
            </p>
          </div>
          <div className='flex items-center gap-2'>
            <div className='flex items-center gap-4 p-2 border border-gray-300 rounded '>
              <ReactSVG src={calendar} />
              <ReactDatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date as Date)}
                showYearPicker
                dateFormat='yyyy'
                className='w-12'
              />
            </div>

            <div>
              <ButtonIconLink
                className='text-white bg-greenDark'
                src={add}
                link='/admin/onboard'
                text='Add Employee'
              />
            </div>
          </div>
        </div>
      </div>

      <div className='border-2 border-solid rounded-lg border-[#EAECF0] py-2 mb-10 lg:flex md:flex-row'>
        <div className='lg:px-6 md:px-28'>
          <Tabs contents={contents} />
        </div>
        <div className='border-l-2 border-solid lg:w-[60%] md:w-[90%] border-[#EAECF0]'>
          <div className='pl-6'>
            <h3 className='py-4 text-xl text-grayText'>Employee Chart</h3>
            <div className='flex flex-col items-center'>
              <Chart data={chart} />
            </div>
          </div>
        </div>
      </div>
      <div className=''>
        <AllEmployeesTable employees={employees} rowCount={rowCount} />
      </div>
    </div>
  );
}

export default AdminDashboard;
