import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  ExpenseId } from '../../../utilities/types/types';
import Expense_API from '../../../api/expenseService';

export const approvedExpense = createAsyncThunk(
  'approved/expense',
  async (
     expenseId: ExpenseId ,
    thunkAPI
  ) => {
    try {
      const response = await Expense_API.approveExpense( expenseId);

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
const approvedExpenseSlice = createSlice({
  name: 'approvedExpense',
  initialState: {
    formData: {
      description: '',
      category: '',
      expenseDate: '',
      amount: '',
      employee: '',
      paidBy: '',
      tax: '',
      file: [],
      notes: '',
    },
    error: null,
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.formData = {
        description: '',
        category: '',
        expenseDate: '',
        amount: '',
        employee: '',
        paidBy: '',
        tax: '',
        file: [],
        notes: '',
      };
      state.error = null;
    },
  },
});

export const { setFormData, setErrors, resetForm } =
  approvedExpenseSlice.actions;

export default approvedExpenseSlice.reducer;
export type RootState = ReturnType<typeof approvedExpenseSlice.reducer>;
