import React from "react";
import { Form, FormikProps } from "formik";
import { FormValues } from "./newPaygrade";
import PayrollSelect from "../../../utilities/payrollSelect";
import CustomSelect from "../../../utilities/select";
import CustomInput from "../../../utilities/forms/customInput";

interface StepOneProps {
  nextStep: () => void;
  formikProps: FormikProps<FormValues>;
}

const StepOne: React.FC<StepOneProps> = ({ nextStep, formikProps }) => {
  const { values } = formikProps;

  // Example options for the CustomSelect component
  const options = [
    { value: "level1", label: "Level 1" },
    { value: "level2", label: "Level 2" },
    { value: "level3", label: "Level 3" },
    { value: "level4", label: "Level 4" },
    { value: "level5", label: "Level 5" },
    { value: "level6", label: "Level 6" },
    { value: "level7", label: "Level 7" },
    { value: "level8", label: "Level 8" },
    { value: "level9", label: "Level 9" },
    // Add more options as needed
  ];

  return (
    <div>
      <Form>
        <div className="flex gap-10 ">
          <CustomInput
            id="gradeName"
            label="Grade Name"
            type="text"
            name="gradeName"
            value={formikProps.values.gradeName}
            onChange={formikProps.handleChange}
            error={formikProps.errors?.gradeName}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[520px]"
            wrapperClassName=""
            placeholder="Enter Grade Name"
          />
          <CustomSelect
            label="Level"
            name="level"
            options={options}
            isMulti={false}
            className="w-[550px] "
            onChange={(selectedValues: any) => {}}
          />
        </div>

        <div className="flex gap-10 ">
          <PayrollSelect
            formik={formikProps}
            options={[
              { value: "₦", label: "Naira" },
              { value: "$", label: "USD" },
            ]}
            fieldName="netPay"
            label="Net Pay"
            className="p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[300px] lg:w-[400px] xl:w-[500px]"
          />
          <div className="mt-4 mb-2">
            <p className="mb-2 ">Is this paygrade taxable?</p>
            <div className="flex gap-4">
              <label className="flex items-center">
                <input
                  type="radio"
                  id="yes"
                  name="paygradeTaxable"
                  value="yes"
                  checked={formikProps.values.paygradeTaxable === "yes"}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  className="w-4 h-4 mr-3 border rounded-full appearance-none border-greyTint checked:bg-greenDark checked:ring-greenDark focus:outline-none focus:border-greenDark focus:ring focus:ring-greenDark ring-offset-4"
                />
                Yes
              </label>
              <label className="flex items-center">
                <input
                  type="radio"
                  id="no"
                  name="paygradeTaxable"
                  value="no"
                  checked={formikProps.values.paygradeTaxable === "no"}
                  onChange={formikProps.handleChange}
                  onBlur={formikProps.handleBlur}
                  className="w-4 h-4 mr-3 border rounded-full appearance-none border-greyTint checked:bg-greenDark checked:ring-greenDark focus:outline-none focus:border-greenDark focus:ring focus:ring-greenDark ring-offset-4"
                />
                No
              </label>
            </div>
          </div>
        </div>
        <div className="flex justify-end pt-7">
          <button
            className="mt-7 border bg-greenDark text-white rounded-lg px-[18px] py-[10px] "
            onClick={nextStep}
          >
            Next
          </button>
        </div>
      </Form>
    </div>
  );
};

export default StepOne;
