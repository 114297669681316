import React, { useState } from 'react';
import { Formik } from 'formik';
import StepOne from './createPaygrade';
import StepTwo from './benefits';
import StepThree from './statutoryContribution';
import Preview from './preview';
import TitleSection from '../../../utilities/headers/titleSection';
import { useAppDispatch } from '../../../redux/hook/authHook';
import { createNewPaygrade } from '../../../redux/slices/paygrade/createPaygrade';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

export interface FormValues {
  gradeName: string;
  level: string;
  paygradeTaxable: string;
  netPay: {
    currencyType: string | null;
    amount: number | null;
  };
}

const initialValues: FormValues = {
  gradeName: '',
  level: '',
  paygradeTaxable: '',
  netPay: {
    currencyType: null,
    amount: null,
  },
};

const ThreeStepForm: React.FC = () => {
  const [step, setStep] = useState<number>(1);
  const [loading, setLoading] = useState(false);
  const [response, setResponse] = useState<any>(null);

  const nextStep = () => setStep(step + 1);
  const previousStep = () => setStep(step - 1);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (values: FormValues, formikBag: any) => {
    setLoading(true);

    try {
      const response = await dispatch(createNewPaygrade(values));
      setLoading(false);

      if (response?.payload?.error) {
        toast.error('Total benefit value must match net pay, try again', {
          position: 'top-center',
          autoClose: 3000,
          theme: 'colored',
          style: { backgroundColor: '#F04438', color: 'white' },
        });
      } else if (response?.payload?.statusCode === 200 || 201 || 204) {
        setResponse(response);
        nextStep();
        toast.success('Paygrade created successfully', {
          position: 'top-center',
          autoClose: 3000,
          theme: 'colored',
          style: { backgroundColor: '#00989B', color: 'white' },
        });
      }
    } catch (error: any) {
      setLoading(false);

      toast.error('An error occurred. Please try again later.', {
        position: 'top-center',
        autoClose: 3000,
        theme: 'colored',
        style: { backgroundColor: '#D92D20', color: 'white' },
      });
    }
  };

  return (
    <div>
      <TitleSection
        title='Create New Paygrade'
        subtitle='Fill out required information and save.'
        grayButtonText='Cancel'
        greenBtnLink=''
        grayBtnLink=''
      />
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(formikProps) => (
          <>
            <div className='h-[1px] bg-gray-300 mb-5'></div>
            <ul className='flex py-4 space-x-4'>
              <li
                className={`${
                  step >= 1 ? 'border-greenDark' : 'border-greyTint'
                } border-t-4 w-[25%] pl-2 text-left font-medium`}
              >
                PayGrade
              </li>
              <li
                className={`${
                  step >= 2 ? 'border-greenDark' : 'border-greyTint'
                } border-t-4 w-[25%] pl-2 text-left font-medium`}
              >
                Benefit
              </li>
              <li
                className={`${
                  step >= 3 ? 'border-greenDark' : 'border-greyTint'
                } border-t-4 w-[25%] pl-2 text-left font-medium`}
              >
                Contribution
              </li>
              <li
                className={`${
                  step >= 4 ? 'border-greenDark' : 'border-greyTint'
                } border-t-4 w-[25%] pl-2 text-left font-medium`}
              >
                View
              </li>
            </ul>
            {step === 1 && (
              <StepOne nextStep={nextStep} formikProps={formikProps} />
            )}
            {step === 2 && (
              <StepTwo
                previousStep={previousStep}
                nextStep={nextStep}
                formikProps={formikProps}
              />
            )}
            {step === 3 && (
              <StepThree
                previousStep={previousStep}
                handleSubmit={() => formikProps.handleSubmit()}
                formikProps={formikProps}
              />
            )}
            {step === 4 && <Preview responseData={response?.payload} />}
          </>
        )}
      </Formik>
    </div>
  );
};

export default ThreeStepForm;
