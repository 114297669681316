import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useField, FieldAttributes } from 'formik';

interface DatePickerProps extends FieldAttributes<any> {
  onChange?: (date: Date | null) => void;
  className?: string;
}

const CustomDatePicker: React.FC<DatePickerProps> = ({
  onChange,
  className,
  ...props
}) => {
  const [field, meta, helpers] = useField(props.name);

  const handleDateChange = (date: Date | null) => {
    helpers.setValue(date);
    if (onChange) {
      onChange(date);
    }
  };

  return (
    <div className='block'>
      <DatePicker
        {...field}
        {...props}
        selected={field.value}
        onChange={handleDateChange}
        dateFormat='MM/dd/yyyy'
        className={`border rounded-lg border-greyTint h-10 pl-1 ${className}`}
      />
      {meta.touched && meta.error ? (
        <div style={{ color: 'red' }}>{meta.error}</div>
      ) : null}
    </div>
  );
};

export default CustomDatePicker;
