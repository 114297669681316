import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Employee_API from "../../../api/employeeService";
import { ActiveEmployee } from "../../../utilities/types/types";

// Define the type for your slice state
interface ActiveEmployeeState {
  activeEmployee: ActiveEmployee[];
}

// Create an async thunk to fetch employee data
export const getActiveEmployees = createAsyncThunk("active", async () => {
  try {
    const response = await Employee_API.active();
    return response.data;
  } catch (error) {
    throw error;
  }
});

// Create a slice
const activeEmployeeSlice = createSlice({
  name: "active",
  initialState: {
    activeEmployee: [],
  } as ActiveEmployeeState,
  reducers: {
    setActiveEmployees: (state, { payload }) => {
      state.activeEmployee = payload;
    },
  },
  extraReducers: (builder) => {
    builder
           .addCase(getActiveEmployees.pending, (state) => {
        // state.status = "loading";
      })
      .addCase(getActiveEmployees.fulfilled, (state, action) => {
        state.activeEmployee = action.payload;
        // state.status = "succeeded";
      })
      .addCase(getActiveEmployees.rejected, (state, action) => {
        // state.status = "failed";
        // state.error = action.error.message;
      });
  },
});
export const { setActiveEmployees } = activeEmployeeSlice.actions;

export default activeEmployeeSlice.reducer;
