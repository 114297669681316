import React, { useState } from "react";
import ImageUploadForm from "../../utilities/forms/customLogoUpload";
import loader from "../../asset/images/loader 2.gif";
import { Admin } from "../../redux/slices/auth/adminDetails";

interface ColorFormProps {
  admin: Admin | null;
}

const ColorForm: React.FC<ColorFormProps> = ({ admin }) => {
  const [loading, setLoading] = useState(false);

  return (
    <div>
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/40">
          <img src={loader} alt="" width={800} height={800} />
        </div>
      )}
      <div className="text-lg font-medium">Company Styling</div>
      <div className="py-7 ">
        <div className="px-3">
          <ImageUploadForm
            square={<p>Company Logo Here</p>}
            image={admin?.logo}
          />
        </div>
        {/* <Formik initialValues={initialValues} onSubmit={handleSubmit}>
          {({ isSubmitting, values, setFieldValue }) => (
            <Form>
              <div className='py-3 mt-4 border-y border-[#EAECF0]'>
                <div className='text-lg font-medium pt-7'>
                  Choose Brand Colour Theme
                </div>
                <div className='flex item-center'>
                  <div
                    className='w-[330px] h-[140px] mt-12 rounded-full border-black'
                    style={{ backgroundColor: previewColor }}
                  ></div>
                  <div className='py-10'>
                    <ColorPicker
                      field={{ name: 'color', value: values.color }}
                      form={{ setFieldValue }}
                      customColors={[
                        '#FF5733', // Vivid Orange
                        '#3498DB', // Dodger Blue
                        '#2ECC71', // Emerald Green
                        '#F39C12', // Sunflower Yellow
                        '#8E44AD', // Purple
                        '#1ABC9C', // Turquoise
                        '#E74C3C', // Alizarin Red
                        '#34495E', // Wet Asphalt
                        '#D35400', // Pumpkin Orange
                        '#2ECC71', // Nephritis Green
                        '#F39C12', // Orange
                        '#8E44AD', // Wisteria Purple
                        '#1ABC9C', // Green Sea
                        '#E74C3C', // Red
                        '#34495E', // Midnight Blue
                        '#D35400', // Tangerine
                        '#3498DB', // Steel Blue
                        '#2ECC71', // Chateau Green
                        '#F39C12', // Goldenrod Yellow
                        '#9B59B6', // Amethyst Purple
                        '#27AE60', // Nephritis Green
                        '#E67E22', // Carrot Orange
                        '#C0392B', // Pomegranate Red
                        '#2980B9', // Belize Hole Blue
                        '#16A085', // Green Sea
                        '#E74C3C', // Indian Red
                        '#8E44AD', // Medium Purple
                        '#F39C12', // Bright Orange
                        '#2ECC71', // Emerald
                        '#1ABC9C', // Robin's Egg Blue
                        '#E67E22', // Tiger's Eye Orange
                        '#27AE60', // Contrast Green
                        '#9B59B6', // Deep Purple
                        '#C0392B', // Mandarin Red
                        '#2980B9', // Azure Blue
                        '#16A085', // Ocean Green
                        '#F39C12', // Sunflower
                        '#D35400', // Harvest Orange
                        '#2C3E50', // Charcoal
                        '#8E44AD', // Dark Orchid
                        '#E74C3C', // Tomato Red
                        '#27AE60', // Mountain Meadow Green
                        '#1ABC9C', // Light Sea Green
                        '#F39C12', // Mango
                        '#9B59B6', // Medium Purple
                        '#2980B9', // Denim Blue
                        '#C0392B', // Rust Red
                        '#16A085', // Amazon Green
                        '#E67E22', // Basketball Orange
                        '#3498DB', // Cerulean Blue
                        '#2ECC71', // Shamrock Green
                        '#1ABC9C', // Medium Turquoise
                        '#F39C12', // Yellow Orange
                        '#9B59B6', // Dark Lavender
                        '#27AE60', // Emerald
                        '#D35400', // Rustic Orange
                      ]}
                      onChange={(color) => {
                        setFieldValue('color', color);
                        setPreviewColor(color);
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='flex justify-end my-4'>
                <button
                  type='submit'
                  disabled={isSubmitting}
                  className='border text-white bg-greenDark  mr-4 rounded-lg px-[18px] py-[10px] text-left'
                >
                  Save Changes
                </button>
              </div>
            </Form>
          )}
        </Formik> */}
      </div>
    </div>
  );
};

export default ColorForm;
