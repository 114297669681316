import { useEffect, useState } from "react";
import TitleSection from "../../../utilities/headers/titleSection";
import add from "../../../asset/add.svg";
import { useAppDispatch, useAppSelector } from "../../../redux/hook/authHook";
import { FormikHelpers } from "formik";
import {
  getLeaveType,
  setLeaveType,
} from "../../../redux/slices/leave/leaveType";
import LeaveType from "../tables/leaveTypeTable";

function LeaveConfig() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getLeaveType()).then((response) => {
      dispatch(setLeaveType(response.payload));
    });
  }, [dispatch]);

  const leave = useAppSelector((state) => state.leaveType.leaveType);
  const rowCount = leave?.length;

  return (
    <div>
      <TitleSection
        title="Leave Configuration"
        greenButtonText="Add New Category"
        greenButtonIcon={add}
        greenBtnLink="/admin/new-leave-type"
      />

      <div>
        <LeaveType leaveCategories={leave} rowCount={rowCount} />{" "}
      </div>
    </div>
  );
}

export default LeaveConfig;
