import React, { ChangeEvent } from 'react';
import CustomInput from '../../../utilities/forms/customInput';
import { ExitManagement } from '../../../utilities/types/types';
import CustomSelect from '../../../utilities/select';
import CustomDatePicker from '../../../utilities/dateSelect';
import { FormikErrors } from 'formik';

interface ExitProps {
  onPrevStep: () => void;
  values: ExitManagement;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  errors?: FormikErrors<ExitManagement>;
  onSubmit: (values: ExitManagement) => void;
}

const ExitForm = ({
  onPrevStep,
  values,
  setFieldValue,
  errors,
  onSubmit,
}: ExitProps) => {
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };

  const handleSubmit = async () => {
    onSubmit(values);
  };

  return (
    <form>
      <div>
        <p className='text-lg pb-7'>Exit Employee</p>
        <div className='flex gap-10'>
          <div>
            <p>Last Day at Work</p>
            <CustomDatePicker
              name='lastDay'
              className='  focus:outline-none focus:ring-2 focus:ring-gray-500 w-[500px]'
            />
          </div>

          <CustomSelect
            label='Select Exit Reasons '
            name='selectExitReasons'
            options={[
              {
                value: 'Voluntary Resignation',
                label: 'Voluntary Resignation',
              },
              { value: 'Personal Development', label: 'Personal Development' },
              {
                value: 'Company Restructuring',
                label: 'Company Restructuring',
              },
              { value: 'Personal Choice', label: 'Personal Choice' },
              { value: 'Retirement', label: 'Retirement' },
              { value: 'Career Change', label: 'Career Change' },
              { value: 'Health Reasons', label: 'Health Reasons' },
              { value: 'Demise', label: 'Demise' },
              { value: 'Company Culture', label: 'Company Culture' },
              { value: 'Conflict', label: 'Conflict' },
            ]}
            wrapperClassName='pt-[2px]'
            isMulti={false}
            className='focus:outline-none focus:ring-2 focus:ring-gray-500 w-[500px]'
          />
        </div>
        <div className='py-2'>
          <CustomInput
            id='exitRemark'
            label='Exit Remark'
            type='textarea'
            name='exitRemark'
            value={values.exitRemark}
            onChange={handleChange}
            error={errors?.exitRemark}
            className='w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500'
            wrapperClassName=''
            placeholder='Enter Exit Remark'
          />
        </div>
      </div>
      <div className='flex justify-between py-9'>
        <button
          type='button'
          onClick={onPrevStep}
          className='border text-grayText border-greyFade mr-4 rounded-lg px-[18px] py-[10px] text-left'
        >
          Previous
        </button>
        <button
          type='button'
          onClick={handleSubmit}
          className='border text-white bg-greenDark mr-4 rounded-lg px-[18px] py-[10px] text-left'
        >
          Submit
        </button>
      </div>
    </form>
  );
};
export default ExitForm;
