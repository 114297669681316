// src/features/onboardingFormSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { GeneralValues } from '../../../utilities/types/types';
import Payroll_API from '../../../api/payroll';

export const generalSettings = createAsyncThunk(
  'create/expense',
  async (data: GeneralValues, thunkAPI) => {
    try {
      const response = await Payroll_API.general(data);

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
const generalSlice = createSlice({
  name: 'general',
  initialState: {
    formData: {
      companyName: '',
      showPaydayEmail: false,
    },
    error: null,
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.formData = {
        companyName: '',
        showPaydayEmail: false,
      };
      state.error = null;
    },
  },
});

export const { setFormData, setErrors, resetForm } = generalSlice.actions;

export default generalSlice.reducer;
export type RootState = ReturnType<typeof generalSlice.reducer>;
