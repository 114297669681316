import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { twMerge } from "tailwind-merge";
import { classNames } from "./components/Utils";

type props1 = {
  className: string;
  link: string;
  text: string;
};

type props2 = {
  text: string;
  number: number;
};
type props3 = {
  className: string;
  link: string;
  text: string;
  src: string;
};
type props4 = {
  className: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  type?: "button" | "submit" | "reset";
};
type props5 = {
  className: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  children: React.ReactNode;
  disabled: boolean;
};

export interface RowProps {
  className: string;
  link: string;
  text: string;
  id: string;
  onClick: (id: string) => void;
}

interface RowBtnProps {
  rowProps: RowProps;
}

export const RowBtn: React.FC<RowBtnProps> = ({ rowProps }) => {
  const { className, link, text, id, onClick } = rowProps;
  const handleClick = () => {
    onClick(id);
  };

  return (
    <Link to={link} className={`no-underline ${className}`}>
      <button
        className={`px-4 py-2 rounded-lg cursor-pointer ${className}`}
        onClick={handleClick}
      >
        {text}
      </button>
    </Link>
  );
};

export default RowBtn;

// export function RowBtn({ className, link, text, rowId }: rowProps) {
//   const handleClick = () => {
//     // Use the rowId in your button logic
//     // ... (other logic)
//   };
//   return (
//     <div
//       className={`flex px-[18px] py-[10px] border rounded-lg   ${className}`}
//       onClick={handleClick}
//     >
//       <Link to={link} className="pl-1 no-underline">
//         {text}
//       </Link>
//     </div>
//   );
// }
export function Btn({ className, link, text }: props1) {
  return (
    <div
      className={`flex px-[18px] py-[10px] border rounded-lg   ${className}`}
    >
      <Link to={link} className="pl-1 no-underline">
        {text}
      </Link>
    </div>
  );
}

export function BtnText({ text, number }: props2) {
  return (
    <div className="border border-transparent">
      <div className="flex p-2 mx-1 border rounded-lg">
        <p className="">{text}</p>
        <p className="px-2 ml-2 border border-transparent rounded-full bg-greyBtn">
          {number}
        </p>
      </div>
    </div>
  );
}

export function ButtonIconLink({ className, src, link, text }: props3) {
  return (
    <div
      className={twMerge(
        "flex px-[1.125rem] py-[0.625rem] border  rounded-lg",
        className
      )}
    >
      <div className="p-1">
        <ReactSVG src={src} />
      </div>
      <Link to={link} className="pl-1 no-underline">
        {text}
      </Link>
    </div>
  );
}

export function PageButton({ children, className, onClick, ...rest }: props5) {
  return (
    <button
      type="button"
      className={classNames(
        "inline-flex items-center px-6 py-4 border rounded-lg gap-x-3 border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50",
        className
      )}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
}
export function Button({
  children,
  className,
  onClick,
  type,
  ...rest
}: props4) {
  return (
    <button
      type={type}
      className={classNames("relative block items-center  ", className)}
      onClick={onClick}
      {...rest}
    >
      {children}
    </button>
  );
}
