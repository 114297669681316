import { Link } from "react-router-dom";
import "../../../styles/auth.css";
import AuthLayout from "../authLayout";
import ForgotPassword from "../forgotPassword/emailInput";

function ForgotPasswordPage() {
  return (
    <>
      <AuthLayout>
        <div className='formSection'>
          <div className='formText'>
            <h2 className='formHeader'>Forgot Password?</h2>
            <div>
              <p className='formP'>
                Need a QuiickOpps account?
                <Link to='/signup' className='pLink'>
                  Create an account
                </Link>
              </p>
            </div>
          </div>
          <div>
            <ForgotPassword />
          </div>
        </div>
      </AuthLayout>
    </>
  );
}

export default ForgotPasswordPage;
