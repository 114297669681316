import React, { useRef, useState } from 'react';
import { ReactSVG } from 'react-svg';
import DownloadSampleCSV from './downloadCsv';
import upload from '../../../../asset/cloudUpload.svg';
import file from '../../../../asset/file.svg';
import del from '../../../../asset/trash.svg';
import { useAppDispatch } from '../../../../redux/hook/authHook';
import { bulkEmployeesUpload } from '../../../../redux/slices/employes/bulkUpload';
import TitleSection from '../../../../utilities/headers/titleSection';
import { useNavigate } from 'react-router-dom';

export interface CSVEntry {
  [key: string]: string | number;
}

const expectedDataFormat = [
  { name: 'firstName', type: 'string' },
  { name: 'lastName', type: 'string' },
  { name: 'middleName', type: 'string' },
  { name: 'staffId', type: 'number' },
  { name: 'email', type: 'string' },
  { name: 'phone', type: 'string' },
  { name: 'dOB ', type: 'string' },
  { name: 'gender', type: 'string' },
  { name: 'contractType', type: 'string' },
  { name: 'department', type: 'string' },
  { name: 'jobTitle', type: 'string' },
  { name: 'unit', type: 'string' },
  { name: 'employmentType', type: 'string' },
  { name: 'employeeSupervisor', type: 'string' },
  { name: 'dateEmployed', type: 'string' },
  { name: 'hoursPerWeekMin', type: 'number' },
  { name: 'hoursPerWeekMax', type: 'number' },
  { name: 'payGroup', type: 'string' },
  { name: 'payGrade', type: 'string' },
  { name: 'message', type: 'string' },
];

function BulkIndex() {
  const [uploading, setUploading] = useState(false);
  const [parseProgress, setParseProgress] = useState(0);
  const [showUploadContent, setShowUploadContent] = useState(true);
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [uploadedFileSize, setUploadedFileSize] = useState('');
  const [, setLoading] = useState(false);

  function formatFileSize(bytes: number): string {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
  }

  const handleUploadButtonClick = async () => {
    try {
      setUploading(true);
      setParseProgress(0);
      setShowUploadContent(false);
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = '.csv';
      fileInput.addEventListener('change', async (event) => {
        if (
          event.target instanceof HTMLInputElement &&
          event.target.files?.[0]
        ) {
          const csvFile = event.target.files[0];
          const csvData = await readFile(csvFile);

          setUploadedFileName(csvFile.name);
          setUploadedFileSize(formatFileSize(csvFile.size));

          simulateParsingAndSending(csvData);
        }
      });

      fileInput.click();
    } catch (error) {
      console.error('Error during file selection:', error);
      setUploading(false);
      setParseProgress(0); // Reset progress on error
      setShowUploadContent(true);
    }
  };

  const readFile = (file: File): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        const csvData = event.target?.result;
        if (typeof csvData === 'string') {
          resolve(csvData);
        } else {
          reject(new Error('Failed to read CSV data.'));
        }
      };

      reader.onerror = () => {
        reject(new Error('Failed to read file.'));
      };
      reader.readAsText(file);
    });
  };

  const parseCSVData = (
    csvData: string
  ): { data: CSVEntry[]; errors: string[] } => {
    const lines = csvData.split('\n');
    const parsedData: CSVEntry[] = [];
    const parsingErrors: string[] = []; // New array to store errors

    for (let i = 1; i < lines.length; i++) {
      const values = lines[i].split(',');

      if (values.length === expectedDataFormat.length) {
        const entry: CSVEntry = {};
        for (let j = 0; j < expectedDataFormat.length; j++) {
          const columnName = expectedDataFormat[j].name;
          const value = values[j];
          entry[columnName] =
            expectedDataFormat[j].type === 'number' ? Number(value) : value;
        }

        if (validateData(entry)) {
          parsedData.push(entry);
        } else {
          parsingErrors.push(`Invalid data at line ${i + 1}`); // Record the error
        }
      } else {
        parsingErrors.push(`Data format mismatch at line ${i + 1}`); // Record the error
      }
    }

    return { data: parsedData, errors: parsingErrors };
  };

  const simulateParsingAndSending = async (csvData: string) => {
    const lines = csvData.split('\n');
    const totalLines = lines.length - 1; // Excluding header
    let parsedCount = 0;

    let currentProgress = 0;
    const progressInterval = setInterval(() => {
      currentProgress = (parsedCount / totalLines) * 100;
      setParseProgress(currentProgress);

      if (parsedCount >= totalLines) {
        clearInterval(progressInterval);

        // Simulate data validation and sending to backend
        // const parsedData = parseCSVData(csvData);
        const { data: parsedData } = parseCSVData(csvData);
        sendToBackend(parsedData);
      }

      parsedCount++;
    }, 500);
  };

  const validateData = (data: CSVEntry): boolean => {
    // Validate each field according to expectedDataFormat
    for (const field of expectedDataFormat) {
      if (!data.hasOwnProperty(field.name)) {
        return false;
      }

      if (field.type === 'number' && isNaN(Number(data[field.name]))) {
        return false;
      }
    }

    return true;
  };

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const resetFileInput = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = ''; // Reset the input value
    }
    // You can also reset other state values if needed
    setUploadedFileName('');
    setUploadedFileSize('');
    setParseProgress(0);
  };

  const dispatch = useAppDispatch();

  const sendToBackend = async (jsonData: CSVEntry[]) => {
    setLoading(true);
    try {
      if (jsonData && jsonData.length > 0) {
        await dispatch(bulkEmployeesUpload({ jsonData }));
      }
    } catch (error) {}

    setUploading(false);
  };

   const navigate = useNavigate();
  const handleCancelClick = () => {
    navigate(-1);
  }

  return (
    <div>
      <TitleSection
        title='Add Bulk Employees'
        subtitle='Upload relevant information and save'
        greenBtnLink=''
        grayButtonText='Cancel'
        grayBtnLink=''
        onCancelButtonClick={handleCancelClick}
        greenButtonIcon=''
        grayButtonIcon=''
      />
      <hr className=" text-[#EAECF0] pb-10"></hr>
      <div className=' p-4 shadow-md rounded-xl border border-transparent'>
        <TitleSection
          title='Upload Employee’s Record'
          subtitle='Upload and attach files to this project.s'
          greenBtnLink=''
          grayButtonText=''
          grayBtnLink=''
          greenButtonIcon=''
          grayButtonIcon=''
        />
        <div className=' border rounded-lg border-greyWhite  px-6 py-8'>
          {showUploadContent ? (
            <div className='flex flex-col items-center justify-center w-full h-[50px] py-8'>
              <div className='mb-4'>
                <ReactSVG src={upload} />
              </div>
              <p className='text-lg font-normal text-center'>
                <span className=' text-greenDark'>Click to upload</span> or drag
                and drop
              </p>

              <p className='text-sm font-light text-center'>
                *the uploaded file format must be csv
              </p>
            </div>
          ) : (
            <div className='py-4'>
              {uploading ? (
                <div>Uploading</div>
              ) : (
                <div>
                  <div>
                    <div className='flex justify-between'>
                      <ReactSVG src={file} />

                      {/* Middle Div */}
                      <div
                        style={{ flexGrow: 1, margin: '0 10px' }}
                        className='flex flex-col justify-between'
                      >
                        <div>
                          <p>{uploadedFileName}</p>
                          <p>{uploadedFileSize}</p>
                        </div>
                        <div className='flex pt-2'>
                          <div
                            style={{ flex: '0 1 100%' }}
                            className='h-4 bg-gray-300 border border-transparent rounded-2xl mr-2'
                          >
                            <div
                              className={`h-full bg-greenDark rounded-2xl ${
                                parseProgress === 100 ? 'w-full' : ''
                              }`}
                              style={{ width: `${parseProgress}%` }}
                            />
                          </div>
                          <p>{parseProgress.toFixed(2)}%</p>
                        </div>
                      </div>
                      <div
                        onClick={() => {
                          resetFileInput();
                        }}
                      >
                        <ReactSVG src={del} />
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
        <div>
          <DownloadSampleCSV />
        </div>
        <div>
          <button
            onClick={handleUploadButtonClick}
            disabled={uploading}
            className='w-full bg-greenWhite font-medium text-lg py-3 border rounded-lg text-white text-center'
          >
            Add Bulk Employees
          </button>
        </div>
      </div>
    </div>
  );
}

export default BulkIndex;
