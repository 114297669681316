import React, { ReactNode } from "react";
import { Link } from "react-router-dom";
import { ReactSVG } from "react-svg";
import { Button } from "../button";
import Modal from "../components/modal";

interface TitleSectionProps {
  title?: string;
  subtitle?: string;
  buttonSubtitle?: string;
  greenButtonText?: string;
  greenButtonIcon?: string;
  lightButtonText?: string;
  lightButtonIcon?: string;
  grayButtonIcon?: string;
  grayButtonText?: string;
  onRejectButtonClick?: () => void;
  onCancelButtonClick?: () => void;
  onSaveButtonClick?: () => void;
  lightBtnLink?: string;
  greenBtnLink?: string;
  grayBtnLink?: string;
  type?: string;
  showModal?: boolean;
  closeModal?: () => void;
  modalContentStyle?: string;
  children?: ReactNode;
  externalIsModalOpen?: boolean;
  externalSetIsModalOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  buttonSize?: string;
  size?: "lg" | "xs" | "sm" | "md" | "xl" | "full" | string;
}
function TitleSection({
  title,
  subtitle,
  greenButtonText,
  onCancelButtonClick,
  onSaveButtonClick,
  onRejectButtonClick,
  greenBtnLink,
  grayButtonText,
  grayBtnLink,
  lightButtonText,
  lightBtnLink,
  lightButtonIcon,
  greenButtonIcon,
  grayButtonIcon,
  type,
  buttonSubtitle,
  buttonSize = "px-[18px] py-[10px]",
  showModal,
  closeModal,
  modalContentStyle,
  externalIsModalOpen,
  externalSetIsModalOpen,
  children,
  size = "lg",
}: TitleSectionProps) {
  // useEffect(() => {
  //   setIsModalOpen(!!showModal);
  // }, [showModal]);

  const handleModalClose = () => {
    if (externalSetIsModalOpen) {
      externalSetIsModalOpen(false);
    }
    if (closeModal) {
      closeModal();
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between pt-4 pb-8">
        <div>
          <h3 className="pb-1 text-2xl text-grayText">{title}</h3>
          <div className="flex gap-4">
            {subtitle && (
              <p className="text-lg font-light text-[#667085]">{subtitle}</p>
            )}
            {type && (
              <p
                style={{
                  backgroundColor:
                    type === "Review"
                      ? "green"
                      : type === "Approved"
                      ? "#ECFDF3"
                      : type === "Request"
                      ? "#FFF6ED"
                      : "#EFF8FF",
                  borderColor: "transparent",
                  textAlign: "center",
                  padding: "2px 8px",
                  borderRadius: "16px",
                  color:
                    type === "Request"
                      ? "#C4320A"
                      : type === "Review"
                      ? "green"
                      : type === "Approved"
                      ? "#027A48"
                      : "#175CD3",
                }}
              >
                {type}
              </p>
            )}
          </div>
        </div>
        <div className="flex items-center">
          {greenButtonText && (
            <>
              <div className="">
                <Link to={greenBtnLink || "#"} className="">
                  <Button
                    className={`border border-transparent bg-greenDark text-white mr-4 rounded-lg ${buttonSize}`}
                    onClick={() => {
                      if (onSaveButtonClick) {
                        onSaveButtonClick();
                      }
                      if (showModal && externalSetIsModalOpen) {
                        externalSetIsModalOpen(true);
                      }
                    }}
                  >
                    <div className="flex items-center gap-1">
                      {greenButtonIcon && (
                        <ReactSVG src={greenButtonIcon} className="p-1" />
                      )}

                      {greenButtonText}
                    </div>
                  </Button>{" "}
                </Link>
                {buttonSubtitle && (
                  <p className="text-sm font-light">{buttonSubtitle}</p>
                )}
              </div>
            </>
          )}
          {lightButtonText && (
            <Button
              className="border border-transparent text-greenDark bg-greenLight   mr-4 rounded-lg px-[18px] py-[10px]"
              onClick={onRejectButtonClick}
            >
              {lightButtonIcon && (
                <div className="flex items-center">
                  <ReactSVG src={lightButtonIcon} className="p-1" />
                  <Link to={lightBtnLink || "#"} className="pl-1 no-underline">
                    {lightButtonText}
                  </Link>
                </div>
              )}
              {!lightButtonIcon && (
                <Link to={lightBtnLink || "#"} className="no-underline">
                  {lightButtonText}
                </Link>
              )}
            </Button>
          )}
          {grayButtonText && (
            <Button
              className="border text-grayText border-greyFade mr-4 rounded-lg px-[18px] py-[10px]"
              onClick={onCancelButtonClick}
            >
              {grayButtonIcon && (
                <div className="flex items-center">
                  <ReactSVG src={grayButtonIcon} className="p-1" />
                  <Link to={grayBtnLink || "#"} className="pl-1 no-underline">
                    {grayButtonText}
                  </Link>
                </div>
              )}
              {!grayButtonIcon && (
                <Link to={grayBtnLink || "#"} className="no-underline">
                  {grayButtonText}
                </Link>
              )}
            </Button>
          )}
          <Modal
            isOpen={!!externalIsModalOpen}
            onClose={handleModalClose}
            size={size}
          >
            <div className={`overflow-scroll  ${modalContentStyle}`}>
              {children}
            </div>
          </Modal>
        </div>
      </div>
    </div>
  );
}

export default TitleSection;
