import React, { useEffect, useState } from "react";
import loader from "../../../asset/images/loader 2.gif";
import TitleSection from "../../../utilities/headers/titleSection";
import { ExpenseId } from "../../../utilities/types/types";
import { useNavigate, useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../redux/hook/authHook";
import { getOne } from "../../../redux/slices/expenses/getOneExpense";
import { toast } from "react-toastify";
import { reimbursedExpense } from "../../../redux/slices/expenses/reimburseExpenseForm";
import DisabledInput from "../../../utilities/components/disabledInput";
import {
  getActivities,
  setActivity,
} from "../../../redux/slices/expenses/activity";
import { sendMessageForm } from "../../../redux/slices/expenses/sendMessage";
import Comms from "../../../utilities/components/coms";

function ReimburseExpenseForm() {
  const [loading, setLoading] = useState(false);
  const { expenseId } = useParams<ExpenseId>();
  const dispatch = useAppDispatch();
  const validExpense = expenseId!;
  const navigate = useNavigate();

  const handleCancelClick = () => {
    navigate(-1);
  };
  const expense = useAppSelector((state) => state.oneExpense.oneExpense);

  useEffect(() => {
    dispatch(getOne(validExpense));
    dispatch(getActivities(validExpense)).then((response) => {
      dispatch(setActivity(response.payload));
    });
  }, [dispatch, validExpense]);
  const activity = useAppSelector((state) => state.activity.activity);

  const getFormattedDate = (dateString: string | undefined) =>
    dateString ? new Date(dateString).toLocaleDateString() : "";
  const handleSave = async (expenseId: ExpenseId) => {
    setLoading(true);
    try {
      const res = await dispatch(reimbursedExpense(expenseId));
      setLoading(false);
      navigate("/admin/expense");
      toast.success("Expenses reimbursed successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "colored",
        style: { backgroundColor: "#00989B", color: "white" },
      });
      // resetForm()
    } catch (error: any) {
      setLoading(false);

      if (error?.response?.status === 400) {
        toast.error("Bad request. Please check your input.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: " #D92D20", color: "white" },
        });
      } else {
        toast.error("An error occurred. Please try again later.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: " #D92D20", color: "white" },
        });
      }
    }
  };
  const handleMessageSubmit = async (editorHtml: string, expenseId: string) => {
    try {
      // Dispatch the action to send the message
      await dispatch(
        sendMessageForm({ data: { sendMessage: editorHtml }, expenseId })
      );

      // Refetch or reload activity data
      const response = await dispatch(getActivities(expenseId));
      dispatch(setActivity(response.payload));

      // Optionally show a success message or handle any post-submit actions
      toast.success("Message sent ", {
        position: "top-center",
        autoClose: 3000,
        theme: "colored",
        style: { backgroundColor: "#00989B", color: "white" },
      });
    } catch (error) {
      console.error(error);
      toast.error("Failed to send message ", {
        position: "top-center",
        autoClose: 3000,
        theme: "colored",
        style: { backgroundColor: "#D92D20", color: "white" },
      });
    }
  };

  const createdAtDate = new Date(expense.createdAt);
  const currentDate = new Date(); // Initialize currentDate with the current date

  // Calculate the difference in milliseconds
  const timeDifference = currentDate.getTime() - createdAtDate.getTime();

  // Convert milliseconds to days
  const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
  const imagePreviews = expense?.file || [];

  return (
    <div>
      {loading && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/40">
          <img src={loader} alt="" width={800} height={800} />
        </div>
      )}
      <div>
        <TitleSection
          subtitle={`Last edit ${daysAgo} days ago`}
          type={
            expense.status
              ? expense.status.charAt(0).toUpperCase() + expense.status.slice(1)
              : ""
          }
          // lightButtonText='Reject'
          // lightBtnLink=""
          greenButtonText="Reimburse"
          onCancelButtonClick={handleCancelClick}
          onSaveButtonClick={() =>
            expenseId !== undefined && handleSave(expenseId)
          }
          // onRejectButtonClick={}
          grayButtonText="Cancel"
        />
      </div>
      <div className="text-greyDark">
        <div className="flex gap-10 mb-4">
          <DisabledInput
            label="Description"
            value={expense?.description || ""}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]"
          />

          <DisabledInput
            label="Category"
            showDropdown
            value={expense?.category || ""}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]"
          />
        </div>

        <div className="flex gap-10 mb-4">
          <DisabledInput
            label="Expense Date"
            showDropdown
            value={getFormattedDate(expense?.expenseDate)}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]"
          />
          <DisabledInput
            label="Amount"
            value={expense?.amount || ""}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]"
          />
        </div>
        <div className="flex gap-10 mb-4">
          <DisabledInput
            label="Paid By"
            showDropdown
            value={expense?.paidBy || ""}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]"
          />
          <DisabledInput
            label="Included Taxes(%)"
            value={expense?.tax || ""}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]"
          />
        </div>
        <div className="flex gap-10 mb-4">
          {imagePreviews.length > 0 && (
            <div className="flex gap-2 mt-4">
              {imagePreviews.map((image, index) => (
                <img
                  key={index}
                  src={typeof image === "string" ? image : ""}
                  alt={`Image ${index + 1}`}
                  className="object-contain max-h-40"
                />
              ))}
            </div>
          )}
        </div>
        <DisabledInput
          label="Notes"
          value={expense?.notes || ""}
          className="w-full p-2 mb-5 border border-gray-400 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500"
        />
      </div>
      <Comms
        validId={validExpense}
        activity={activity}
        handleSubmit={handleMessageSubmit}
      />{" "}
    </div>
  );
}

export default ReimburseExpenseForm;
