import React, { useMemo } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
  useRowSelect,
  Column,
} from "react-table";
import { EmptyState } from "../../../utilities/components/emptyState";
import RowRange from "../../../utilities/customTable/rowsCount";
import GlobalFilter from "../../../utilities/customTable/globalFilter";
import Table from "../../../utilities/customTable/table";
import Pagination from "../../../utilities/customTable/pagination";
import { Employee } from "../../../utilities/types/types";

type OnboardEmployeeTableProps = {
  employees: Employee[];
  rowCount: number;
};
const predefinedColors = [
  "#3498db",
  "#e74c3c",
  "#2ecc71",
  "#f39c12",
  "#9b59b6",
  "#34495e",
  "#1abc9c",
  "#e67e22",
  "#7f8c8d",
  "#c0392b",
];

const OnboardEmployeeTable: React.FC<OnboardEmployeeTableProps> = ({
  employees,
  rowCount,
}) => {
  const data = useMemo(() => {
    return employees.map((employee, index) => ({
      firstName: employee.firstName,
      lastName: employee.lastName,
      middleName: employee.middleName,
      staffId: employee.staffId,
      email: employee.email,
      phone: employee.phone,
      dOB: employee.dOB,
      gender: employee.gender,
      contractType: employee.contractType,
      department: employee.department,
      jobTitle: employee.jobTitle,
      unit: employee.unit,
      employmentType: employee.employmentType,
      employeeSupervisor: employee.employeeSupervisor,
      dateEmployed: employee.dateEmployed,
      hoursPerWeekMin: employee.hoursPerWeekMin,
      hoursPerWeekMax: employee.hoursPerWeekMax,
      payGroup: employee.payGroup,
      payGrade: employee.payGrade,
      adminRights: employee.adminRights,
      groupRights: employee.groupRights,
      message: employee.message,
      details: employee.details,
      color: predefinedColors[index % predefinedColors.length],
      status: employee.status,
      _id: employee._id,
    }));
  }, [employees]);

  const columns: Column<Employee>[] = useMemo(
    () => [
      {
        Header: "Staff ID",
        accessor: "staffId",
      },
      {
        Header: "Employee Name",

        accessor: (row: any) => `${row.firstName} ${row.lastName}`,
        Cell: ({ row }: { row: any }) => {
          const fullName = `${row.original.firstName} ${row.original.lastName}`;
          const capitalizedFullName = fullName
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");

          const initials = `${row.original.firstName
            .charAt(0)
            .toUpperCase()}${row.original.lastName.charAt(0).toUpperCase()}`;

          return (
            <div className="flex items-center space-r-2 ">
              <div
                className={`flex items-center justify-center w-8 h-8 text-white rounded-full flex-2`}
                style={{ backgroundColor: row.original.color }}
              >
                {initials}
              </div>
              <p className="flex-1 p-2 text-left text-[#158b8d] ">
                {capitalizedFullName}
              </p>
            </div>
          );
        },
      },

      {
        Header: "Gender",
        accessor: "gender",
      },
      {
        Header: "Department",
        accessor: "department",
      },
      {
        Header: "Job Title",
        accessor: "jobTitle",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <div>
            <p
              className="flex items-center"
              style={{
                backgroundColor: value === "active" ? "#ECFDF3" : "#FEF3F2",
                borderColor: "transparent",
                width: "80px",
                // textAlign: 'center',
                padding: "0px 0px 0px 8px ",
                borderRadius: "16px",
                color: value === "active" ? "#027A48" : "#B42318",
              }}
            >
              {/* <p
                className='text-3xl'
                style={{
                  color: value === 'active' ? '#027A48' : '#B42318',
                }}
              >
                .
              </p> */}
              {value.charAt(0).toUpperCase() + value.slice(1)}
            </p>
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable<Employee>(
    {
      data,
      columns,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  // const [rowCount, setRowCount] = useState<number>(data.length);
  // const [rows, setRows] = useState<any>(data);
  // const deleteRow = (id: number) => {
  //   setRows((prevRows) => prevRows.filter((row) => row.staffId !== id));
  //   setSelectedRowId(null);
  // };

  // const archiveRow = (id: number) => {
  //   setRows((prevRows) =>
  //     prevRows.map((row) =>
  //       row.staffId === id ? { ...row, archived: true } : row
  //     )
  //   );
  //   setSelectedRowId(null);
  // };

  const { globalFilter, pageIndex, pageSize } = state;
  return (
    <div className=" mx-auto  border-2 border-solid rounded-lg border-[#EAECF0]">
      <div className="">
        <div className="flex justify-start gap-2 px-6 py-5">
          <p className="text-lg text-grayText ">All Employees</p>
          <div className=" border rounded-2xl bg-[#E0F6F6] text-[#158B8D] px-2 py-[2px]">
            <RowRange
              pageIndex={pageIndex}
              pageSize={pageSize}
              rowCount={rowCount}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center lg:gap-[50%] xl:gap-[50%] md:[25%] my-3 mx-4 ">
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        {/* <ButtonIconLink
          className='pt-[10px] pb-[7px] px-4 item-center'
          src={filter}
          link='#'
          text='Filters'
        /> */}
      </div>
      {data.length === 0 ? (
        <EmptyState
          title="No employees data found"
          subtitle=""
          message="Kindly “Add Employee” to populate table data."
          link=""
          actionLabel=""
          icon=""
          status={"default"}
        />
      ) : (
        <div>
          <Table
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            page={page}
            prepareRow={prepareRow}
            data={data}
            editBasePath="/admin/edit-employee"
            viewBasePath="/admin/view-employee"
            tooltip
            tooltipButtons={{
              view: true,
              edit: true,
            }}
          />

          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            gotoPage={gotoPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
          />
        </div>
      )}
    </div>
  );
};

export default OnboardEmployeeTable;
