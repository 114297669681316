// src/features/onboardingFormSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Paygrade_API from "../../../api/paygradeService";
import { FormValues } from "../../../admin/payroll/paygrades/newPaygrade";

export const createNewPaygrade = createAsyncThunk(
  "create/expense",
  async (data: FormValues, thunkAPI) => {
    try {
      const response = await Paygrade_API.create(data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
const createPaygradeSlice = createSlice({
  name: "createExpense",
  initialState: {
    formData: {
      gradeName: "",
      nextLevel: "",
      taxablePaygrade: "",
      netPay: {
        currencyType: null,
        amount: null,
      },
      benefits: {
        Basic: null,
        Housing: null,
        Transport: null,
        Medical: null,
        lifeInsurance: null,
      },
      contributions: {
        employeeDeduction: null,
        companyContribution: null,
        allowance: [],
        nhf: null,
      },
    },
    error: null,
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.formData = {
        gradeName: "",
        nextLevel: "",
        taxablePaygrade: "",
        netPay: {
          currencyType: null,
          amount: null,
        },
        benefits: {
          Basic: null,
          Housing: null,
          Transport: null,
          Medical: null,
          lifeInsurance: null,
        },
        contributions: {
          employeeDeduction: null,
          companyContribution: null,
          allowance: [],
          nhf: null,
        },
      };
      state.error = null;
    },
  },
});

export const { setFormData, setErrors, resetForm } =
  createPaygradeSlice.actions;

export default createPaygradeSlice.reducer;
export type RootState = ReturnType<typeof createPaygradeSlice.reducer>;
