import React from 'react';

import { FormikErrors } from 'formik';

import {
  FormStepValues,
  FormStepValues2,
} from '../../../../../utilities/types/types';
import DisabledInput from '../../../../../utilities/components/disabledInput';

interface Step1Props {
  employeeData: FormStepValues;
  onNextStep: (values: FormStepValues2) => void;
  values: FormStepValues2;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  errors?: FormikErrors<FormStepValues2>;
}

const Step1B: React.FC<Step1Props> = ({
  onNextStep,
  values,
  setFieldValue,
  errors,
  employeeData,
}) => {
  const handleSubmit = () => {
    onNextStep(values);
  };

  function isValidDate(dateString: string | undefined | null): boolean {
    if (!dateString) {
      return false;
    }

    const timestamp = Date.parse(dateString);
    return !isNaN(timestamp);
  }

  return (
    <div>
      <div className='flex gap-6 mb-4'>
        <DisabledInput
          label='First Name'
          value={employeeData?.firstName || ''}
          className='border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]'
        />
        <DisabledInput
          label='Middle Name'
          value={employeeData?.middleName || ''}
          className='border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]'
        />
      </div>

      <div className='flex gap-6 mb-4'>
        <DisabledInput
          label='Last Name'
          value={employeeData?.lastName || ''}
          className='border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]'
        />
        <DisabledInput
          label='Staff ID'
          value={employeeData?.staffId}
          className='border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]'
        />
      </div>

      <div className='flex gap-6 mb-4'>
        <DisabledInput
          label='Email Address'
          value={employeeData?.email || ''}
          className='border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]'
        />
        <DisabledInput
          label='Phone Number'
          value={employeeData?.phone || ''}
          className='border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]'
        />
      </div>

      <div className='flex gap-6 mb-4'>
        <DisabledInput
          label='Date of Birth'
          value={
            isValidDate(employeeData.dOB)
              ? new Date(Date.parse(employeeData.dOB))
              : new Date()
          }
          className='border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]'
        />
        <DisabledInput
          label='Gender'
          showDropdown
          value={employeeData?.gender || ''}
          className='border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]'
        />
      </div>
      <div className='py-5'>
        <hr />
      </div>

      <p className='text-lg font-medium pb-7'>Job Information</p>
      <div className='flex gap-6 mb-4'>
        <DisabledInput
          label='Department'
          showDropdown
          value={employeeData?.department || ''}
          className='border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]'
        />
        <DisabledInput
          label='Job Title'
          value={employeeData?.jobTitle || ''}
          className='border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]'
        />
      </div>

      <div className='flex gap-6 mb-4'>
        <DisabledInput
          label='Unit'
          showDropdown
          value={employeeData?.unit || ''}
          className='border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]'
        />
        <DisabledInput
          label='Employment Type'
          value={employeeData?.employmentType || ''}
          className='border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]'
        />
      </div>

      <div className='flex gap-6'>
        <DisabledInput
          label='Employee Supervisor'
          showDropdown
          value={employeeData?.employeeSupervisor || ''}
          className='border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]'
        />
        <DisabledInput
          label='Date of Employment'
          showDropdown
          value={
            isValidDate(employeeData.dateEmployed)
              ? new Date(Date.parse(employeeData.dateEmployed))
              : new Date()
          }
          className='border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]'
        />
      </div>

      <div className='flex gap-6'>
        <div className=''>
          <p className=''>Hours per week</p>
          <div className='flex gap-6'>
            <div className='flex gap-1'>
              <DisabledInput
                value={employeeData?.hoursPerWeekMin || ''}
                className=' border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500  w-[200px]'
              />
              <p className='pt-5'>min</p>
            </div>
            <p className='pt-5'>to</p>
            <div className='flex gap-1'>
              <DisabledInput
                value={employeeData?.hoursPerWeekMax || ''}
                className='border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[200px]'
              />
              <p className='pt-5'>max</p>
            </div>
          </div>
        </div>
      </div>

      <div className='py-7'>
        <hr />
      </div>

      <p className='text-lg font-medium pb-7'>Pay Information</p>

      <div className='flex gap-6'>
        <DisabledInput
          label='Pay Grade'
          showDropdown
          value={employeeData?.payGrade || ''}
          className='border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]'
        />
      </div>
      <div className='py-5'>
        <hr />
      </div>
      <div className='flex justify-end pb-8'>
        <button
          className='border  text-white bg-greenDark mr-4 rounded-lg px-[18px] py-[10px] text-left'
          type='button'
          onClick={handleSubmit}
        >
          Next
        </button>
      </div>
    </div>
  );
};

export default Step1B;
