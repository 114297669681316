import React from 'react';
import { ReactSVG } from 'react-svg';
import { Btn } from '../button';

interface EmptyStateProps {
  title?: string;
  subtitle?: string;
  message?: string;
  icon?: string;
  link?: string;
  actionLabel?: string;
  status: 'error' | 'danger' | 'info' | 'warning' | 'default';
}

const EmptyState = ({
  title = 'Nothing Here',
  subtitle,
  message,
  link,
  actionLabel,
  icon,
}: EmptyStateProps) => {
  return (
    <div className='flex flex-col items-center justify-center w-full h-[340px] py-8'>
      {icon && (
        <div className='mb-4'>
          <ReactSVG src={icon} />
        </div>
      )}
      <p className='text-2xl font-medium text-center'>{title}</p>
      {subtitle && <p className='text-sm font-light text-center'>{subtitle}</p>}
      {message && <p className='text-center'>{message}</p>}
      {link && actionLabel && (
        <Btn className='bg-greenDark' text={actionLabel} link={link} />
      )}
    </div>
  );
};

export { EmptyState };
