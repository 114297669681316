import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ExpenseAnalyticState } from '../../../utilities/types/types';
import Expense_API from '../../../api/expenseService';

// Define the type for your slice state
export interface ExAnalyticState {
  analytics: ExpenseAnalyticState | null;
}

// Create an async thunk to fetch employee data
export const getExpenseAnalytics = createAsyncThunk(
  'expense-analytics',
  async () => {
    try {
      const response = await Expense_API.expenseAnalytics();
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Create a slice
const expenseAnalyticsSlice = createSlice({
  name: 'expense-analytics',
  initialState: {
    analytics: null,
  } as ExAnalyticState,
  reducers: {
    setExpenseAnalytics: (state, { payload }) => {
      state.analytics = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(analytics.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setExpenseAnalytics } = expenseAnalyticsSlice.actions;

export default expenseAnalyticsSlice.reducer;

// Selectors
// export const selectEmployees = (state:EmployeeState) => state.employees.employees;
// export const selectEmployeesStatus = (state:EmployeeState) =>
//   state.employees.status;
// export const selectEmployeesError = (state:EmployeeState) => state.employees.error;
