import React, { useState } from "react";
import { Formik, Form } from "formik";
import CustomInput from "../../../utilities/forms/customInput";
import { Button } from "../../../utilities/button";
import { GeneralValues } from "../../../utilities/types/types";
import { useAppDispatch } from "../../../redux/hook/authHook";
import { generalSettings } from "../../../redux/slices/payroll/general";
import { toast } from "react-toastify";

import CustomSwitch from "../../../utilities/switch";

interface GeneralProps {
  generalData: GeneralValues;
}

const General = ({ generalData }: GeneralProps) => {
  const [loading, setLoading] = useState(false);
  const dispatch = useAppDispatch();

  const initialValues: GeneralValues = {
    companyDescriptor: generalData?.companyDescriptor || "",
    payGradeEmail: generalData?.payGradeEmail || false,
  };

  const handleSubmit = async (data: GeneralValues) => {
    setLoading(true);
    try {
      await dispatch(generalSettings(data));
      setLoading(false);

      toast.success("Updated successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "colored",
        style: { backgroundColor: "#00989B", color: "white" },
      });
    } catch (error: any) {
      setLoading(false);
      console.error(error);

      if (error?.response?.status === 400) {
        toast.error("Please check your input.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#D92D20", color: "white" },
        });
      } else {
        toast.error("An error occurred. Please try again later.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#D92D20", color: "white" },
        });
      }
    }
  };

  return (
    <div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form>
            <div>
              <p className="text-sm font-medium">Configure Payday email</p>
              <p className="pb-4 text-[#667085]">
                Choose if pay details should be displayed or hidden in employee
                payday emails.
              </p>
              <CustomSwitch
                checked={formik.values.payGradeEmail}
                onCheckedChange={(value: boolean) =>
                  formik.setFieldValue("payGradeEmail", value)
                }
              />
            </div>
            <div className="py-7">
              <hr />
            </div>
            <div>
              <p className="text-sm font-medium">Company Descriptor</p>
              <p className="text-[#667085]">
                The company name that appears on your employee's pay slip.
              </p>
              <div className="pt-4">
                <CustomInput
                  id="companyDescriptor"
                  type="text"
                  name="companyDescriptor"
                  value={formik.values.companyDescriptor}
                  onChange={formik.handleChange}
                  error={formik.errors.companyDescriptor}
                  className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[600px]"
                  wrapperClassName=""
                  placeholder="Enter Company Name"
                />
              </div>
            </div>
            <div className="pt-7">
              <hr />
            </div>
            <div className="flex justify-end py-4">
              <Button
                type="submit"
                className="px-[18px] py-[10px] rounded-lg text-white bg-greenDark"
              >
                Save Changes
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default General;
