import React, { FC, useState } from 'react';
import { ReactSVG } from 'react-svg';
import tooltips from '../../asset/tooltipH.svg';
import del from '../../asset/RedTrash.svg';

import { Button } from '../button';
import { Link } from 'react-router-dom';

type TooltipButtons = {
  view?: boolean;
  edit?: boolean;
  archive?: boolean;
  blank?: boolean;
  delete?: boolean;
  approved?: boolean;
  reiemburse?: boolean;
};

interface TableProps<T> {
  getTableProps: any;
  getTableBodyProps: any;
  headerGroups: any[];
  page: T[];
  prepareRow: any;
  data: T[];
  viewBasePath?: string;
  editBasePath?: string;
  archivedBasePath?: string;
  delBasePath?: string;
  approvedBasePath?: string;
  reiemburseBasePath?: string;
  basePath?: string;
  tooltip?: boolean;
  tooltipButtons?: TooltipButtons;
}

const Table: FC<TableProps<any>> = ({
  getTableProps,
  getTableBodyProps,
  headerGroups,
  page,
  prepareRow,
  basePath,
  viewBasePath,
  editBasePath,
  archivedBasePath,
  delBasePath,
  approvedBasePath,
  reiemburseBasePath,
  data,
  tooltip,
  tooltipButtons,
}: TableProps<any>) => {
  const [selectedRowId, setSelectedRowId] = useState<number | null>(null);
  const [isTooltipOpen, setIsTooltipOpen] = useState<number | null>(null);

  const handleTooltipClick = (id: number) => {
    setIsTooltipOpen((prev) => (prev === id ? null : id));
  };

  const handleRowClick = (id: number) => {
    setSelectedRowId((prev) => (prev === id ? null : id));
  };

  // const archiveRow = (id: number ,) => {
  //   const { clickedId } = useParams();};

  return (
    <div className='flex flex-col mt-4'>
      <div className='-mx-4 -my-2 sm:-mx-6 lg:-mx-8'>
        <div className='inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8'>
          <div className='border-b border-gray-200 shadow sm:rounded-lg'>
            <table
              {...getTableProps()}
              className='min-w-full divide-y divide-gray-200 '
            >
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr
                    {...headerGroup.getHeaderGroupProps()}
                    key={headerGroup.id}
                  >
                    {headerGroup.headers.map((column: any) => (
                      <th
                        {...column.getHeaderProps()}
                        scope='col'
                        key={column.id}
                        className='px-3 py-1 text-xs border-b-greyWhite font-normal tracking-wider text-left text-[#667085] bg-[#fcfcfd] group lg:px-6 lg:py-3 lg:text-sm'
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row) => {
                  prepareRow(row);
                  return (
                    <React.Fragment key={row.id}>
                      <tr
                        {...row.getRowProps()}
                        onClick={(e) => handleRowClick(row.id)}
                        className='hover:bg-greyWhite'
                      >
                        {row.cells.map((cell: any) => (
                          <td
                            {...cell.getCellProps()}
                            key={cell.column.id}
                            className='px-3 py-1 lg:px-6 lg:py-3 text-xs text-tableGrey lg:text-sm whitespace-nowrap border-b border-solid rounded-lg border-[#f2f4f7]'
                          >
                            {cell.render('Cell')}
                          </td>
                        ))}
                        {tooltip && (
                          <td>
                            <Button
                              type='button'
                              onClick={() => handleTooltipClick(row.id)}
                              className='px-6 py-4 text-sm font-medium bg-white text-tableGrey tooltip-button gap-x-3'
                            >
                              <ReactSVG
                                src={tooltips}
                                className={
                                  selectedRowId === row.id ? 'active' : ''
                                }
                              />
                            </Button>
                            {isTooltipOpen === row.id && (
                              <div className='relative inset-0 flex text-center justify-center z-10 top-[-30px] left-[-50px]'>
                                <div className='absolute p-2 bg-white rounded shadow'>
                                  {tooltipButtons?.view && (
                                    <Link
                                      to={`${viewBasePath}/${row.original._id}`}
                                      className='text-sm py-1 px-[6px] flex gap-2 '
                                    >
                                      <p className=''>View</p>
                                    </Link>
                                  )}
                                  {tooltipButtons?.edit && (
                                    <Link
                                      to={`${editBasePath}/${row.original._id}`}
                                      className='text-sm py-1 px-[6px] flex gap-2 '
                                    >
                                      <p className=''>Edit</p>
                                    </Link>
                                  )}
                                  {tooltipButtons?.delete && (
                                    <Link
                                      to={`${delBasePath}/${row.original._id}`}
                                      className='text-xs py-1 px-[6pxsmflex gap-2 text-[#D92D20] '
                                    >
                                      <ReactSVG src={del} />
                                      <p>Delete</p>
                                    </Link>
                                  )}
                                  {tooltipButtons?.archive && (
                                    <Link
                                      to={`${archivedBasePath}/${row.original._id}`}
                                      className='text-sm py-1 px-[6px] flex gap-2 '
                                    >
                                      <p className=''>Archive</p>
                                    </Link>
                                  )}
                                  {tooltipButtons?.approved && (
                                    <Link
                                      to={`${approvedBasePath}/${row.original._id}`}
                                      className='text-sm py-1 px-[6px] flex gap-2 '
                                    >
                                      <p className=''>Approve</p>
                                    </Link>
                                  )}
                                  {tooltipButtons?.reiemburse && (
                                    <Link
                                      to={`${reiemburseBasePath}/${row.original._id}`}
                                      className='text-sm py-1 px-[6px] flex gap-2 '
                                    >
                                      <p className=''>Reimburse</p>
                                    </Link>
                                  )}
                                  {tooltipButtons?.blank && (
                                    <Link
                                      to={`${basePath}/${row.original._id}`}
                                      className='text-sm py-1 px-[6px] flex gap-2 '
                                    >
                                      <p className=''>Blank</p>
                                    </Link>
                                  )}
                                </div>
                              </div>
                            )}
                          </td>
                        )}
                      </tr>
                    </React.Fragment>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Table;
