import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { PaygradeTable } from '../../../utilities/types/types';
import Paygrade_API from '../../../api/paygradeService';

interface PaygradeState {
  paygrade: PaygradeTable[];
}

export const getPaygradeData = createAsyncThunk('paygrade', async () => {
  try {
    const response = await Paygrade_API.payGrades();
    return response.data;
  } catch (error) {
    throw error;
  }
});

// Create a slice
const PaygradeDataSlice = createSlice({
  name: 'paygrade',
  initialState: {
    paygrade: [],
  } as PaygradeState,
  reducers: {
    setPaygradeData: (state, { payload }) => {
      state.paygrade = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(customEarning.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setPaygradeData } = PaygradeDataSlice.actions;

export default PaygradeDataSlice.reducer;
