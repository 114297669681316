import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import {  ExpenseId, activityData } from '../../../utilities/types/types';
import Expense_API from '../../../api/expenseService';

// Define the type for your slice state
interface ActivityState {
  activity: activityData[];
}

// Create an async thunk to fetch employee data
export const getActivities = createAsyncThunk(
  'activity',
  async (expenseId: ExpenseId) => {
    try {
      const response = await Expense_API.getActivity(expenseId);
      return response.messages;
   
    } catch (error) {
      throw error;
    }
  }
);

// Create a slice
const activitySlice = createSlice({
  name: 'activity',
  initialState: {
    activity: [],
  } as ActivityState,
  reducers: {
    setActivity: (state, { payload }) => {
      state.activity = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(activity.pending, (state) => {
  //       state.status = "loading";
  //       state.error = nupproved;
  //     })
  //     .addCase(approvedEmployees.fulfipproveded,
  //     .addCase(approvedEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
})
export const { setActivity } = activitySlice.actions;

export default activitySlice.reducer;
