import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { DepartmentValues } from '../../../utilities/types/types';
import Auth_API from '../../../api/authService/authservice';

interface AllDepartmentState {
  allDepartment: DepartmentValues[];
}

export const getAllDepartments = createAsyncThunk(
  'all-Department',
  async () => {
    try {
      const response = await Auth_API.getDept();
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Create a slice
const allDepartmentSlice = createSlice({
  name: 'all-Department',
  initialState: {
    allDepartment: [],
  } as AllDepartmentState,
  reducers: {
    setAllDepartments: (state, { payload }) => {
      state.allDepartment = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(allDepartment.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setAllDepartments } = allDepartmentSlice.actions;

export default allDepartmentSlice.reducer;
