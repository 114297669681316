import React from "react";
import { Navigate, useLocation } from "react-router-dom";
import Layout from "../layout/index";
import { useAppSelector } from "../redux/hook/authHook";
import { RootState } from "../redux/store";
import AccessDenied from "../utilities/components/accessDenied";

type Role = "superAdmin" | "admin" | "user";

const PrivateRoute: React.FC = () => {
  const location = useLocation();
  const { user, isAuthenticated } = useAppSelector(
    (state: RootState) => state.auth
  );
  if (!isAuthenticated) {
    return <Navigate to="/login" state={{ from: location }} />;
  }
  const allowedRoles: Role[] = ["admin", "superAdmin"];
  if (user?.role && allowedRoles.includes(user.role as Role)) {
    return <Layout />;
  }
  return <AccessDenied />;
};

export default PrivateRoute;
