import React, { FC, ReactNode } from 'react';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  children?: ReactNode;
  modalContentStyle?: string;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full' | string;
}

const Modal: FC<ModalProps> = ({
  isOpen,
  onClose,
  children,
  modalContentStyle,
  size = 'sm',
}) => {
  if (!isOpen) return null;

  let modalSizeClass = '';
  let closeIconSize = 'text-lg';

  if (size === 'xs') {
    modalSizeClass = 'w-[544px] h-[180px]';
    closeIconSize = 'text-lg';
  } else if (size === 'sm') {
    modalSizeClass = 'w-[560px] h-[206px]';
    closeIconSize = 'text-xl';
  } else if (size === 'md') {
    modalSizeClass = 'w-[580px] h-[226px]';
    closeIconSize = 'text-3xl';
  } else if (size === 'lg') {
    modalSizeClass = 'w-[600px] h-[246px]';
    closeIconSize = 'text-lg';
  } else if (size === '2xl') {
    modalSizeClass = 'w-[600px] h-[500px]';
    closeIconSize = 'text-4xl';
  } else if (size === 'xl') {
    modalSizeClass = 'w-[650px] h-[550px]';
    closeIconSize = 'text-5xl';
  } else if (size === 'full') {
    modalSizeClass = 'w-full h-full';
    closeIconSize = 'text-5xl';
  } else if (typeof size === 'string') {
    closeIconSize = 'text-3xl';
    modalSizeClass = size;
  }

  return (
    <div
      className='fixed inset-0 flex items-center justify-center bg-black/70'
      style={{ zIndex: 99999, transform: 'translate3d(0, 0, 0)' }}
    >
      <div className='fixed inset-0 bg-black opacity-50' onClick={onClose} />

      <div
        className={`relative  p-8 bg-white rounded shadow-md ${modalSizeClass} ${modalContentStyle}`}
      >
        <span
          className={`absolute text-gray-600 cursor-pointer top-4 right-4 ${closeIconSize}`}
          onClick={onClose}
        >
          &times;
        </span>

        <div className='overflow-scroll'>{children}</div>
      </div>
    </div>
  );
};

export default Modal;
