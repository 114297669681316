import React, { FC } from 'react';
import { twMerge } from 'tailwind-merge';

interface DisabledInputProps {
  label?: string;
  showDropdown?: boolean;
  value: string | number | Date | string;
  className?: string;
}

const DisabledInput: FC<DisabledInputProps> = ({
  label,
  showDropdown = false,
  value,
  className = '',
}) => {
  return (
    <div className='flex flex-col'>
      <p className='mb-1'>{label}</p>
      <div
        className={twMerge(
          'flex items-center border pl-2 border-gray-300 h-12 rounded-md',
          className
        )}
      >
        {typeof value === 'string' || typeof value === 'number' ? (
          value
        ) : (
          <>
            {value.toLocaleDateString()} {/* Format Date as desired */}
          </>
        )}
        {showDropdown && (
          <div className='flex items-center ml-auto'>
            <svg
              className='w-5 h-5 text-gray-500'
              fill='none'
              stroke='currentColor'
              viewBox='0 0 24 24'
              xmlns='http://www.w3.org/2000/svg'
            >
              <path
                strokeLinecap='round'
                strokeLinejoin='round'
                strokeWidth='2'
                d='M19 9l-7 7-7-7'
              />
            </svg>
          </div>
        )}
      </div>
    </div>
  );
};

export default DisabledInput;
