import React, { useEffect } from "react";
import TitleSection from "../../../utilities/headers/titleSection";
import RunPayrollTable from "./runPayrollTable";

import { useAppDispatch, useAppSelector } from "../../../redux/hook/authHook";
import {
  getPayrollData,
  setPayroll,
} from "../../../redux/slices/payroll/runPayrollData";

function PayrollIndex() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPayrollData()).then((response) => {
      dispatch(setPayroll(response.payload));
    });
  }, [dispatch]);
  const payroll = useAppSelector((state) => {
    return state.runPayroll.payroll;
  });
  const payrollData = payroll?.payrollData || [];
  const payrollRowCount = payrollData.length;

  return (
    <div>
      <TitleSection title="Run Payroll" />
      <div className="p-6 bg-white rounded-md shadow-md">
        <TitleSection
          title={payroll?.totalPayroll.toString()}
          subtitle="Total Payroll"
          greenButtonText="Payroll"
          greenBtnLink="/admin/run-payroll"
          buttonSubtitle={`Payment due on ${payroll?.employeePayDate}`}
          buttonSize="py-[10px] px-[75px]"
        />
      </div>
      <div className="mt-5">
        <RunPayrollTable rowCount={payrollRowCount} payroll={payrollData} />
      </div>
    </div>
  );
}

export default PayrollIndex;
