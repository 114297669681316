import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import Payroll_API from '../../../api/payroll';

export const createPayslip = createAsyncThunk(
  'payslip/create',
  async (_, thunkAPI) => {
    try {
      const response = await Payroll_API.runPaySlip();
      return response; // Return the entire response object
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

interface PayslipState {
  status: string;
  error: string | null;
}

const payslipSlice = createSlice({
  name: 'payslip',
  initialState: {
    status: 'idle', // Change to 'idle'
    error: null,
  } as PayslipState,
  reducers: {
    setErrors: (state, action: PayloadAction<string>) => {
      state.status = 'failed'; // Set status to 'failed'
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.status = 'idle'; // Reset status to 'idle'
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createPayslip.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(createPayslip.fulfilled, (state, action) => {
        state.status = 'succeeded';
        // Assuming the payload is the entire response object
        state.error = null;
      })
      .addCase(createPayslip.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { setErrors, resetForm } = payslipSlice.actions;
export default payslipSlice.reducer;
