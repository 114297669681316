import React, { useState } from 'react';
import * as Yup from 'yup';
import '../../../styles/auth.css';
import { Formik, Form } from 'formik';
import { Link } from 'react-router-dom';
import { Bars } from 'react-loader-spinner';
import CustomInput from '../../utilities/forms/customInput';
import { useAppDispatch } from '../../redux/hook/authHook';
import { email } from '../../utilities/types/types';
import { forgetPassword } from '../../redux/slices/auth/authSlice';

const validationSchema = Yup.object().shape({
  email: Yup.string().required('Email is required').email('Email is invalid'),
});

const ForgotPassword: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const initialValues: email = {
    email: '',
  };

  const dispatch = useAppDispatch();
  const handleSubmit = async (data: email) => {
    setLoading(true);

    try {
      await dispatch(forgetPassword(data));
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
    }
  };

  return (
    <div className='registerForm'>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <Form onSubmit={formik.handleSubmit}>
            <div>
              <div className='formGroup'>
                <CustomInput
                  id='email'
                  label='Email'
                  type='text'
                  name='email'
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.errors.email}
                  wrapperClassName=''
                  className='border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px]'
                  placeholder='Enter Professional Email'
                />
              </div>

              <div>
                <button
                  type='submit'
                  className={`bg-greenWhite font-medium text-white text-base py-2 rounded-md my-6  px-[140px]  ${
                    loading
                      ? 'cursor-not-allowed opacity-50 px-48 '
                      : formik.isValid
                      ? 'bg-greenDarker hover:bg-greenDarker'
                      : 'bg-gray-300'
                  }`}
                  disabled={loading || formik.isSubmitting}
                >
                  {loading ? (
                    <Bars
                      height={20}
                      width={20}
                      color='#fff'
                      ariaLabel='bars-loading'
                    />
                  ) : (
                    'GET RESET LINK'
                  )}
                </button>
                <div className='lineDiv'>
                  <div className='lineLeft'></div>
                  <p>OR</p>
                  <div className='lineRight'></div>
                </div>
                <button className='border border-greyDark font-medium text-black text-base py-2 px-[170px] rounded-md my-6'>
                  <Link to='/'>LOG IN</Link>
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default ForgotPassword;
