import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import UPLOAD_API from '../../../api/uploadService';

export const uploadLogo = createAsyncThunk(
  'uploadLogoColor',
  async (data: any, thunkAPI) => {
    try {
      const response = await UPLOAD_API.uploadLogo(data);

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
const uploadLogoSlice = createSlice({
  name: 'uploadLogo',
  initialState: {
    file: null,
  },
  reducers: {
    setFormData: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});
// Export actions
export const { setFormData } = uploadLogoSlice.actions;

export default uploadLogoSlice.reducer;
export type RootState = ReturnType<typeof uploadLogoSlice.reducer>;
