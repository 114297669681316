// src/features/onboardingFormSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { FormStepValues } from '../../../../utilities/types/types';
import Employee_API from '../../../../api/employeeService';

export const onboardEmployeeNew = createAsyncThunk(
  'onboarding/employee',
  async (data: FormStepValues, thunkAPI) => {
    try {
      // Perform the async operation here, for example, making an API call using axios
      const response = await Employee_API.onboard2(data);

      return response.data;
    } catch (error: any) {
      // Handle any errors that occur during the API call

      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
const onboardingFormNewSlice = createSlice({
  name: 'onboardingFormNew',
  initialState: {
    step: 1,
    formData: {
      firstName: '',
      middleName: '',
      lastName: '',
      staffId: 0,
      email: '',
      phone: '',
      dOB: null,
      gender: '',
      department: '',
      jobTitle: '',
      unit: '',
      employmentType: '',
      employeeSupervisor: '',
      dateEmployed: null,
      hoursPerWeekMin: 0,
      hoursPerWeekMax: 0,
      payGrade: '',
      adminRights: [],
      groupRights: [],
      message: '',
      details: false,
    },
    error: null,
  },
  reducers: {
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.step = 1;
      state.formData = {
        firstName: '',
        middleName: '',
        lastName: '',
        staffId: 0,
        email: '',
        phone: '',
        dOB: null,
        gender: '',
        department: '',
        jobTitle: '',
        unit: '',
        employmentType: '',
        employeeSupervisor: '',
        dateEmployed: null,
        hoursPerWeekMin: 0,
        hoursPerWeekMax: 0,
        payGrade: '',
        adminRights: [],
        groupRights: [],
        message: '',
        details: false,
      };
      state.error = null;
    },
  },
});

// Export actions
export const { setStep, setFormData, setErrors, resetForm } =
  onboardingFormNewSlice.actions;

export default onboardingFormNewSlice.reducer;
export type RootState = ReturnType<typeof onboardingFormNewSlice.reducer>;
