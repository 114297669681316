import { Switch } from "../ui/switch";

interface CustomSwitchProps {
  checked: boolean;
  onCheckedChange: (value: boolean) => void;
}

function CustomSwitch({
  checked,
  onCheckedChange,
  ...rest
}: CustomSwitchProps): JSX.Element {
  return (
    <div>
      <Switch
        checked={checked}
        onCheckedChange={onCheckedChange}
        className="data-[state=checked]:bg-greenDark data-[state=unchecked]:bg-greyTint"
      />
    </div>
  );
}

export default CustomSwitch;
