import React from 'react';
import CustomInput from '../../../../../utilities/forms/customInput';

interface GrouprightProps {
  selectedGroupRights: string[];
  setSelectedGroupRights: (rightList: string[]) => void;
}

function ViewGroupright({
  selectedGroupRights,
  setSelectedGroupRights,
}: GrouprightProps) {
  const groupRights = [
    'Supervisor',
    'HOD',
    'Senior Manager',
    'Manager',
    'Junior Staff',
  ];

  return (
    <div className=''>
      <h2 className='text-xl font-medium '>Group Access</h2>
      <div className='flex flex-col justify-start pl-4 my-3 pt-4 w-[300px] rounded shadow-lg'>
        {groupRights.map((right, index) => (
          <CustomInput
            key={index}
            placeholder=''
            wrapperClassName=''
            type='checkbox'
            disabled
            id={right}
            label={right}
            name={right}
            value={selectedGroupRights.includes(right)}
            onChange={() => {
              const newRightArray = selectedGroupRights.includes(right)
                ? selectedGroupRights.filter((item: any) => item !== right)
                : [...selectedGroupRights, right];
              setSelectedGroupRights(newRightArray);
            }}
            className='mr-3 border-[#D0D5DD]'
          />
        ))}
      </div>
    </div>
  );
}

export default ViewGroupright;
