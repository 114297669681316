import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { expenseCategories } from '../../../utilities/types/types';
import Expense_API from '../../../api/expenseService';

interface AllCategoriesState {
  allCategories: expenseCategories[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: AllCategoriesState = {
  allCategories: [],
  status: 'idle',
  error: null,
};

export const getAllCategories = createAsyncThunk('all-categories', async () => {
  try {
    const response = await Expense_API.allCategories();
    return response.data;
  } catch (error) {
    throw error;
  }
});

// Create a slice
const allCategoriesSlice = createSlice({
  name: 'all-categories',
  initialState,
  reducers: {
    setAllCategories: (state, { payload }) => {
      state.allCategories = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllCategories.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(getAllCategories.fulfilled, (state, { payload }) => {
        state.status = 'succeeded';
        state.allCategories = payload;
      })
      .addCase(getAllCategories.rejected, (state, action) => {
        state.status = 'failed';
        state.error = 'Error fetching categories';
      });
  },
});

export const { setAllCategories } = allCategoriesSlice.actions;

export default allCategoriesSlice.reducer;
