import {
  AdminSignInForm,
  email,
  Passwords,
  resetPasswordValue,
  SignInForm,
  SignUpTypes,
  status,
} from '../../utilities/types/types';
import axiosService from '../axios/axios';

const API_URL = `${process.env.REACT_APP_API_BASE_URL}admin/`;

// const register = (data: SignUpTypes) => {
//   return axios.post(API_URL + "signup", data);
// };

export default class Auth_API {
  static async register(data: SignUpTypes): Promise<any> {
    return await axiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}signup`,
    });
  }
 
  static async login(data: SignInForm): Promise<any> {
    return await axiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}login`,
    });
  }
  static async forgotPassword(data: email): Promise<any> {
    return await axiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}requestToken`,
    });
  }
  static async resetPassword(
    data: resetPasswordValue,
    token: string
  ): Promise<any> {
    return await axiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}resetPassword/${token}`,
    });
  }

  static async admin(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}`,
    });
  }
  // static async loginUser(data: UserSignInForm): Promise<any> {
  //   return await axiosService({
  //     method: "POST",
  //     data: data,
  //     url: `${API_URL}/user/login`,
  //   });
  // }
  static async loginAdmin(data: AdminSignInForm): Promise<any> {
    return await axiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}login`,
    });
  }
  // static async uploadImage(data: any): Promise<any> {
  //   return await axiosService({
  //     method: "POST",
  //     data: data,
  //     url: `${API_URL}upload`,
  //     headers: { "Content-Type": "multipart/form-data" }, // Pass the form data through headers
  //   });
  // }

  static async createDept(data: any): Promise<any> {
    return await axiosService({
      method: "POST",
      data: data,
      url: `${API_URL}department/create`,
    });
  }
  static async updatePassword(data: Passwords): Promise<any> {
    return await axiosService({
      method: 'PATCH',
      data: data,
      url: `${API_URL}update`,
    });
  }
  static async updateDepartment (data: status,departmentId:string): Promise<any> {
    return await axiosService({
      method: 'PATCH',
      data: data,
      url: `${API_URL}department/disable/${departmentId}`,
    });
  }
  static async getDept(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}department`,
    });
  }
 
  static logout = () => {
    localStorage.removeItem('user');
  };
}


