import React, { useState } from "react";
import { FormikProps } from "formik";
import PayrollSelect from "../../../utilities/payrollSelect";
import CustomSelect from "../../../utilities/select";
import { ReactSVG } from "react-svg";
import { FormValues } from "./newPaygrade";
import dropdownIcon from "../../../asset/dropdown copy.svg"; // Updated import name

interface StepThreeProps {
  previousStep: () => void;
  formikProps: FormikProps<FormValues>;
  handleSubmit: () => void;
}

const StepThree: React.FC<StepThreeProps> = ({
  previousStep,
  formikProps,
  handleSubmit,
}) => {
  const nhfOptions = [
    { value: "₦", label: "Naira" },
    { value: "$", label: "USD" },
    { value: "%", label: "%" },
  ];
  const options = [{ value: "%", label: "%" }];
  const allowanceOptions = [
    { value: "Basic", label: "Basic" },
    { value: "Medical", label: "Medical" },
    { value: "Transport", label: "Transport" },
    { value: "Housing", label: "Housing" },
    { value: "lifeInsurance", label: "Life Insurance" },
  ];

  const handleSubmission = () => {
    handleSubmit();
  };

  const { setFieldValue } = formikProps;
  const contributions = ["Pension", "NHF"];

  const [visibleDropdowns, setVisibleDropdowns] = useState<number[]>([]);

  return (
    <div>
      <p className="text-lg font-medium">Statutory Contributions</p>

      {contributions.map((contribution, index) => (
        <div key={index} className="border rounded-lg my-7">
          <div
            onClick={() =>
              setVisibleDropdowns((prevDropdowns) =>
                prevDropdowns.includes(index)
                  ? prevDropdowns.filter((dropdown) => dropdown !== index)
                  : [...prevDropdowns, index]
              )
            }
            className="flex items-center justify-between px-6 py-4 font-medium"
          >
            <div>
              {contribution === "Pension" ? (
                <span>{contribution} / 401k</span>
              ) : (
                <div className="flex items-center ">
                  <span>{contribution}</span>
                  <p className="pl-1 text-xs font-light">(optional)</p>
                </div>
              )}
            </div>
            <div>
              <ReactSVG src={dropdownIcon} />
            </div>
          </div>
          {visibleDropdowns.includes(index) && (
            <div key={index} className="border-t rounded-lg border-x my-7">
              {contribution === "Pension" ? (
                <div className="py-4 mx-5">
                  <div className="flex gap-10 pb-3">
                    <PayrollSelect
                      formik={formikProps}
                      options={options}
                      fieldName={`employeeDeduction`}
                      className="w-[400px]"
                      label={`Employee Deduction`}
                    />
                    <PayrollSelect
                      formik={formikProps}
                      options={options}
                      fieldName={`companyContribution`}
                      className="w-[400px]"
                      label={`Company Contribution`}
                    />
                  </div>

                  <CustomSelect
                    label="Inclusive Allowance of other benefits"
                    name="allowance"
                    options={allowanceOptions}
                    onChange={(selectedValues: any) => {}}
                    isMulti={true}
                    className="w-[550px]"
                  />
                </div>
              ) : (
                <div className="flex py-4 mx-5">
                  <PayrollSelect
                    formik={formikProps}
                    options={nhfOptions}
                    fieldName={`NHF`}
                    className="w-[550px]"
                    label={`Amount/Percentage of Gross Earnings`}
                  />
                  <div className=" w-[550px]"></div>
                </div>
              )}
            </div>
          )}
        </div>
      ))}

      <div className="flex justify-between">
        <button
          type="button"
          onClick={previousStep}
          className="mt-7 border rounded-lg px-[18px] py-[10px]"
        >
          Previous
        </button>
        {contributions.length > 0 && (
          <button
            type="button" // changed to 'button' type since it's not a form submission
            onClick={handleSubmission}
            className="mt-7 border bg-greenDark text-white rounded-lg px-[18px] py-[10px]"
          >
            Submit
          </button>
        )}
      </div>
    </div>
  );
};

export default StepThree;
