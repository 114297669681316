import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { Role, SignInForm, SignUpTypes, UserData,  email } from "../../../utilities/types/types";
import AuthModule from "../../../api/authService/Auth.module";
import Auth_API from "../../../api/authService/authservice";
// Async Thunks for authentication
export const signup = createAsyncThunk("signup", async (data: SignUpTypes, thunkAPI) => {
  try {
    const response = await Auth_API.register(data);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

// export const logOAuthUserInAction = createAsyncThunk(
//   "googleAuthUser",
//   async (data:googleData, { rejectWithValue }) => {
//     try {
//       const configPayload = {
//         headers: {
//           'Content-Type': 'application/json'
//         },
//         data: {
//           token: googleData.tokenId
//         }
//       }

//       const { data } = await axios.post(
//         `${config.BASE_API_URL}/auth/google`,
//         configPayload
//       )

//       AuthModule.authenticateUser(data)

//       AppToaster('Welcome!', 'top-center', 'success')

//       if (router.query && router.query.from) {
//         router.push(`${router.query.from}`)
//       } else {
//         router.push('/dashboard')
//       }
//       return data
//     } catch (ex: any) {
//       const msg = ex.response
//         ? ex.response?.data
//         : new Error('Something went wrong!')

//       AppToaster(msg.message, 'top-center', 'error')
//       return rejectWithValue(msg.message)
//     }
//   }
// )

export const login = createAsyncThunk("login", async (data: SignInForm, thunkAPI) => {
  try {
    const res = await Auth_API.login(data);
    AuthModule.authenticateUser(res);
    thunkAPI.dispatch(setUser({ user: res?.data, isAuthenticated: true, error: "" }));

    return res;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});
export const forgetPassword = createAsyncThunk("forgotPassword", async (data: email, thunkAPI) => {
  try {
    const res = await Auth_API.forgotPassword(data);

    return res;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.res?.data || error.message);
  }
});
// export const resetPassword = createAsyncThunk(
//   "resetPassword",
//   async ((data: resetPasswordValue,token:"ujk", )thunkAPI) => {
//     try {
//       const res = await Auth_API.resetPassword(data,token);

//       return res;
//     } catch (error: any) {
//       return thunkAPI.rejectWithValue(error.res?.data || error.message);
//     }
//   }
// );
export const logout = createAsyncThunk("auth/logout", async () => {
  AuthModule.deAuthenticateUser();
});

// Slice
const userString = localStorage.getItem("user");
const user: UserData | null = userString ? JSON.parse(userString) : null;
const token = localStorage.getItem("token");

export interface AuthState {
  error: string;
  loading: boolean;
  isAuthenticated: boolean | null;
  role: Role | null;
  user: UserData | null;
}
const initialState = {
  error: "",
  loading: false,
  isAuthenticated: !!user && !!token,
  role: null as Role | null,
  user: user || (null as UserData | null),
} as AuthState;

const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    setUser: (state, { payload }: { payload: Partial<typeof initialState> }) => {
      state.role = payload.user?.role ?? state.role;
      state.user = payload.user ?? state.user;
      state.isAuthenticated = payload.isAuthenticated ?? state.isAuthenticated;
      state.loading = payload.loading ?? state.loading;
      state.error = payload.error ?? state.error;
    },
  },
  extraReducers: builder => {
    builder

      .addCase(signup.pending, (state: AuthState) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(signup.fulfilled, (state: AuthState, action) => {
        state.loading = false;
        state.isAuthenticated = true;
      })
      .addCase(
        signup.rejected,
        //  (state, action) => {
        // state.isLoggedIn = false;
        (state: AuthState, { payload }: any) => {
          state.loading = false;
          state.error = payload;
          state.isAuthenticated = false;
        }
      )

      .addCase(login.pending, (state: AuthState) => {
        state.loading = true;
      })
      .addCase(login.fulfilled, (state: AuthState, action) => {
        state.loading = false;
        state.isAuthenticated = true;
      })
      .addCase(
        login.rejected,

        (state: AuthState, { payload }: any) => {
          state.loading = false;
          state.error = payload;
          state.isAuthenticated = false;
          state.user = null;
        }
      )
      .addCase(forgetPassword.pending, (state: AuthState) => {
        state.loading = true;
        state.error = "";
      })
      .addCase(forgetPassword.fulfilled, (state: AuthState, action) => {
        state.loading = false;
      })
      .addCase(forgetPassword.rejected, (state: AuthState, { payload }: any) => {
        state.loading = false;
        state.error = payload;
      })
      .addCase(logout.fulfilled, (state: AuthState, action) => {
        return state;
      });
  },
});

// export const { setUser } = authSlice.actions;
export const { setUser } = authSlice.actions;

export default authSlice.reducer;

// Correct the RootState type
export type RootState = ReturnType<typeof authSlice.reducer>;