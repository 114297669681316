// src/features/onboardingFormSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { EmployeeId, ExitManagement } from "../../../utilities/types/types";
import Archived_API from "../../../api/exitEmployee";

export const archivedEmployeExit = createAsyncThunk(
  "archived/employee",
  async ({ data, employeeId }: { data: ExitManagement; employeeId: EmployeeId }, thunkAPI) => {
    try {
      const response = await Archived_API.archivedEmployeesForm(data, employeeId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
const archivedFormSlice = createSlice({
  name: "exitForm",
  initialState: {
    step: 1,
    formData: {
      message: "",
      title: "",
      remark: "",
      lastDay: new Date(),
      exitReasons: "",
    },
    error: null,
  },
  reducers: {
    setStep: (state, action) => {
      state.step = action.payload;
    },
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: state => {
      state.step = 1;
      state.formData = {
        message: "",
        title: "",
        remark: "",
        lastDay: new Date(),
        exitReasons: "",
      };
      state.error = null;
    },
  },
});

// Export actions
export const { setStep, setFormData, setErrors, resetForm } = archivedFormSlice.actions;

export default archivedFormSlice.reducer;
export type RootState = ReturnType<typeof archivedFormSlice.reducer>;
