import { useState } from "react";
import "../../../../styles/sideBar.css";
import { Button } from "../../../utilities/button";
import { data, datafooter } from "./data/data";
import { NavLink } from "react-router-dom";

interface Item {
  name: string;
  link: string;
}
interface Group {
  name: {
    title: string;
    className: string;
    icon: JSX.Element;
    link?: string;
  };
  items?: Item[];
}

function Main() {
  const [activeDropdown, setActiveDropdown] = useState<number | null>(null);
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const [activeButton, setActiveButton] = useState<number>();
  const toggleDropdown = (index: number) => {
    setActiveDropdown(activeDropdown === index ? null : index);
    setActiveButton(0);
    // navigate(item.link);
  };
  const toggleButton = (index: number) => {
    setActiveButton(index);
  };

  return (
    <div className="flex flex-col items-center justify-between h-full">
      <div className="flex-initial ">
        <div className="grow">
          {data.admin.map((group: Group, index: number) => (
            <div key={index} className="my-2">
              {(group.name.link && (
                <NavLink to={group.name.link}>
                  <div
                    className={`cursor-pointer flex pb-4 items-center ${
                      activeDropdown === index && isHovered
                        ? "bg-[#242e42]"
                        : "text-greenDark"
                    }`}
                    onMouseEnter={() => setIsHovered(true)}
                    onMouseLeave={() => setIsHovered(false)}
                    onClick={() => toggleDropdown(index)}
                  >
                    <div className={group.name.className}>
                      {group.name.icon}
                    </div>
                    <p
                      className={`py-2 ml-4 text-sm font-medium ${
                        activeDropdown === index && !isHovered
                          ? "text-greenDark"
                          : "text-white"
                      }`}
                    >
                      {group.name.title}
                    </p>
                  </div>
                </NavLink>
              )) || (
                <div
                  className={`cursor-pointer flex pb-4 items-center ${
                    activeDropdown === index && isHovered
                      ? "bg-[#242e42]"
                      : "text-greenDark"
                  }`}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  onClick={() => toggleDropdown(index)}
                >
                  <div className={group.name.className}>{group.name.icon}</div>
                  <p
                    className={`py-2 ml-4 text-sm font-medium ${
                      activeDropdown === index && !isHovered
                        ? "text-greenDark"
                        : "text-white"
                    }`}
                  >
                    {group.name.title}
                  </p>
                </div>
              )}
              {activeDropdown === index && group.items && (
                <div className="">
                  {group.items.map((item, itemIndex) => (
                    <Button
                      type="button"
                      key={itemIndex}
                      className={`text-white font-medium text-base py-2 px-8 ${
                        activeButton === itemIndex
                          ? "bg-greenDark border-transparent rounded-lg"
                          : "bg-transparent"
                      }`}
                      onClick={() => toggleButton(itemIndex)}
                    >
                      <NavLink to={item.link}>{item.name}</NavLink>
                    </Button>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>

      {/* Footer Menu */}
      <div className="fixed bottom-0 bg-[#1d2939]">
        {datafooter.admin.map((group, index) => (
          <div key={index}>
            {group.items.map((item, index2) => (
              <div key={index2}>
                <NavLink
                  to={item.link}
                  className={`flex px-4 gap-3 py-1 cursor-pointer ${item.className}`}
                >
                  <div className="py-5">{item.icon}</div>
                  <p className="py-5 text-sm font-medium text-white ">
                    {item.title}
                  </p>
                </NavLink>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default Main;
