import '../../../styles/auth.css';
import AuthLayout from '../authLayout';
import OrganizationVerifyForm from '../orgVerify';
interface OrganizationVerifyProps {
  onLoginSuccess: () => void;
}
function OrgLogin({ onLoginSuccess }: OrganizationVerifyProps) {
  return (
    <>
      {/* <Head>
        <meta name="description" content="" />
        <title>Log In</title>
      </Head> */}
      <AuthLayout>
        <div className='formSection'>
          <div className='pt-40'>
            <h2 className='formHeader'>Log in Portal</h2>
          </div>

          <div>
            <OrganizationVerifyForm onLoginSuccess={onLoginSuccess} />
          </div>
        </div>
      </AuthLayout>
    </>
  );
}

export default OrgLogin;
