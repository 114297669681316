import React, { useEffect, useState } from "react";
import TitleSection from "../../../utilities/headers/titleSection";
import Tabs from "../../../utilities/tabs";
import General from "./general";
import PaySchedule from "./paySchedule";

import { useAppDispatch, useAppSelector } from "../../../redux/hook/authHook";
import {
  getGeneralSettings,
  setGeneral,
} from "../../../redux/slices/payroll/getGeneral";
import {
  payScheduleData,
  setPaySchedule,
} from "../../../redux/slices/payroll/getPayschedule";
import {
  CustomEarnings,
  setCustomEarnings,
} from "../../../redux/slices/payroll/customEarning";
import CustomEarningIndex from "./customEarning";

function PayrollSettings() {
  const [activeTab, setActiveTab] = useState<number>(1);

  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };
  const dispatch = useAppDispatch();
  useEffect(() => {
    // Dispatch the async thunk to fetch employee data when the component mounts
    dispatch(getGeneralSettings()).then((response) => {
      dispatch(setGeneral(response.payload));
    });
    dispatch(payScheduleData()).then((response) => {
      dispatch(setPaySchedule(response.payload));
    });
    dispatch(CustomEarnings()).then((response) => {
      dispatch(setCustomEarnings(response.payload));
    });
  }, [dispatch]);
  const general = useAppSelector((state) => state.generalSettings.general);
  const schedule = useAppSelector((state) => state.payschedulData.paySchedule);
  const earning = useAppSelector((state) => {
    return state.customEarnings.customEarning;
  });
  return (
    <div>
      <TitleSection title="Payroll Settings" />
      <div className="pb-8">
        <Tabs
          tabs={["General", "Pay Schedule", "Custom Earnings"]}
          handleTabClick={handleTabClick}
          activeTab={activeTab}
          activeClassName="border-b"
          inactiveClassName=""
        />
      </div>
      <div>
        {activeTab === 1 && <General generalData={general} />}
        {activeTab === 2 && <PaySchedule generalData={schedule} />}
        {activeTab === 3 && <CustomEarningIndex customEarning={earning} />}
      </div>
    </div>
  );
}

export default PayrollSettings;
//
