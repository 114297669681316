import React, { useMemo } from "react";
import {
  useTable,
  useGlobalFilter,
  useFilters,
  usePagination,
  useRowSelect,
  Column,
} from "react-table";

import { ActiveEmployee } from "../../../utilities/types/types";
import RowRange from "../../../utilities/customTable/rowsCount";
import { EmptyState } from "../../../utilities/components/emptyState";
import Table from "../../../utilities/customTable/table";
import Pagination from "../../../utilities/customTable/pagination";
import { Link } from "react-router-dom";

type ActiveEmployeesTableProps = {
  employees: ActiveEmployee[];
  rowCount: number;
};
const predefinedColors = [
  "#3498db",
  "#e74c3c",
  "#2ecc71",
  "#f39c12",
  "#9b59b6",
  "#34495e",
  "#1abc9c",
  "#e67e22",
  "#7f8c8d",
  "#c0392b",
];

const ActiveEmployeesTable: React.FC<ActiveEmployeesTableProps> = ({
  employees,
  rowCount,
}) => {
  const data = useMemo(
    () =>
      employees.map((employee, index) => ({
        ...employee,
        color: predefinedColors[index % predefinedColors.length],
      })),
    [employees]
  );
  const columns: Column<ActiveEmployee>[] = useMemo(
    () => [
      {
        Header: "Staff ID",
        accessor: "staffId",
      },
      {
        Header: "Employee Name",

        accessor: (row: any) => `${row.firstName} ${row.lastName}`,
        Cell: ({ row }: { row: any }) => {
          const fullName = `${row.original.firstName} ${row.original.lastName}`;
          const capitalizedFullName = fullName
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");

          const initials = `${row.original.firstName
            .charAt(0)
            .toUpperCase()}${row.original.lastName.charAt(0).toUpperCase()}`;

          return (
            <div className="flex items-center space-r-2 ">
              <div
                className={`flex items-center justify-center w-8 h-8 text-white rounded-full flex-2`}
                style={{ backgroundColor: row.original.color }}
              >
                {initials}
              </div>
              <p className="flex-1 p-2 text-left text-[#158b8d] ">
                {capitalizedFullName}
              </p>
            </div>
          );
        },
      },

      {
        Header: "Department",
        accessor: "department",
      },
      {
        Header: "Job Title",
        accessor: "jobTitle",
      },

      {
        Header: "Exit Employee",
        accessor: (data) => `${data._id}`,
        Cell: ({ row }: { row: any }) => (
          <div className="flex space-x-16 text-center">
            <Link
              to={`/admin/exit-form/${row.original._id}`}
              className="flex-1 py-2"
            >
              <p className="w-[90%] lg:w-[65%] border border-[#EAECF0] py-2 rounded-lg ">
                Exit Employee
              </p>
            </Link>
          </div>
        ),
      },
    ],
    []
  );

  // React Table hooks
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable<ActiveEmployee>(
    {
      data,
      columns,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  // Row props

  // Global filter and pagination state
  const { pageIndex, pageSize } = state;

  return (
    <div className="mx-auto  border rounded-lg border-[#EAECF0]">
      <div>
        <div className="flex justify-start gap-2 px-6 py-5">
          <p className="text-lg text-grayText ">Active Employees</p>
          <div className="border rounded-2xl bg-[#E0F6F6] text-[#158B8D] px-2 py-[2px]">
            <RowRange
              pageIndex={pageIndex}
              pageSize={pageSize}
              rowCount={rowCount}
            />
          </div>
        </div>
      </div>
      {/* <div className='flex justify-between items-center lg:gap-[50%] xl:gap-[50%] md:[25%] my-3 mx-4 '>
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        <ButtonIconLink
          className='pt-[10px] pb-[7px] px-4 item-center'
          src={filter}
          link='#'
          text='Filters'
        />
      </div> */}
      {data.length === 0 ? (
        <EmptyState
          title="No employees data found"
          subtitle=""
          message="Kindly “Add Employee” to populate table data."
          link=""
          actionLabel=""
          icon=""
          status={"default"}
        />
      ) : (
        <div>
          <Table
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            page={page}
            prepareRow={prepareRow}
            data={data}
          />

          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            gotoPage={gotoPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
          />
        </div>
      )}
    </div>
  );
};

export default ActiveEmployeesTable;
