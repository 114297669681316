import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from "react-table";
import { EmptyState } from "../../../utilities/components/emptyState";
import Table from "../../../utilities/customTable/table";
import { expenseCategories } from "../../../utilities/types/types";
import CustomSwitch from "../../../utilities/switch";
import { useAppDispatch } from "../../../redux/hook/authHook";
import { toast } from "react-toastify";
import { updateCategoriesForm } from "../../../redux/slices/expenses/updateCategory";

interface CustomCategoryTableProps {
  category: expenseCategories[];
}

function CustomExpenseCategoryTable({ category }: CustomCategoryTableProps) {
  const [data, setData] = useState(() =>
    category.map((category) => ({
      _id: category._id,
      status: category.status,
      categoryName: category.categoryName,
      categoryType: category.categoryType,
    }))
  );

  useEffect(() => {
    setData(
      category.map((categoryItem) => ({
        _id: categoryItem._id,
        status: categoryItem.status,
        categoryName: categoryItem.categoryName,
        categoryType: categoryItem.categoryType,
      }))
    );
  }, [category]);

  const dispatch = useAppDispatch();

  const columns: Column<expenseCategories>[] = useMemo(
    () => [
      {
        Header: "Category Name",
        accessor: "categoryName",
        Cell: ({ value }: { value: string }) => (
          <div>
            {value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
          const [isChecked, setIsChecked] = useState(
            row.original.status === "true"
          );

          const handleCheckedChange = useCallback(
            async (newValue: boolean) => {
              // Update local state optimistically
              setIsChecked(newValue);
              try {
                await dispatch(
                  updateCategoriesForm({
                    categoryId: row.original._id,
                    data: { status: newValue },
                  })
                ).unwrap(); // Unwrap to handle async success/failure
                toast.success("Updated successfully", {
                  position: "top-center",
                  autoClose: 3000,
                  theme: "colored",
                  style: { backgroundColor: "#00989B", color: "white" },
                });
              } catch (error) {
                console.error("Error updating status:", error);
                // Revert local state if API call fails
                setIsChecked(!newValue);
                toast.error("Failed to update. Please try again.", {
                  position: "top-center",
                  autoClose: 3000,
                  theme: "colored",
                });
              }
            },
            [dispatch, row.original._id]
          );

          return (
            <div>
              <CustomSwitch
                checked={isChecked}
                onCheckedChange={(newValue) => handleCheckedChange(newValue)}
              />
            </div>
          );
        },
      },
    ],
    [data]
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    useTable<expenseCategories>(
      {
        data,
        columns,
        initialState: { pageIndex: 0, pageSize: 10 },
      },
      useFilters,
      useGlobalFilter,
      usePagination,
      useRowSelect
    );

  return (
    <div className="mx-auto border-2 border-solid rounded-lg border-[#EAECF0]">
      {data.length === 0 ? (
        <EmptyState
          title="No expense categories found"
          subtitle=""
          message="Kindly “Add new categories” to populate table data."
          link=""
          actionLabel=""
          icon=""
          status="error"
        />
      ) : (
        <Table
          getTableProps={getTableProps}
          getTableBodyProps={getTableBodyProps}
          headerGroups={headerGroups}
          page={page}
          prepareRow={prepareRow}
          data={data}
        />
      )}
    </div>
  );
}

export default CustomExpenseCategoryTable;
