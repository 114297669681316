import React, { useCallback, useMemo, useState } from "react";
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from "react-table";
import RowRange from "../../../utilities/customTable/rowsCount";
import GlobalFilter from "../../../utilities/customTable/globalFilter";
import { EmptyState } from "../../../utilities/components/emptyState";
import Table from "../../../utilities/customTable/table";
import { DepartmentValues } from "../../../utilities/types/types";
import Pagination from "../../../utilities/customTable/pagination";
import { useAppDispatch } from "../../../redux/hook/authHook";
import { toast } from "react-toastify";
import CustomSwitch from "../../../utilities/switch";
import { updateDepartmentForm } from "../../../redux/slices/auth/updateDepartment";

type DepartmentProps = {
  allDepartment: DepartmentValues[];
  rowCount: number;
};
const predefinedColors = [
  "#3498db",
  "#e74c3c",
  "#2ecc71",
  "#f39c12",
  "#9b59b6",
  "#34495e",
  "#1abc9c",
  "#e67e22",
  "#7f8c8d",
  "#c0392b",
  "#7761ab",
];

function DepartmentTable({ allDepartment, rowCount }: DepartmentProps) {
  const dispatch = useAppDispatch();

  const data = useMemo(() => {
    if (!allDepartment) {
      return [];
    }

    return allDepartment.map((department, index) => ({
      department: {
        departmentName: department.department.departmentName,
        units: department.department.units,
        _id: department.department._id,
        status: department.department.status,
      },
      teamMembers: department.teamMembers,
      averageAge: department.averageAge,
      maleEmployees: department.maleEmployees,
      femaleEmployees: department.femaleEmployees,
      color: predefinedColors[index % predefinedColors.length],
      status: department.status,
    }));
  }, [allDepartment]);

  const [departmentStatuses, setDepartmentStatuses] = useState(
    data.map((department) => department.department.status === "true")
  );

  const columns: Column<DepartmentValues>[] = useMemo(
    () => [
      {
        Header: "Department Name",
        accessor: (row: any) => row.department.departmentName,
        Cell: ({ row }: { row: any }) => {
          const fullName = `${row.original.department.departmentName} `;
          const capitalizedFullName = fullName
            .split(" ")
            .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");

          const initials = `${row.original.department.departmentName
            .charAt(0)
            .toUpperCase()}${row.original.department.departmentName
            .charAt(1)
            .toUpperCase()}`;

          return (
            <div className="flex items-center space-r-2 ">
              <div
                className={`flex items-center justify-center w-8 h-8 text-white rounded-full flex-2`}
                style={{ backgroundColor: row.original.color }}
              >
                {initials}
              </div>
              <p className="flex-1 p-2 text-left text-[#158b8d] ">
                {capitalizedFullName}
              </p>
            </div>
          );
        },
      },
      {
        Header: "Team Members",
        accessor: "teamMembers",
      },
      {
        Header: "Average Age",
        accessor: "averageAge",
      },
      {
        Header: "Gender Distribution",
        Cell: ({ row }: { row: any }) => (
          <div className=" w-28">
            <div className="flex items-center ">
              <div
                className="h-3 border border-transparent rounded-lg "
                style={{
                  backgroundColor: "#0BA5EC",
                  width: `${
                    (row.original.maleEmployees / row.original.teamMembers) *
                    100
                  }%`,
                }}
              ></div>
              <div
                className="h-3 border border-transparent rounded-lg "
                style={{
                  backgroundColor: "#EE46BC",
                  width: `${
                    (row.original.femaleEmployees / row.original.teamMembers) *
                    100
                  }%`,
                }}
              ></div>
            </div>
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
          const isChecked =
            departmentStatuses[row.index] ||
            row.original.department.status === "true";

          const handleCheckedChange = useCallback(
            async (newValue: boolean) => {
              try {
                await dispatch(
                  updateDepartmentForm({
                    departmentId: row.original.department._id,
                    data: { status: newValue },
                  })
                );
                // Update local state
                setDepartmentStatuses((prevStatuses) =>
                  prevStatuses.map((status, idx) =>
                    idx === row.index ? newValue : status
                  )
                );
                toast.success("Updated successfully", {
                  position: "top-center",
                  autoClose: 3000,
                  theme: "colored",
                  style: { backgroundColor: "#00989B", color: "white" },
                });
              } catch (error) {
                console.error("Error updating status:", error);
              }
            },
            [dispatch, row.index, row.original.department._id]
          );

          return (
            <div>
              <CustomSwitch
                checked={isChecked}
                onCheckedChange={handleCheckedChange}
              />
            </div>
          );
        },
      },
    ],
    [departmentStatuses, dispatch]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable<DepartmentValues>(
    {
      data,
      columns,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );
  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="mx-auto border-2 border-solid rounded-lg border-[#EAECF0]">
      <div>
        <div className="flex justify-start gap-2 px-6 py-5">
          <p className="text-lg text-grayText">All Departments</p>
          <div className=" border border-transparent rounded-2xl bg-[#E0F6F6] text-[#158B8D] px-2 py-[2px]">
            <RowRange
              pageIndex={pageIndex}
              pageSize={pageSize}
              rowCount={rowCount}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center lg:gap-[50%] xl:gap-[50%] md:[25%] my-3 mx-4 ">
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>
      {data.length === 0 ? (
        <EmptyState
          title="No department found"
          subtitle=""
          message="Kindly “Add department” to populate table data."
          link=""
          actionLabel=""
          icon=""
          status="error"
        />
      ) : (
        <div>
          <Table
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            page={page}
            prepareRow={prepareRow}
            data={page}
          />
          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            gotoPage={gotoPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
          />
        </div>
      )}
    </div>
  );
}

export default DepartmentTable;
