import React, { useEffect, useState } from "react";
import TitleSection from "../../../utilities/headers/titleSection";
import RunPayrollTable from "./runPayrollTable";
import RunPayrollHeader from "./runPayrollHeader";
import PayrollSummaryTable from "./payrollSummarytable";
import { useAppDispatch, useAppSelector } from "../../../redux/hook/authHook";
import {
  getPayrollData,
  setPayroll,
} from "../../../redux/slices/payroll/runPayrollData";
import { createPayslip } from "../../../redux/slices/payroll/runPayslip";
import Step from "../../../utilities/components/step";

function RunPayrollIndex() {
  const [activeTab, setActiveTab] = useState<number>(1);
  const [isPayslipCreated, setIsPayslipCreated] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPayrollData()).then((response) => {
      dispatch(setPayroll(response.payload));
    });
  }, [dispatch]);
  const payroll = useAppSelector((state) => {
    return state.runPayroll.payroll;
  });
  const payrollData = payroll?.payrollData || [];
  const summaryData = payroll?.responseData || [];
  const payrollRowCount = payrollData.length;
  const handleSaveButtonClick = async () => {
    try {
      const response = await dispatch(createPayslip());

      // if (response?.error) {
      //   // Handle the 400 status code scenario here
      // }

      if (response?.payload?.statusCode === 200) {
        setIsPayslipCreated(true);
        setActiveTab(2);
      }
    } catch (error) {
      console.error("An error occurred:", error);
    }
  };

  return (
    <div>
      <TitleSection
        title="Run Payroll"
        subtitle="Fill out required information to run payroll."
      />
      <hr />
      <div className="">
        <Step
          titles={["Run Payroll", "Summary"]}
          activeIndex={activeTab}
          visitedTabClass="border-t-4  w-[50%] text-left "
          inactiveTabClass="border-t-4 border-greenDark w-[50%] text-left pl-2 "
        />
      </div>
      {activeTab === 1 && (
        <>
          <div className="p-6 bg-white rounded-md shadow-md">
            <TitleSection
              title={payroll?.totalPayroll.toString()}
              subtitle="Total Payroll"
              greenButtonText="Run Payroll"
              buttonSubtitle={`Payment due on ${payroll?.employeePayDate}`}
              onSaveButtonClick={handleSaveButtonClick}
              buttonSize="py-[10px] px-14"
            />
          </div>

          <div className="mt-5">
            <RunPayrollTable rowCount={payrollRowCount} payroll={payrollData} />
          </div>
        </>
      )}
      {activeTab === 2 && (
        <>
          <div className="bg-white rounded-md shadow-md ">
            <RunPayrollHeader
              totalAmount={
                payroll?.totalPayroll ? payroll?.totalPayroll.toString() : ""
              }
              isPayrollRun={false}
              payrollData={
                payroll || {
                  payrollData: [],
                  responseData: [],
                  totalPayroll: 0,
                  employeePayDate: "",
                  payrollDate: "",
                }
              }
            />
          </div>
          <div className="py-6 text-2xl ">Payroll Summary</div>{" "}
          <PayrollSummaryTable
            rowCount={payrollRowCount}
            payroll={summaryData}
          />
        </>
      )}
    </div>
  );
}

export default RunPayrollIndex;
