import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { EmployeeId, FormStepValues } from '../../../utilities/types/types';
import Employee_API from '../../../api/employeeService';

interface OneEmployeeState {
  oneEmployee: FormStepValues;
}

export const getOneEmployee = createAsyncThunk(
  'oneEmployee',
  async (employeeId: EmployeeId) => {
    try {
      const response = await Employee_API.getEmployee(employeeId);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Create a slice
const oneEmployeeSlice = createSlice({
  name: 'oneEmployee',
  initialState: {
    oneEmployee: {} as FormStepValues,
  } as OneEmployeeState,
  reducers: {
    setOneEmployee: (state, { payload }) => {
      state.oneEmployee = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOneEmployee.pending, (state) => {
        // Handle pending state if needed
      })
      .addCase(getOneEmployee.fulfilled, (state, action) => {
        state.oneEmployee = action.payload;
      })
      .addCase(getOneEmployee.rejected, (state, action) => {
        // Handle rejected state if needed
      });
  },
});

export const { setOneEmployee } = oneEmployeeSlice.actions;

export default oneEmployeeSlice.reducer;
