import Hr from "../../../../asset/hr.svg";
import expense from "../../../../asset/expense.svg";
import Benefit from "../../../../asset/benefit.svg";
import Performance from "../../../../asset/performance.svg";
import Pm from "../../../../asset/project.svg";
import Help from "../../../../asset/help.svg";
import dash from "../../../../asset/dashboard.svg";
import Settings from "../../../../asset/set.svg";
import { ReactSVG } from "react-svg";

export const data = {
  admin: [
    {
      name: {
        title: "Dashboard",
        className: "",
        icon: <ReactSVG src={dash} />,
        link: "/admin/dashboard",
      },
    },
    {
      name: {
        title: "HR Database",
        className: "",
        icon: <ReactSVG src={Hr} />,
      },
      items: [
        {
          name: "Departments",
          link: "/admin/departments",
        },
        {
          name: "Onboarding",
          link: "/admin/onboarding",
        },

        {
          name: "Exit Management",
          link: "/admin/exit",
        },
      ],
    },

    {
      name: {
        title: "Compensation and Benefit",
        className: "",
        icon: <ReactSVG src={Benefit} />,
      },
      items: [
        {
          name: "Run Payroll",
          link: "/admin/payroll",
        },
        {
          name: "Paygrades",
          link: "/admin/paygrades",
        },
        {
          name: "Payroll Settings",
          link: "/admin/payroll-settings",
        },
      ],
    },
    {
      name: {
        title: "Expense Management",
        className: "",
        icon: <ReactSVG src={expense} />,
      },
      items: [
        {
          name: "Expense Summary",
          link: "/admin/expense",
        },

        {
          name: "Expense Category",
          link: "/admin/expense-category",
        },
      ],
    },
    {
      name: {
        title: "Leave Management",
        className: "pl-18",
        icon: <ReactSVG src={Hr} />,
      },
      items: [
        {
          name: "Leave Management",
          link: "/admin/leave-management",
        },
        {
          name: "Leave Configuration",
          link: "/admin/leave-config",
        },
      ],
    },
    {
      name: {
        title: "Performance",
        className: "",
        icon: <ReactSVG src={Performance} />,
      },
      items: [
        {
          name: "Performance",
          link: "/admin/unsubscribe",
        },
      ],
    },
    // {
    //   name: {
    //     title: 'Time Management',
    //     className: 'pl-18',
    //     icon: <ReactSVG src={Tm} />,
    //   },
    //   items: [
    //     {
    //       name: 'Time Management',
    //       link: '/admin/unsubscribe',
    //     },
    //   ],
    // },
    // {
    //   name: {
    //     title: 'Marketing',
    //     className: '',
    //     icon: <ReactSVG src={Marketing} />,
    //   },
    //   items: [
    //     {
    //       name: 'Marketing',
    //       link: '/admin/unsubscribe',
    //     },
    //   ],
    // },
    {
      name: {
        title: "Project Management",
        className: "",
        icon: <ReactSVG src={Pm} />,
      },
      items: [
        {
          name: "Project Management",
          link: "/admin/unsubscribe",
        },
      ],
    },
  ],
};
export const datafooter = {
  admin: [
    {
      name: "",
      items: [
        {
          title: "Settings",
          icon: <ReactSVG src={Settings} />,
          className: " cursor-pointer border-b border-white",
          link: "/admin/settings",
        },
        {
          title: "Help and Getting started",
          icon: <ReactSVG src={Help} />,
          className: "",
          link: "/admin/unsubscribe",
        },
      ],
    },
  ],
};
