import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Leave_API from '../../../api/leaveService';
import { LeaveCategory} from '../../../utilities/types/types';

interface leaveTypeState {
leaveType: LeaveCategory[];
}

export const getLeaveType = createAsyncThunk('leave-type', async () => {
  try {
    const response = await Leave_API.LeaveType();
    return response.data;
  } catch (error) {
    throw error;
  }
});

// Create a slice
const leaveTypeSlice = createSlice({
  name: 'leave-type',
  initialState: {
    leaveType: [],
  } as leaveTypeState,
  reducers: {
    setLeaveType: (state, { payload }) => {
      state.leaveType = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(allExpense.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setLeaveType } = leaveTypeSlice.actions;

export default leaveTypeSlice.reducer;
