import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { expenseCategories } from '../../../utilities/types/types';
import Expense_API from '../../../api/expenseService';

interface DefaultCategoryState {
  defaultCategory: expenseCategories[];
  status: 'idle' | 'loading' | 'failed'; // State to handle loading and error
}

export const getDefaultCategory = createAsyncThunk(
  'default-category',
  async () => {
    try {
      const response = await Expense_API.defaultCategoryType();
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const defaultCategorySlice = createSlice({
  name: 'default-category',
  initialState: {
    defaultCategory: [],
    status: 'idle', // Initial status is idle
  } as DefaultCategoryState,
  reducers: {
    setDefaultCategory: (state, action) => {
      state.defaultCategory = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDefaultCategory.pending, (state) => {
        state.status = 'loading'; // Set status to 'loading' on pending
      })
      .addCase(getDefaultCategory.fulfilled, (state, action) => {
        state.status = 'idle'; // Set status to 'idle' on successful fetch
        state.defaultCategory = action.payload;
      })
      .addCase(getDefaultCategory.rejected, (state) => {
        state.status = 'failed'; // Set status to 'failed' on fetch failure
      });
  },
});

export const { setDefaultCategory } = defaultCategorySlice.actions;

export default defaultCategorySlice.reducer;
