import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Employee_API from "../../../api/employeeService";
import { DataPoint } from "../../../admin/overview/diagram/charts/chart";

interface AllEmployeeCountState {
  allEmployeeCount: DataPoint[];
}

export const getAllEmployeesCount = createAsyncThunk(
  "all-EmployeeCount",
  async ({ startDate }: { startDate: string }) => {
    try {
      const response = await Employee_API.getChartData(startDate);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Create a slice
const allEmployeeCountSlice = createSlice({
  name: "all-EmployeeCount",
  initialState: {
    allEmployeeCount: [],
  } as AllEmployeeCountState,
  reducers: {
    setAllEmployeesCount: (state, { payload }) => {
      state.allEmployeeCount = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(allEmployee.Countpending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setAllEmployeesCount } = allEmployeeCountSlice.actions;

export default allEmployeeCountSlice.reducer;
