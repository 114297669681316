import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Expense } from '../../../utilities/types/types';
import Expense_API from '../../../api/expenseService';

interface AllExpenseState {
  allExpense: Expense[];
}

export const getAllExpenses = createAsyncThunk('all-expense', async () => {
  try {
    const response = await Expense_API.getAllExpense();
    return response.data;
  } catch (error) {
    throw error;
  }
});

// Create a slice
const allExpenseSlice = createSlice({
  name: 'all-expense',
  initialState: {
    allExpense: [],
  } as AllExpenseState,
  reducers: {
    setAllExpenses: (state, { payload }) => {
      state.allExpense = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(allExpense.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setAllExpenses } = allExpenseSlice.actions;

export default allExpenseSlice.reducer;
