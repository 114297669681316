import React, { useState } from "react";
import TitleSection from "../../../utilities/headers/titleSection";
import add from "../../../asset/add.svg";
import CustomEarningsTable from "./customEarningTable";
import { useAppDispatch } from "../../../redux/hook/authHook";
import { Formik, FormikHelpers } from "formik";
import CustomInput from "../../../utilities/forms/customInput";
import PayrollSelect from "../../../utilities/payrollSelect";
import CustomSelect from "../../../utilities/select";
import { createCustomEarningForm } from "../../../redux/slices/payroll/createCustomEarning";
import { CustomEarning } from "../../../utilities/types/types";

export interface EarningFormValue {
  categoryName: string;
  categorizeEarning: string;
}
interface CustomEarningProps {
  customEarning: CustomEarning[];
}

function CustomEarningIndex({ customEarning }: CustomEarningProps) {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleSubmit = async (
    values: EarningFormValue,
    actions: FormikHelpers<EarningFormValue>
  ) => {
    await dispatch(createCustomEarningForm(values));
    actions.resetForm();
    handleModalClose();

    setTimeout(() => {
      window.location.reload();
    }, 2000);
  };

  const earningsOptions = [
    { value: "Discretionary Bonus", label: "Discretionary Bonus" },
    { value: "Commission", label: "Commission" },
  ];
  const options = [
    { value: "₦", label: "Naira" },
    { value: "$", label: "USD" },
  ];
  const handleCancel = () => {
    handleModalClose();
  };
  return (
    <div>
      <div className="">
        <TitleSection
          title="Custom Earnings"
          subtitle="You might use a custom earning type for a signing bonus or severance pay."
          greenButtonText="Add Custom Earning"
          greenBtnLink=""
          greenButtonIcon={add}
          showModal={true}
          size="2xl h-auto"
          closeModal={handleCancel}
          externalIsModalOpen={isModalOpen}
          externalSetIsModalOpen={setIsModalOpen}
        >
          <div>
            <p className="px-1 pt-5 text-lg font-medium">Add Custom Earnings</p>
            <p className="pb-5 text-sm text-[#667085]">
              Payments are made solely at the employer's discretion and without
              a prior agreement.
            </p>
          </div>
          <hr />
          <div className="pt-4">
            <Formik
              initialValues={{ categoryName: "", categorizeEarning: "" }}
              onSubmit={handleSubmit}
              enableReinitialize={true}
            >
              {(formik) => (
                <form onSubmit={formik.handleSubmit}>
                  <CustomSelect
                    label="Categorize the Earning"
                    name="categorizeEarning"
                    options={earningsOptions}
                    isMulti={false}
                    className="w-[500px]"
                  />

                  <div className="py-5">
                    <CustomInput
                      id="categoryName"
                      label="Category Name"
                      type="text"
                      className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[500px]"
                      wrapperClassName=""
                      placeholder="Enter category"
                      name="categoryName"
                      value={formik.values.categoryName}
                      onChange={formik.handleChange}
                      error={formik.errors.categoryName}
                    />
                  </div>
                  <PayrollSelect
                    formik={formik}
                    options={options}
                    fieldName="amountOfGrossEarnings"
                    className=" rounded-md p-2  focus:outline-none focus:ring-2 focus:ring-gray-500 w-[490px]"
                    label="Amount/Percentage of Gross Earnings"
                  />
                  <div className="flex items-center py-5">
                    <button
                      onClick={handleModalClose}
                      className="border text-grayText border-greyFade mr-4 rounded-lg px-24 py-[10px] text-left"
                    >
                      Cancel
                    </button>
                    <button
                      className="border text-white border-transparent bg-greenDark mr-4 rounded-lg px-24 py-[10px] text-left"
                      type="submit"
                    >
                      Save
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          </div>
        </TitleSection>
      </div>
      <CustomEarningsTable earnings={customEarning} />
    </div>
  );
}

export default CustomEarningIndex;
