import { useEffect, useState } from "react";
import { FaPlus } from "react-icons/fa6";
import TitleSection from "../../../utilities/headers/titleSection";
import add from "../../../asset/add.svg";
import DepartmentTable from "./departmentTable";
import { useAppDispatch, useAppSelector } from "../../../redux/hook/authHook";
import { Formik } from "formik";
import CustomInput from "../../../utilities/forms/customInput";
import {
  getAllDepartments,
  setAllDepartments,
} from "../../../redux/slices/auth/getAllDept";
import { createDepartmentandUnits } from "../../../redux/slices/auth/createDept";
import { toast } from "react-toastify";

export interface DeptFormValue {
  departmentName: string;
  units: string[];
}

function DepartmentIndex() {
  const dispatch = useAppDispatch();
  const initialValues = { departmentName: "", units: [] };

  const [formValues, setFormValues] = useState(initialValues);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    dispatch(getAllDepartments()).then((response) => {
      dispatch(setAllDepartments(response.payload));
    });
  }, [dispatch]);
  const departments = useAppSelector((state) => {
    return state.department.allDepartment;
  });
  const rowCount = departments?.length;
  const handleSubmit = async (values: DeptFormValue) => {
    try {
      const response = await dispatch(createDepartmentandUnits(values));

      const statusCode = response?.payload?.statusCode;

      if (statusCode === 200 || statusCode === 201 || statusCode === 204) {
        toast.success("Department created successfully", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#00989B", color: "white" },
        });
      } else if (
        response?.payload?.message === "This department already exists!"
      ) {
        toast.error("This department already exists", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#F04438", color: "white" },
        });
      } else if (statusCode === 400) {
        toast.error("Enter a department name", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#F04438", color: "white" },
        });
      }

      setFormValues(initialValues);
      handleModalClose();

      dispatch(getAllDepartments()).then((response) => {
        dispatch(setAllDepartments(response.payload));
      });
    } catch (error) {
      console.error("Error in handleSubmit:", error);
    }
  };

  const handleCancel = () => {
    handleModalClose();
  };

  return (
    <div>
      <div className="">
        <TitleSection
          title="Departments"
          greenButtonText="Add Department"
          greenBtnLink=""
          greenButtonIcon={add}
          showModal={true}
          size="w-1/3 h-auto "
          closeModal={handleCancel}
          externalIsModalOpen={isModalOpen}
          externalSetIsModalOpen={setIsModalOpen}
        >
          <div className="">
            <div>
              <p className="text-lg font-medium text-grayText">
                Add Departments
              </p>
              <p className="pb-5 text-sm font-normal text[#475767]">
                Employees are categorised into various departments and units
                based on their roles and responsibilities.
              </p>
            </div>
            <hr />
            <div className="pt-4 ">
              <Formik
                initialValues={initialValues}
                onSubmit={handleSubmit}
                enableReinitialize={true}
              >
                {(formik) => (
                  <form onSubmit={formik.handleSubmit}>
                    <div className="pt-4">
                      <CustomInput
                        id="departmentName"
                        label="New Department"
                        type="text"
                        className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px]"
                        wrapperClassName=""
                        placeholder="e.g Human Resource"
                        name="departmentName"
                        value={formik.values.departmentName}
                        onChange={formik.handleChange}
                        error={formik.errors.departmentName}
                      />
                    </div>
                    <div className="max-h-[300px] overflow-y-auto">
                      {formik.values.units &&
                        formik.values.units.map((unit, index) => (
                          <div key={index} className="pt-4">
                            <CustomInput
                              id={`unit${index}`}
                              label={index === 0 ? `Units` : ""}
                              type="text"
                              className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px]"
                              wrapperClassName=""
                              placeholder={`e.g Unit ${index + 1}`}
                              name={`unit${index}`}
                              value={unit}
                              onChange={(e) =>
                                formik.setFieldValue(
                                  `units[${index}]`,
                                  e.target.value
                                )
                              }
                            />
                          </div>
                        ))}
                      <button
                        type="button"
                        className="text-[#158B8D] bg-[#E0F6F6] text-sm rounded-lg mb-6 mt-5 w-28 h-9 flex gap-2 items-center justify-center"
                        onClick={() =>
                          formik.setFieldValue("units", [
                            ...formik.values.units,
                            "",
                          ])
                        }
                      >
                        <FaPlus className="text-[#158B8D]" /> <p> Add units</p>
                      </button>
                    </div>
                    <div className="flex items-center gap-3">
                      <button
                        className="border rounded-lg text-grayText border-greyFade h-11 w-60"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button
                        className="text-white border border-transparent rounded-lg bg-greenDark h-11 w-60"
                        type="submit"
                      >
                        Save
                      </button>
                    </div>
                  </form>
                )}
              </Formik>
            </div>
          </div>
        </TitleSection>
      </div>
      <DepartmentTable allDepartment={departments} rowCount={rowCount} />
    </div>
  );
}

export default DepartmentIndex;
