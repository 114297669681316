import React from 'react';

import { FormikErrors } from 'formik';
import { FormStepValues2 } from '../../../../../utilities/types/types';
import { Button } from '../../../../../utilities/button';
import ViewAdminAccess from './viewAdminAccess';
import ViewGroupright from './group';

interface Step2Props {
  onNextStep: (values: FormStepValues2) => void;
  onPrevStep: () => void;
  values: FormStepValues2;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  errors?: FormikErrors<FormStepValues2>;
}

const AccessRightForm: React.FC<Step2Props> = ({
  onNextStep,
  onPrevStep,
  values,
  setFieldValue,
  errors,
}) => {
  const handleSubmit = () => {
    onNextStep(values);
  };

  return (
    <div>
      <div>
        <ViewAdminAccess
          selectedAdminRights={values.adminRights}
          setSelectedAdminRights={(rights) =>
            setFieldValue('adminRights', rights)
          }
        />
      </div>
      <div>
        <ViewGroupright
          selectedGroupRights={values.groupRights}
          setSelectedGroupRights={(rights) =>
            setFieldValue('groupRights', rights)
          }
        />
      </div>
      <div className='flex justify-between pb-5'>
        <Button
          type='button'
          onClick={onPrevStep}
          className='border text-grayText border-greyFade mr-4 rounded-lg px-[18px] py-[10px] text-left'
        >
          Previous
        </Button>
        <Button
          type='submit'
          onClick={handleSubmit}
          className='border  text-white bg-greenDark mr-4 rounded-lg px-[18px] py-[10px] text-left'
        >
          Next
        </Button>
      </div>
    </div>
  );
};

export default AccessRightForm;
