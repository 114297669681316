import React, { useEffect } from "react";
import Charts from "./charts";
import TaskValidation from "./taskValidation";
import TitleSection from "../../../utilities/headers/titleSection";
import { useAppDispatch, useAppSelector } from "../../../redux/hook/authHook";
import add from "../../../asset/add.svg";

import {
  getExpenseAnalytics,
  setExpenseAnalytics,
} from "../../../redux/slices/expenses/expenseAnalytics";

function Index() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getExpenseAnalytics()).then((response) => {
      dispatch(setExpenseAnalytics(response.payload));
    });
  }, [dispatch]);

  const Analytics = useAppSelector((state) => state.expenseAnalytics);

  return (
    <div>
      <div>
        <div>
          <TitleSection
            title="Expense Management"
            greenButtonIcon={add}
            greenButtonText="Add New Expense"
            greenBtnLink="/admin/new-expense"
          />
        </div>

        <div className="flex gap-4 px-4 ">
          <TaskValidation data={Analytics} />
          <Charts />
        </div>
      </div>
    </div>
  );
}

export default Index;
