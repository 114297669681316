// src/features/onboardingFormSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Expense_API from '../../../api/expenseService';

export const createNewExpenses = createAsyncThunk(
  'create/expense',
  async (data: any, thunkAPI) => {
    try {
      const response = await Expense_API.create(data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
const createExpenseSlice = createSlice({
  name: 'createExpense',
  initialState: {
    formData: {
      description: '',
      category: '',
      expenseDate: '',
      amount: '',
      employee: '',
      paidBy: '',
      tax: '',
      file: [],
      notes: '',
    },
    error: null,
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.formData = {
        description: '',
        category: '',
        expenseDate: '',
        amount: '',
        employee: '',
        paidBy: '',
        tax: '',
        file: [],
        notes: '',
      };
      state.error = null;
    },
  },
});

export const { setFormData, setErrors, resetForm } = createExpenseSlice.actions;

export default createExpenseSlice.reducer;
export type RootState = ReturnType<typeof createExpenseSlice.reducer>;
