import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import CustomInput from "../../utilities/forms/customInput";
import ImageUpload from "../../utilities/forms/customImageUpload";
import { Admin } from "../../redux/slices/auth/adminDetails";
import { Button } from "../../utilities/button";

interface AccountInfoFormProps {
  admin: Admin | null;
}

const BioProfile = ({ admin }: AccountInfoFormProps) => {
  const [initialValues, setInitialValues] = useState({
    companyName: admin?.companyName || "",
    quickoppsLink: admin?.quiickopsUrl || "",
    firstName: admin?.firstName || "",
    lastName: admin?.lastName || "",
    email: admin?.email || "",
    phone: admin?.phoneNumber || "",
  });

  useEffect(() => {
    setInitialValues({
      companyName: admin?.companyName || "",
      quickoppsLink: admin?.quiickopsUrl || "",
      firstName: admin?.firstName || "",
      lastName: admin?.lastName || "",
      email: admin?.email || "",
      phone: admin?.phoneNumber || "",
    });
  }, [admin]);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    onSubmit: (values) => {},
  });

  return (
    <div>
      <div className="">
        <ImageUpload
          circle={<p>Image Here</p>}
          firstName={admin?.firstName}
          lastName={admin?.lastName}
          image={admin?.profileImage}
        />
      </div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex gap-6">
          <CustomInput
            id="companyName"
            label="Company Name"
            disabled={true}
            type="text"
            name="companyName"
            value={formik.values.companyName}
            onChange={formik.handleChange}
            error={formik.errors.companyName}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[520px]"
            wrapperClassName=""
            placeholder="Enter Company Name"
          />
          <CustomInput
            id="quickoppsLink"
            label="Your Quiickops URL"
            disabled={true}
            type="text"
            name="quickoppsLink"
            value={formik.values.quickoppsLink}
            onChange={formik.handleChange}
            error={formik.errors.quickoppsLink}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[520px]"
            wrapperClassName=""
            placeholder="plum.quiickops.com"
          />
        </div>
        <div className="flex gap-6">
          <CustomInput
            id="firstName"
            label="First Name"
            type="text"
            name="firstName"
            value={formik.values.firstName}
            onChange={formik.handleChange}
            error={formik.errors.firstName}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[520px]"
            wrapperClassName=""
            placeholder="Enter Full Name"
          />
          <CustomInput
            id="lastName"
            label="Last Name"
            type="text"
            name="lastName"
            value={formik.values.lastName}
            onChange={formik.handleChange}
            error={formik.errors.lastName}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[520px]"
            wrapperClassName=""
            placeholder="Enter Full Name"
          />
        </div>
        <div className="flex gap-6">
          <CustomInput
            id="email"
            label="Professional Email Address"
            type="text"
            name="email"
            disabled={true}
            value={formik.values.email}
            onChange={formik.handleChange}
            error={formik.errors.email}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[520px]"
            wrapperClassName=""
            placeholder="Enter Professional Email Address"
          />
          <CustomInput
            id="phone"
            label="Phone Number"
            type="tel"
            name="phone"
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.errors.phone}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[520px]"
            wrapperClassName=""
            placeholder="Enter Phone Number"
          />
        </div>
        <div className="flex justify-end">
          <Button
            type="submit"
            className="border text-white bg-greenDark mr-4 rounded-lg px-[18px] py-[10px] text-left"
          >
            Save changes
          </Button>
        </div>
      </form>
    </div>
  );
};

export default BioProfile;
