import React, { useCallback, useMemo } from "react";
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from "react-table";

import { EmptyState } from "../../../utilities/components/emptyState";
import Table from "../../../utilities/customTable/table";
import { CustomEarning } from "../../../utilities/types/types";
import CustomSwitch from "../../../utilities/switch";
import { updateCustomEarningForm } from "../../../redux/slices/payroll/updateCustomEarnings";
import { useAppDispatch } from "../../../redux/hook/authHook";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

type CustomEarningProps = {
  earnings: CustomEarning[];
};

function CustomEarningsTable({ earnings }: CustomEarningProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const data = useMemo(() => {
    return earnings.map((earning) => ({
      categorizeEarning: earning.categorizeEarning,
      categoryName: earning.categoryName,
      amountOfGrossEarnings: {
        amountOfGrossEarnings:
          earning.amountOfGrossEarnings.amountOfGrossEarnings,
        currencyType: earning.amountOfGrossEarnings.currencyType,
      },
      status: earning.status,
      _id: earning._id,
    }));
  }, [earnings]);

  const columns: Column<CustomEarning>[] = useMemo(
    () => [
      {
        Header: "Name of earning",
        accessor: "categorizeEarning",
      },
      {
        Header: "Category",
        accessor: "categoryName",
      },
      {
        Header: "Gross earning setting",
        accessor: "amountOfGrossEarnings",
        Cell: ({
          cell: { value },
        }: {
          cell: {
            value: { amountOfGrossEarnings: number; currencyType: string };
          };
        }) => (
          <div>
            <p>{`${value.currencyType} ${value.amountOfGrossEarnings} `}</p>
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
          const isChecked = row.original.status === "true";
          const handleCheckedChange = useCallback(
            async (newValue: boolean) => {
              try {
                dispatch(
                  updateCustomEarningForm({
                    earningId: row.original._id,
                    data: { status: newValue },
                  })
                );
                setTimeout(() => {
                  window.location.reload();
                }, 3000);
                toast.success("Updated successfully", {
                  position: "top-center",
                  autoClose: 3000,
                  theme: "colored",
                  style: { backgroundColor: "#00989B", color: "white" },
                });
              } catch (error) {
                console.error("Error updating status:", error);
                // Handle error, potentially revert local state or display a message
              }
            },
            [dispatch, row.original._id]
          );

          return (
            <div>
              <CustomSwitch
                checked={isChecked}
                onCheckedChange={handleCheckedChange}
              />
            </div>
          );
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
  } = useTable<CustomEarning>(
    {
      data,
      columns,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  // Function to handle status change

  return (
    <div className="mx-auto border-2 border-solid rounded-lg border-[#EAECF0]">
      {data.length === 0 ? (
        <EmptyState
          title="No Custom Earnings found"
          subtitle=""
          message="Kindly “Create Custom Earnings” to populate table data."
          link=""
          actionLabel=""
          icon=""
          status="error"
        />
      ) : (
        <div>
          <Table
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            page={page}
            prepareRow={prepareRow}
            data={page}
          />
        </div>
      )}
    </div>
  );
}

export default CustomEarningsTable;
