import React from "react";
import { ExAnalyticState } from "../../../redux/slices/expenses/expenseAnalytics";

interface TaskValidationProps {
  data: ExAnalyticState;
}

const TaskValidation: React.FC<TaskValidationProps> = ({ data }) => {
  return (
    <div className="p-4 border rounded-lg border-[#EAECF0] w-[25%]">
      <div>
        <p className="text-xl font-medium ">Task Validation</p>
        <div className="pt-4 pb-[63px]">
          <p className="text-4xl font-medium">
            <p>{data.analytics?.requestedExpenses}</p>
          </p>
          <p className="text-[#667085]">
            You have {data.analytics?.requestedExpenses} task awaiting
            validation
          </p>
        </div>
      </div>
      <div>
        <div className="flex items-center gap-2 py-2">
          <div
            className="text-center text-white border border-transparent rounded-full bg-greenDark"
            style={{
              width: `${
                20 +
                (data.analytics?.approvedExpenses ?? "").toString().length * 10
              }px`,
              height: `${
                20 +
                (data.analytics?.approvedExpenses ?? "").toString().length * 10
              }px`,
            }}
          >
            <p className="pt-[3px]">{data.analytics?.requestedExpenses}</p>
          </div>
          <div className="flex gap-4">
            <p className="text-greenDark ">Request Expense</p>
            <div> </div>
          </div>
        </div>
        <div className="flex items-center gap-2 py-2">
          <div
            className="text-center text-white border border-transparent rounded-full bg-greenDark"
            style={{
              width: `${
                20 +
                (data.analytics?.approvedExpenses ?? "").toString().length * 10
              }px`,
              height: `${
                20 +
                (data.analytics?.approvedExpenses ?? "").toString().length * 10
              }px`,
            }}
          >
            <p className="pt-[3px]">{data.analytics?.approvedExpenses}</p>
          </div>

          <div className="flex gap-4">
            <p className="text-greenDark">Approved Expenses</p>
            <div></div>
          </div>
        </div>
        <div className="flex items-center gap-2 py-2">
          <div
            className="text-center text-[#344054] border border-transparent rounded-full bg-[#f2f4f7]"
            style={{
              width: `${
                20 +
                (data.analytics?.approvedExpenses ?? "").toString().length * 10
              }px`,
              height: `${
                20 +
                (data.analytics?.approvedExpenses ?? "").toString().length * 10
              }px`,
            }}
          >
            <p className="pt-[3px]"> {data.analytics?.reimbursedExpenses}</p>
          </div>

          <div className="flex gap-4">
            <p className="text-greenDark">To be reimbursed</p>
            <div></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TaskValidation;
