import React, { useMemo, useState, useEffect } from "react";
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from "react-table";
import { EmptyState } from "../../../utilities/components/emptyState";
import Table from "../../../utilities/customTable/table";
import RowRange from "../../../utilities/customTable/rowsCount";
import GlobalFilter from "../../../utilities/customTable/globalFilter";
import Pagination from "../../../utilities/customTable/pagination";
import { LeaveCategory } from "../../../utilities/types/types";
import CustomSwitch from "../../../utilities/switch";
import { useAppDispatch } from "../../../redux/hook/authHook";
import { toast } from "react-toastify";
import { updateLeaveType } from "../../../redux/slices/leave/updateCategory";
import { useNavigate } from "react-router-dom";
import { leaveTypeDelete } from "../../../redux/slices/leave/deleteLeaveType";

type LeaveCategoryTableProps = {
  leaveCategories: LeaveCategory[];
  rowCount: number;
};

function LeaveType({
  leaveCategories: initialLeaveCategories,
  rowCount,
}: LeaveCategoryTableProps) {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const [leaveCategories, setLeaveCategories] = useState<LeaveCategory[]>(
    initialLeaveCategories
  );
  const [leaveStatuses, setLeaveStatuses] = useState<boolean[]>([]);

  useEffect(() => {
    setLeaveCategories(initialLeaveCategories);
    if (initialLeaveCategories.length > 0) {
      const statuses = initialLeaveCategories.map(
        (category) => category.status
      );
      setLeaveStatuses(statuses);
    }
  }, [initialLeaveCategories]);

  const handleDelete = async (categoryId: string) => {
    try {
      const resultAction = await dispatch(leaveTypeDelete(categoryId));

      if (leaveTypeDelete.fulfilled.match(resultAction)) {
        // Update state to remove the deleted category
        setLeaveCategories((prevCategories) =>
          prevCategories.filter((category) => category._id !== categoryId)
        );

        toast.success("Deleted successfully", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#00989B", color: "white" },
        });
      } else {
        toast.error(`Failed to delete: ${resultAction.payload}`, {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#f44336", color: "white" },
        });
      }
    } catch (error) {
      console.error("Error deleting category:", error);
      toast.error("An unexpected error occurred", {
        position: "top-center",
        autoClose: 3000,
        theme: "colored",
        style: { backgroundColor: "#f44336", color: "white" },
      });
    }
  };

  const handleEdit = (categoryId: string) => {
    navigate(`/admin/edit-leave-category/${categoryId}`);
  };

  const columns: Column<LeaveCategory>[] = useMemo(
    () => [
      {
        Header: "Category Name",
        accessor: "leaveName",
      },
      {
        Header: "Active",
        accessor: "status",
        Cell: ({ row }) => {
          const isChecked = leaveStatuses[row.index] ?? false;

          const handleCheckedChange = async (newValue: boolean) => {
            try {
              await dispatch(
                updateLeaveType({
                  categoryId: row.original._id,
                  data: {
                    status: newValue,
                    leaveName: row.original.leaveName,
                  },
                })
              );

              setLeaveStatuses((prevStatuses) =>
                prevStatuses.map((status, idx) =>
                  idx === row.index ? newValue : status
                )
              );
              toast.success("Updated successfully", {
                position: "top-center",
                autoClose: 3000,
                theme: "colored",
                style: { backgroundColor: "#00989B", color: "white" },
              });
            } catch (error) {
              console.error("Error updating status:", error);
            }
          };

          return (
            <div>
              <CustomSwitch
                checked={isChecked}
                onCheckedChange={handleCheckedChange}
              />
            </div>
          );
        },
      },
      {
        Header: "Actions",
        accessor: "_id",
        Cell: ({ row }) => {
          return (
            <div className="flex gap-2">
              <button
                onClick={() => handleEdit(row.original._id)}
                className="text-blue-500 hover:underline"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clip-path="url(#clip0_9622_5103)">
                    <path
                      d="M14.1641 2.49993C14.3829 2.28106 14.6428 2.10744 14.9287 1.98899C15.2147 1.87054 15.5212 1.80957 15.8307 1.80957C16.1403 1.80957 16.4468 1.87054 16.7327 1.98899C17.0187 2.10744 17.2785 2.28106 17.4974 2.49993C17.7163 2.7188 17.8899 2.97863 18.0083 3.2646C18.1268 3.55057 18.1878 3.85706 18.1878 4.16659C18.1878 4.47612 18.1268 4.78262 18.0083 5.06859C17.8899 5.35455 17.7163 5.61439 17.4974 5.83326L6.2474 17.0833L1.66406 18.3333L2.91406 13.7499L14.1641 2.49993Z"
                      stroke="#667085"
                      stroke-width="1.66667"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </g>
                  <defs>
                    <clipPath id="clip0_9622_5103">
                      <rect width="20" height="20" fill="white" />
                    </clipPath>
                  </defs>
                </svg>
              </button>
              <button
                onClick={() => handleDelete(row.original._id)}
                className="text-red-500 hover:underline"
              >
                <svg
                  width="20"
                  height="20"
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.5 5.00033H4.16667M4.16667 5.00033H17.5M4.16667 5.00033V16.667C4.16667 17.109 4.34226 17.5329 4.65482 17.8455C4.96738 18.1581 5.39131 18.3337 5.83333 18.3337H14.1667C14.6087 18.3337 15.0326 18.1581 15.3452 17.8455C15.6577 17.5329 15.8333 17.109 15.8333 16.667V5.00033H4.16667ZM6.66667 5.00033V3.33366C6.66667 2.89163 6.84226 2.46771 7.15482 2.15515C7.46738 1.84259 7.89131 1.66699 8.33333 1.66699H11.6667C12.1087 1.66699 12.5326 1.84259 12.8452 2.15515C13.1577 2.46771 13.3333 2.89163 13.3333 3.33366V5.00033M8.33333 9.16699V14.167M11.6667 9.16699V14.167"
                    stroke="#667085"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </button>
            </div>
          );
        },
      },
    ],
    [leaveStatuses, dispatch]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable<LeaveCategory>(
    {
      data: leaveCategories,
      columns,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="mx-auto border-2 border-solid rounded-lg border-[#EAECF0]">
      <div>
        <div className="flex justify-start gap-2 px-6 py-5">
          <p className="text-lg text-grayText">Leave Categories</p>
          <div className="border rounded-2xl bg-[#E0F6F6] text-[#158B8D] px-2 py-[2px]">
            <RowRange
              pageIndex={pageIndex}
              pageSize={pageSize}
              rowCount={rowCount}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center lg:gap-[50%] xl:gap-[50%] md:[25%] my-3 mx-4">
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>

      {leaveCategories.length === 0 ? (
        <EmptyState
          title="No leave categories found"
          subtitle=""
          message="Kindly “Create Leave Categories” to populate table data."
          link=""
          actionLabel=""
          icon=""
          status="error"
        />
      ) : (
        <div>
          <Table
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            page={page}
            prepareRow={prepareRow}
            data={leaveCategories}
          />

          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            gotoPage={gotoPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
          />
        </div>
      )}
    </div>
  );
}

export default LeaveType;
