import React, { useEffect, useState } from "react";
import TitleSection from "../../../utilities/headers/titleSection";
import add from "../../../asset/add.svg";
import DefaultExpenseCategoryTable from "../tables/defaultCategoriesTable";
import CustomExpenseCategoryTable from "../tables/customExpenseCategories";
import CustomInput from "../../../utilities/forms/customInput";
import { Formik, FormikHelpers } from "formik";
import { useAppDispatch, useAppSelector } from "../../../redux/hook/authHook";
import { categoryForm } from "../../../redux/slices/expenses/category";
import {
  getCustomCategory,
  setCustomCategory,
} from "../../../redux/slices/expenses/customCategories";
import {
  getDefaultCategory,
  setDefaultCategory,
} from "../../../redux/slices/expenses/defaultCategories";

export interface CategoryFormValue {
  categoryName: string;
}
function ExpenseCategories() {
  const dispatch = useAppDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  const handleSubmit = async (
    values: CategoryFormValue,
    actions: FormikHelpers<CategoryFormValue>
  ) => {
    await dispatch(categoryForm(values));
    actions.resetForm();
    handleModalClose();
    dispatch(getCustomCategory());
  };

  useEffect(() => {
    dispatch(getCustomCategory()).then((response) => {
      dispatch(setCustomCategory(response.payload));
    });
    dispatch(getDefaultCategory()).then((response) => {
      dispatch(setDefaultCategory(response.payload));
    });
  }, [dispatch]);

  const customCategories = useAppSelector(
    (state) => state.customCategory.customCategory
  );
  const defaultCategories = useAppSelector(
    (state) => state.defaultCategory.defaultCategory
  );
  const handleCancel = () => {
    handleModalClose();
  };

  return (
    <div>
      <TitleSection
        title="Expense Categories"
        greenButtonText="Add New Category"
        greenButtonIcon={add}
        showModal={true}
        closeModal={handleCancel}
        externalIsModalOpen={isModalOpen}
        externalSetIsModalOpen={setIsModalOpen}
      >
        <p className="px-1 py-5 text-lg font-semibold">Add Category</p>
        <Formik
          initialValues={{ categoryName: "" }}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {(formik) => (
            <form onSubmit={formik.handleSubmit}>
              <CustomInput
                id="categoryName"
                label="Category"
                type="text"
                className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[300px] lg:w-[500px]"
                wrapperClassName=""
                placeholder="Enter category"
                name="categoryName"
                value={formik.values.categoryName}
                onChange={formik.handleChange}
                error={formik.errors.categoryName}
              />
              <div>
                <button
                  type="button"
                  className="border text-grayText border-greyFade mr-4 rounded-lg px-12 py-[5px] lg:px-24 lg:py-[10px] text-left"
                  onClick={handleCancel}
                >
                  Cancel
                </button>
                <button
                  className="border text-white border-transparent bg-greenDark mr-4 rounded-lg px-12 py-[5px] lg:px-24 lg:py-[10px] text-left"
                  type="submit"
                >
                  Save
                </button>
              </div>
            </form>
          )}
        </Formik>
      </TitleSection>
      <div className="w-[95%] pb-[50px] pl-5 xl:pl-0">
        <p className="px-6 py-5 text-lg font-medium">Custom Categories</p>
        <div>
          <CustomExpenseCategoryTable category={customCategories} />
        </div>
        <p className="px-6 py-4 text-lg font-medium">Default Categories</p>
        <div>
          <DefaultExpenseCategoryTable category={defaultCategories} />
        </div>
      </div>
    </div>
  );
}

export default ExpenseCategories;
