import React, { useState, ReactElement } from "react";
import { useAppDispatch } from "../../redux/hook/authHook";
import { ReactSVG } from "react-svg";
import upload from "../../asset/cloudUpload.svg";
import { uploadImage } from "../../redux/slices/auth/imageColorUpload";
import { ThreeDots } from "react-loader-spinner";
import { toast } from "react-toastify";

interface ImageUploadProps {
  square?: ReactElement;
  circle?: ReactElement;
  firstName?: string | null;
  lastName?: string | null;
  image?: string | null;
}

function ImageUpload({ firstName, lastName, image }: ImageUploadProps) {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(false);

  const getInitials = () => {
    if (firstName && lastName) {
      return `${firstName.charAt(0)}${lastName.charAt(0)}`;
    } else if (firstName) {
      return firstName.charAt(0);
    } else {
      return "";
    }
  };

  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setSelectedImage(file || null);
  };

  const handleUpload = async (): Promise<void> => {
    if (selectedImage) {
      const formData = new FormData();
      formData.append("file", selectedImage);

      try {
        setLoading(true);

        const response = await dispatch(uploadImage(formData));
        if (response?.payload?.error) {
          toast.error("Try again", {
            position: "top-center",
            autoClose: 3000,
            theme: "colored",
            style: { backgroundColor: "#F04438", color: "white" },
          });
        } else if (
          response?.payload?.statusCode === 200 ||
          response?.payload?.statusCode === 201 ||
          response?.payload?.statusCode === 204
        ) {
          toast.success("Profile Image uploaded successfully", {
            position: "top-center",
            autoClose: 3000,
            theme: "colored",
            style: { backgroundColor: "#00989B", color: "white" },
          });
          // navigate('/admin/dashboard');
        }
      } catch (error) {
        // Handle error
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="">
      <div className="flex items-center gap-4 py-5">
        {!selectedImage && !image && (
          <div className="relative  overflow-hidden text-lg font-medium border border-gray-500 rounded-full bg-greenLight text-greenDark w-20 h-20 flex items-center justify-center">
            {getInitials()}
          </div>
        )}
        <div
          className={`flex items-center gap-3 ${
            selectedImage || image
              ? ""
              : "flex-row-reverse justify-end items-center pb-5"
          }`}
        >
          <div className="flex">
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              className="hidden"
              id="imageInput"
            />
            <label
              htmlFor="imageInput"
              className={`gap-2 rounded-lg cursor-pointer ${
                selectedImage || image ? "" : ""
              }`}
            >
              {selectedImage || image ? (
                <div className="relative mt-4 aspect-w-1 aspect-h-1">
                  <div className="pr-4 overflow-hidden mb-7 aspect-w-1 aspect-h-1">
                    <img
                      src={
                        selectedImage
                          ? URL.createObjectURL(selectedImage)
                          : image || undefined
                      }
                      alt="profile image"
                      className="object-cover w-20 h-20 rounded-full"
                    />
                  </div>
                </div>
              ) : (
                <div className="flex gap-2">
                  <ReactSVG src={upload} />
                  Upload Image
                </div>
              )}
            </label>
          </div>
          <div className="">
            <p className="font-medium">
              {firstName
                ? firstName.charAt(0).toUpperCase() + firstName.slice(1)
                : ""}
              &nbsp;
              {lastName
                ? lastName.charAt(0).toUpperCase() + lastName.slice(1)
                : ""}
            </p>
            <p>Account Owner</p>
          </div>
        </div>
      </div>
      {selectedImage && (
        <button
          className="gap-2 border mb-6 px-4 py-[10px] border-[#D0D5DD] rounded-lg cursor-pointer flex"
          onClick={handleUpload}
        >
          {loading ? (
            <div className="flex items-center justify-center">
              <ThreeDots
                height="20"
                width="80"
                color="#15AFB2"
                wrapperStyle={{}}
                wrapperClass=""
                visible={true}
                radius="9"
              />
            </div>
          ) : (
            "Upload Image"
          )}
        </button>
      )}
    </div>
  );
}

export default ImageUpload;
