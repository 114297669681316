import React from 'react';
import Bars from './barChart';

function Charts() {
  return (
    <div className='p-4 border rounded-lg border-[#EAECF0] w-[80%] lg:w-[90]'>
      <div>
        <p className='text-xl text-grayText '>Expense Chart</p>
      </div>
      <div></div>
      <div className='flex flex-col items-center'>
        <Bars />
        <p className='text-[#667085] text-medium pt-2'>Month</p>
      </div>
    </div>
  );
}

export default Charts;
