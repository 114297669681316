import React, { useState } from "react";
import * as Yup from "yup";

import "../../styles/auth.css";
import CustomInput from "../utilities/forms/customInput";
import { Formik } from "formik";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import { useAppDispatch } from "../redux/hook/authHook";

import { verifyOrganization } from "../redux/slices/auth/organisationAuthslice";
import { toast } from "react-toastify";
import { OrgVerify } from "../utilities/types/types";

interface OrganizationVerifyProps {
  onLoginSuccess: () => void;
}

const validationSchema = Yup.object().shape({
  organizationKey: Yup.string().required("Organization key is required"),
});

const OrganizationVerifyForm = ({
  onLoginSuccess,
}: OrganizationVerifyProps) => {
  const [loading, setLoading] = useState(false);

  const initialValues: OrgVerify = {
    organizationKey: "",
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const handleSubmit = async (data: OrgVerify) => {
    setLoading(true);

    try {
      onLoginSuccess();
      const res = await dispatch(verifyOrganization(data));
      const statusCode = res?.payload?.statusCode;

      if (statusCode === 200 || statusCode === 204) {
        toast.success("Organization verified successfully", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#00989B", color: "white" },
        });
        navigate("/login");
      } else {
        if (statusCode) {
          switch (statusCode) {
            case 400:
              toast.error(
                "Bad request. Please check your input and try again.",
                {
                  position: "top-center",
                  autoClose: 3000,
                }
              );
              break;
            case 403:
              toast.error("You are not authorized to perform this action.", {
                position: "top-center",
                autoClose: 3000,
              });
              break;
            case 404:
              toast.error(
                "Organization not found. Please verify the details.",
                {
                  position: "top-center",
                  autoClose: 3000,
                }
              );
              break;
            case 500:
              toast.error("Server error. Please try again later.", {
                position: "top-center",
                autoClose: 3000,
              });
              break;
            default:
              toast.error("An unexpected error occurred. Please try again.", {
                position: "top-center",
                autoClose: 3000,
              });
              break;
          }
        } else {
          toast.error("An unexpected error occurred. Please try again.", {
            position: "top-center",
            autoClose: 3000,
          });
        }
      }

      setLoading(false);
    } catch (error: any) {
      setLoading(false);

      if (error.response) {
        const statusCode = error.response.status;

        switch (statusCode) {
          case 400:
            toast.error("Bad request. Please check your input and try again.", {
              position: "top-center",
              autoClose: 3000,
            });
            break;
          case 403:
            toast.error("You are not authorized to perform this action.", {
              position: "top-center",
              autoClose: 3000,
            });
            break;
          case 404:
            toast.error("Organization not found. Please verify the details.", {
              position: "top-center",
              autoClose: 3000,
            });
            break;
          case 500:
            toast.error("Server error. Please try again later.", {
              position: "top-center",
              autoClose: 3000,
            });
            break;
          default:
            toast.error("An unexpected error occurred. Please try again.", {
              position: "top-center",
              autoClose: 3000,
            });
            break;
        }
      } else {
        toast.error("An unexpected error occurred. Please try again.", {
          position: "top-center",
          autoClose: 3000,
        });
      }
    }
  };

  return (
    <div className="py-4 text-sm font-normal bg-white font-switzer">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <div className="flex items-center">
              <div className="pt-[10px] formGroup">
                <CustomInput
                  id="organizationKey"
                  label=" Enter your Organization key to login."
                  type="text"
                  name="organizationKey"
                  error={formik.errors.organizationKey}
                  value={formik.values.organizationKey}
                  onChange={formik.handleChange}
                  wrapperClassName=""
                  className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] bg-transparent font-switzer"
                  placeholder="Organization Key"
                />
              </div>
            </div>

            <div className="pb-10">
              <button
                type="submit"
                className={`font-medium text-white text-base py-2 rounded-lg my-6 ${
                  loading
                    ? "bg-[#9FE6E8] px-48"
                    : !formik.isValid
                    ? "bg-[#9FE6E8] px-[10.5rem]"
                    : "bg-greenDark px-[10.5rem]"
                }`}
              >
                {loading ? (
                  <Bars
                    height={20}
                    width={20}
                    color="#fff"
                    ariaLabel="bars-loading"
                  />
                ) : (
                  "Continue"
                )}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default OrganizationVerifyForm;
