// src/features/onboardingFormSlice.js

import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Auth_API from '../../../api/authService/authservice';

export const createDepartmentandUnits = createAsyncThunk(
  'create/Department',
  async (data: any, thunkAPI) => {
    try {
      const response = await Auth_API.createDept(data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

const createDepartmentSlice = createSlice({
  name: 'createDepartment',
  initialState: {
    formData: {
      department: '',
      unit: [],
    },
    error: null,
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.formData = {
        department: '',
        unit: [],
      };
      state.error = null;
    },
  },
});

export const { setFormData, setErrors, resetForm } =
  createDepartmentSlice.actions;

export default createDepartmentSlice.reducer;
export type RootState = ReturnType<typeof createDepartmentSlice.reducer>;
