import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { status } from "../../../utilities/types/types";
import Paygrade_API from "../../../api/paygradeService";

export const updatePaygradeForm = createAsyncThunk(
  "paygradeForm",
  async (
    { data, paygradeId }: { data: status; paygradeId: string },
    thunkAPI
  ) => {
    try {
      const response = await Paygrade_API.updatePaygrade(data, paygradeId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

const paygradeFormSlice = createSlice({
  name: "paygradeForm",
  initialState: {
    status: "",
    error: null,
  },
  reducers: {
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updatePaygradeForm.fulfilled, (state, action) => {
      // Handle successful message sending
      state.status = action.payload;
      state.error = null;
    });
    // builder.addCase(paygradeForm.rejected, (state, action) => {
    //   // Handle rejection or error in sending message
    //   state.error = action.payload;
    // });
  },
});

export const { setErrors, resetForm } = paygradeFormSlice.actions;

export default paygradeFormSlice.reducer;
export type RootState = ReturnType<typeof paygradeFormSlice.reducer>;
