import { Link } from 'react-router-dom';
import '../../../styles/auth.css';
import AuthLayout from '../authLayout';
import LoginForm from '../login';

function Login() {
  return (
    <>
      {/* <Head>
        <meta name="description" content="" />
        <title>Log In</title>
      </Head> */}
      <AuthLayout>
        <div className='formSection'>
          <div className='pt-40'>
            <h2 className='formHeader'>Log in</h2>
            <h2 className='formHeaderMob'>Manage workforce efficiently</h2>
            <div>
              <p className='formP'>
                Need a QuiickOps account?
                <Link to='/signup' className='pLink'>
                  Create an account
                </Link>
              </p>
            </div>
          </div>

          <div>
            <LoginForm />
          </div>
          <div className='margin'>
            <p className='formPara'>
              Need a QuiickOpps account?
              <Link to='/sigup' className='pLink'>
                Create an account
              </Link>
            </p>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}

export default Login;
