import React, { useCallback, useMemo } from "react";
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from "react-table";
import { FaArrowDown } from "react-icons/fa";
import { ButtonIconLink } from "../../../utilities/button";
import filter from "../../../asset/Filters.svg";
import { EmptyState } from "../../../utilities/components/emptyState";
import Table from "../../../utilities/customTable/table";
import RowRange from "../../../utilities/customTable/rowsCount";
import GlobalFilter from "../../../utilities/customTable/globalFilter";
import Pagination from "../../../utilities/customTable/pagination";
import { useParams } from "react-router-dom";
import { PaygradeTable } from "../../../utilities/types/types";
import { useAppDispatch } from "../../../redux/hook/authHook";
import CustomSwitch from "../../../utilities/switch";
import { toast } from "react-toastify";
import { updatePaygradeForm } from "../../../redux/slices/paygrade/updatePaygrade";

type PaygradeTableProps = {
  paygrade: PaygradeTable[];
  rowCount: number;
};

function Paygrade({ paygrade, rowCount }: PaygradeTableProps) {
  const data = useMemo(() => {
    return paygrade.map((item: PaygradeTable) => ({
      gradeName: item.gradeName,
      annualGross: item.annualGross,
      annualEarning: item.annualEarning,
      annualNet: item.annualNet,
      taxable: item.taxable,
      status: item.status,
      date: item.date,
      _id: item._id,
    }));
  }, [paygrade]);
  const { paygradeId } = useParams();
  const dispatch = useAppDispatch();

  const columns: Column<PaygradeTable>[] = useMemo(
    () => [
      {
        Header: (
          <div className="flex items-center">
            Grade Name
            <FaArrowDown className="ml-1" />
          </div>
        ),
        accessor: (row) => (
          <p className=" text-[#158b8d] font-medium ">{row.gradeName}</p>
        ),
        id: "gradeName",
      },
      {
        Header: "Annual Gross",
        accessor: "annualGross",
      },
      {
        Header: "Annual Earning",
        accessor: "annualEarning",
      },
      {
        Header: "Annual Net",
        accessor: "annualNet",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Taxable",
        accessor: "taxable",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <div>
            <p
              style={{
                backgroundColor: value === "yes" ? "#EFF8FF" : "#FFF6ED",
                borderColor: "transparent",
                width: "80px",
                padding: "0px 0px 0px 8px ",
                borderRadius: "16px",
                color: value === "yes" ? "#175CD3" : "#C4320A",
              }}
            >
              {value.charAt(0).toUpperCase() + value.slice(1)}
            </p>
          </div>
        ),
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ row }) => {
          const isChecked = row.original.status === "true";
          const handleCheckedChange = useCallback(
            async (newValue: boolean) => {
              try {
                dispatch(
                  updatePaygradeForm({
                    paygradeId: row.original._id,
                    data: { status: newValue },
                  })
                );

                toast.success("Updated successfully", {
                  position: "top-center",
                  autoClose: 3000,
                  theme: "colored",
                  style: { backgroundColor: "#00989B", color: "white" },
                });

                // Optimistically update the local state
                row.original.status = newValue.toString();
              } catch (error) {
                console.error("Error updating status:", error);
              }
            },
            [dispatch, row.original]
          );

          return (
            <div>
              <CustomSwitch
                checked={isChecked}
                onCheckedChange={handleCheckedChange}
              />
            </div>
          );
        },
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable<PaygradeTable>(
    {
      data,
      columns,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="mx-auto border-2 border-solid rounded-lg border-[#EAECF0]">
      <div className="">
        <div className="flex justify-start gap-2 px-6 py-5">
          <p className="text-lg text-grayText ">All Paygrades</p>
          <div className="border rounded-2xl bg-[#E0F6F6] text-[#158B8D] px-2 py-[2px]">
            <RowRange
              pageIndex={pageIndex}
              pageSize={pageSize}
              rowCount={rowCount}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center lg:gap-[50%] xl:gap-[50%] md:[25%] my-3 mx-4 ">
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>

      {data.length === 0 ? (
        <EmptyState
          title="No paygrade data found"
          subtitle=""
          message="Kindly “Create paygrade” to populate table data."
          link=""
          actionLabel=""
          icon=""
          status="error"
        />
      ) : (
        <div>
          <Table
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            page={page}
            prepareRow={prepareRow}
            data={data}
          />

          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            gotoPage={gotoPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
          />
        </div>
      )}
    </div>
  );
}

export default Paygrade;
