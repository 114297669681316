// src/features/onboardingFormSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Leave_API from "../../../api/leaveService";

export const createNewLeaveType = createAsyncThunk(
  "create-leave",
  async (data: any, thunkAPI) => {
    try {
      const response = await Leave_API.create(data);

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
const createLeaveTypeSlice = createSlice({
  name: "createLeave",
  initialState: {
    formData: {
      leaveName: "",
      acceptableDuration: "",
      blackoutPeriod: "",
      leavePaid: "",
      blackoutApplicable: "",
      exception: [],
    },
    error: null,
  },
  reducers: {
    setFormData: (state, action) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.formData = {
        leaveName: "",
        acceptableDuration: "",
        blackoutPeriod: "",
        leavePaid: "",
        blackoutApplicable: "",
        exception: [],
      };
      state.error = null;
    },
  },
});

export const { setFormData, setErrors, resetForm } =
  createLeaveTypeSlice.actions;

export default createLeaveTypeSlice.reducer;
export type RootState = ReturnType<typeof createLeaveTypeSlice.reducer>;
