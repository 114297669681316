import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { status } from '../../../utilities/types/types';
import Expense_API from '../../../api/expenseService';

export const updateCategoriesForm = createAsyncThunk(
  'categoriesForm',
  async (
    { data, categoryId }: { data: status; categoryId: string },
    thunkAPI
  ) => {
    try {
      const response = await Expense_API.updateCategoriesStatus(
        data,
        categoryId
      );
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

const CategoriesFormSlice = createSlice({
  name: 'categoriesForm',
  initialState: {
    status: '',
    error: null,
  },
  reducers: {
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateCategoriesForm.fulfilled, (state, action) => {
      // Handle successful message sending
      state.status = action.payload;
      state.error = null;
    });
    // builder.addCase(CategoriesForm.rejected, (state, action) => {
    //   // Handle rejection or error in sending message
    //   state.error = action.payload;
    // });
  },
});

export const { setErrors, resetForm } = CategoriesFormSlice.actions;

export default CategoriesFormSlice.reducer;
export type RootState = ReturnType<typeof CategoriesFormSlice.reducer>;
