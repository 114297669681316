import { Routes, Route, Navigate } from "react-router-dom";
import Login from "./components/auth/auth/logIn";
import SignUp from "./components/auth/auth/signUp";
import "./App.css";
import AccessDenied from "./components/utilities/components/accessDenied";
import { adminRoutes } from "./components/routes/routes";
import ErrorPage from "./components/utilities/components/notFound";
import PrivateRoute from "./components/routes/privateRoute";
import ForgotPasswordPage from "./components/auth/auth/forgotPasswordEmail";
import { ToastContainer } from "react-toastify";
import { useState } from "react";
import OrgLogin from "./components/auth/auth/organisationLogin";

function App() {
  const [isOrgLoginSuccessful, setOrgLoginStatus] = useState(false);

  const handleOrgLoginSuccess = () => {
    setOrgLoginStatus(true);
  };

  return (
    <>
      <Routes>
        {/* Non-authenticated routes */}
        <Route
          path="/org"
          element={<OrgLogin onLoginSuccess={handleOrgLoginSuccess} />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<Navigate to="/org" />} />

        <Route
          path="/"
          element={<Navigate to="/login" />} // Instead of to prop, use Navigate component to handle redirection
        />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/forgotPassword" element={<ForgotPasswordPage />} />

        {/* Authenticated routes */}
        <Route path="/admin" element={<PrivateRoute />}>
          {adminRoutes.map((route) => {
            return <Route path={route.path} element={route.component} />;
          })}
        </Route>

        <Route path="/accessDenied" element={<AccessDenied />} />

        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <ToastContainer hideProgressBar />
    </>
  );
}

export default App;
