import React, { useState } from "react";
import { Formik, Form, FormikHelpers } from "formik";
import { Button } from "../../../utilities/button";
import { PayScheduleValues } from "../../../utilities/types/types";
import { useAppDispatch } from "../../../redux/hook/authHook";
import { payScheduleSettings } from "../../../redux/slices/payroll/paySchedule";
import { toast } from "react-toastify";
import CustomSelect from "../../../utilities/select";
import CustomSwitch from "../../../utilities/switch";

const payFrequencyOptions = [
  { value: "weekly", label: "Weekly" },
  { value: "biweekly", label: "Bi-Weekly" },
  { value: "monthly", label: "Monthly" },
  { value: "quarterly", label: "Quarterly" },
  { value: "annually", label: "Annually" },
];

interface Option {
  value: string;
  label: string;
}

interface PayScheduleProps {
  generalData: PayScheduleValues;
}

const PaySchedule = ({ generalData }: PayScheduleProps) => {
  const [loading, setLoading] = useState(false);
  const [multiSelect, setMultiSelect] = useState<boolean>(false);

  const dispatch = useAppDispatch();

  const initialValues: PayScheduleValues = {
    payFrequency: generalData?.payFrequency || "",
    dayOfTheMonth: generalData?.dayOfTheMonth || [],
    autoPayroll: generalData?.autoPayroll || false,
  };

  const handleFrequencyChange = (selectedOption: Option | null) => {
    if (selectedOption) {
      if (
        ["biweekly", "semimonthly", "quarterly"].includes(selectedOption.value)
      ) {
        setMultiSelect(true);
      } else {
        setMultiSelect(false);
      }
    }
  };

  const getDaysOfWeekOptions = (): Option[] => [
    { value: "sunday", label: "Sunday" },
    { value: "monday", label: "Monday" },
    { value: "tuesday", label: "Tuesday" },
    { value: "wednesday", label: "Wednesday" },
    { value: "thursday", label: "Thursday" },
    { value: "friday", label: "Friday" },
    { value: "saturday", label: "Saturday" },
  ];

  const getMonthsOfYearOptions = (): Option[] => [
    { value: "january", label: "January" },
    { value: "february", label: "February" },
    { value: "march", label: "March" },
    { value: "april", label: "April" },
    { value: "may", label: "May" },
    { value: "june", label: "June" },
    { value: "july", label: "July" },
    { value: "august", label: "August" },
    { value: "september", label: "September" },
    { value: "october", label: "October" },
    { value: "november", label: "November" },
    { value: "december", label: "December" },
  ];

  const getDaysOfMonthOptions = (): Option[] => {
    const daysOfMonth: Option[] = [];
    for (let i = 1; i <= 31; i++) {
      daysOfMonth.push({ value: `${i}`, label: `${i}` });
    }
    return daysOfMonth;
  };

  const handleSubmit = async (
    data: PayScheduleValues,
    actions: FormikHelpers<PayScheduleValues>
  ) => {
    setLoading(true);

    try {
      await dispatch(payScheduleSettings(data));
      setLoading(false);

      toast.success("Updated successfully", {
        position: "top-center",
        autoClose: 3000,
        theme: "colored",
        style: { backgroundColor: "#00989B", color: "white" },
      });
    } catch (error: any) {
      setLoading(false);
      console.error(error);

      if (error?.response?.status === 400) {
        toast.error("Please check your input.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#D92D20", color: "white" },
        });
      } else {
        toast.error("An error occurred. Please try again later.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#D92D20", color: "white" },
        });
      }
    }
  };

  return (
    <div>
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
        {(formik) => (
          <Form>
            <div className="flex flex-col gap-7">
              <CustomSelect
                name="payFrequency"
                onChange={handleFrequencyChange}
                label="Pay Frequency"
                className="w-[550px]"
                options={payFrequencyOptions}
                isMulti={false}
              />
              <div>
                <p className="text-sm font-medium">
                  {formik.values.payFrequency === "monthly" ||
                  formik.values.payFrequency === "biweekly"
                    ? "Day of the Month"
                    : formik.values.payFrequency === "quarterly" ||
                      formik.values.payFrequency === "annually"
                    ? "Month of the Year"
                    : "Day of the Week"}
                </p>
                <p className="text-[#667085]">
                  {formik.values.payFrequency === "monthly" ||
                  formik.values.payFrequency === "biweekly"
                    ? "This is the day of the month that you would like your employees to be paid."
                    : formik.values.payFrequency === "quarterly" ||
                      formik.values.payFrequency === "annually"
                    ? "This is the month of the year that you would like your employees to be paid."
                    : "This is the day of the week that you would like your employees to be paid."}
                </p>

                {formik.values.payFrequency &&
                  formik.values.payFrequency === "weekly" && (
                    <div>
                      <CustomSelect
                        label="day of month"
                        name="dayOfTheMonth"
                        options={getDaysOfWeekOptions()}
                        isMulti={false}
                        className="w-[550px]"
                      />
                    </div>
                  )}

                {formik.values.payFrequency &&
                  formik.values.payFrequency === "biweekly" && (
                    <CustomSelect
                      name="dayOfTheMonth"
                      options={getDaysOfMonthOptions()}
                      isMulti={multiSelect}
                      className="w-[550px]"
                    />
                  )}
                {formik.values.payFrequency &&
                  formik.values.payFrequency === "semimonthly" && (
                    <CustomSelect
                      name="dayOfTheMonth"
                      options={getMonthsOfYearOptions()}
                      isMulti={multiSelect}
                      className="w-[550px]"
                    />
                  )}
                {formik.values.payFrequency &&
                  formik.values.payFrequency === "quarterly" && (
                    <CustomSelect
                      name="dayOfTheMonth"
                      options={getMonthsOfYearOptions()}
                      isMulti={multiSelect}
                      className="w-[550px]"
                    />
                  )}
                {formik.values.payFrequency &&
                  formik.values.payFrequency === "annually" && (
                    <CustomSelect
                      name="dayOfTheMonth"
                      options={getMonthsOfYearOptions()}
                      className="w-[550px]"
                    />
                  )}
                {formik.values.payFrequency &&
                  formik.values.payFrequency === "monthly" && (
                    <div>
                      <CustomSelect
                        name="dayOfTheMonth"
                        options={getDaysOfMonthOptions()}
                        isMulti={false}
                        className="w-[550px]"
                      />
                    </div>
                  )}
              </div>

              {/* <div>
              <p className='text-sm font-medium'>First Pay Date</p>
              <p className='text-[#667085]'>
                The first pay-check they will receive with company name.
              </p>
              <div className=''>
                <CustomDatePicker name='firstPaydate' className='w-[550px]' />
              </div>
            </div> */}

              <div>
                <p className="text-sm font-medium">Auto run payroll</p>
                <p className="pb-4 text-[#667085]">
                  Automatically setup payroll to run without any manual
                  intervention every scheduled payday.
                </p>
                <CustomSwitch
                  checked={formik.values.autoPayroll}
                  onCheckedChange={(value: boolean) =>
                    formik.setFieldValue("autoPayroll", value)
                  }
                />
              </div>

              <div className="flex justify-end py-4">
                <Button
                  type="submit"
                  className="px-4 py-[10px] text-white rounded-lg bg-greenDark"
                  // disabled={loading}
                >
                  {loading ? "Saving..." : "Save Changes"}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default PaySchedule;
