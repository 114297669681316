import React from 'react';
export interface ContentItem {
  heading: string;
  figures: number | undefined;
  className: string;
  id: number;
}

interface TabsProps {
  contents: ContentItem[];
}

function Tabs({ contents }: TabsProps) {
  return (
    <div className='flex flex-col justify-center flex-1 m-3 w-[30%]  '>
      <div className='flex flex-row mb-3 gap-3'>
        <div className='flex flex-col gap-3'>
          {contents.slice(0, 2).map((content) => (
            <div key={content.id} className={content.className}>
              <h3 className='text-base py-6 text-grayText'>
                {content.heading}
              </h3>
              <p className='text-4xl font-medium pb-9'>{content.figures}</p>
            </div>
          ))}
        </div>

        <div className='flex flex-col gap-3'>
          {contents.slice(2, 4).map((content) => (
            <div key={content.id} className={content.className}>
              <h3 className='text-base py-6'>{content.heading}</h3>
              <p className='text-4xl font-medium pb-9'>{content.figures}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default Tabs;
