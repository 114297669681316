import TitleSection from "../../../utilities/headers/titleSection";
import Tabs from "../../../utilities/tabs";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hook/authHook";

import {
  getActiveEmployees,
  setActiveEmployees,
} from "../../../redux/slices/employes/activeEmployees";
import {
  getArchiveEmployees,
  setArchiveEmployees,
} from "../../../redux/slices/employes/archiveEmployees";
import ActiveEmployeesTable from "../employeeTable/activeEmployes";
import ArchivedEmployeesTable from "../employeeTable/archivedEmployeeTable";

function ExitIndex() {
  const [activeTab, setActiveTab] = useState<number>(1);

  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getActiveEmployees()).then((response) => {
      dispatch(setActiveEmployees(response.payload));
    });
    dispatch(getArchiveEmployees()).then((response) => {
      dispatch(setArchiveEmployees(response.payload));
    });
  }, [dispatch]); // Make sure to add `dispatch` as a dependency
  const activeEmployees = useAppSelector(
    (state) => state.active.activeEmployee
  );
  const archiveEmployees = useAppSelector(
    (state) => state.archived.archiveEmployee
  );

  const archivedRowCount = archiveEmployees.length;
  const activeRowCount = activeEmployees.length;

  return (
    <div>
      <TitleSection
        title="Exit Management"
        subtitle=""
        greenButtonText=""
        grayButtonText=""
        greenButtonIcon=""
        grayButtonIcon=""
        greenBtnLink="/"
        grayBtnLink=""
      />
      <Tabs
        tabs={["Active Employee", "Archived Employee"]}
        handleTabClick={handleTabClick}
        activeTab={activeTab}
        activeClassName="border-b"
        inactiveClassName="inactive-tab"
      />
      {/* <Tabs
        tab1='Active Employee'
        tab2='Archive Employee'
        handleTabClick={handleTabClick}
        activeTab={activeTab}
        activeClassName='borer-b'
      /> */}
      <div className="py-8 tab-content">
        {activeTab === 1 && (
          <ActiveEmployeesTable
            employees={activeEmployees}
            rowCount={activeRowCount}
            // id={getActiveEmployeeId}
          />
        )}
        {activeTab === 2 && (
          <ArchivedEmployeesTable
            employees={archiveEmployees}
            rowCount={archivedRowCount}
          />
        )}
      </div>
    </div>
  );
}

export default ExitIndex;
