import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
  ResponsiveContainer,
} from "recharts";
import { useAppDispatch, useAppSelector } from "../../../redux/hook/authHook";
import { useEffect } from "react";
import {
  getAllExpenseCategory,
  setAllExpenseCategory,
} from "../../../redux/slices/expenses/expenseChart";

function Bars() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllExpenseCategory()).then((response) => {
      dispatch(setAllExpenseCategory(response.payload));
    });
  }, [dispatch]);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        width={1000}
        height={300}
        // data={}
        margin={{
          top: 20,
          right: 40,
          left: 40,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" vertical={false} />
        <XAxis dataKey="name" />

        <YAxis type="number" domain={[]}>
          <Label
            value="Currency($)"
            position="insideLeft"
            angle={-90}
            style={{ textAnchor: "middle", paddingTop: "100px" }}
          />
        </YAxis>
        <Tooltip />
        <Legend
          iconType="circle"
          align="right"
          verticalAlign="top"
          payload={[
            {
              value: "",
              type: "circle",
              id: "",
              color: "#15AFB2",
            },
            { value: "", type: "circle", id: "", color: "#7FE4E7" },
          ]}
        />
        <Bar dataKey="" stackId="a" fill="#15AFB2" />
        <Bar dataKey="" stackId="a" fill="#7FE4E7" radius={[8, 8, 0, 0]} />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default Bars;
