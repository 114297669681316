import React, { useState } from "react";
import * as Yup from "yup";
import "../../styles/auth.css";
import CustomInput from "../utilities/forms/customInput";
import { Formik } from "formik";
import { toast } from "react-toastify"; // Import the ToastContainer
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import { Bars } from "react-loader-spinner";
import { login } from "../redux/slices/auth/authSlice";
import { SignInForm } from "../utilities/types/types";
import { useAppDispatch } from "../redux/hook/authHook";

const validationSchema = Yup.object().shape({
  email: Yup.string().required("Email is required").email("Email is invalid"),

  password: Yup.string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(40, "Password must not exceed 40 characters"),
});

const LoginForm: React.FC = () => {
  const [loading, setLoading] = useState(false);

  const initialValues: SignInForm = {
    email: "",
    password: "",
    // remember: false,
  };

  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleSubmit = async (data: SignInForm) => {
    setLoading(true);

    try {
      const res = await dispatch(login(data));
      const userRole = res?.payload?.data?.role;

      if (res?.payload?.statusCode === 404) {
        toast.error("User not found", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#F04438", color: "white" },
        });
      } else if (res?.payload?.statusCode === 401) {
        toast.error(
          "Invalid login credentials. Please check your email and password.",
          {
            position: "top-center",
            autoClose: 3000,
            theme: "colored",
            style: { backgroundColor: "#F04438", color: "white" },
          }
        );
      } else if (res?.payload?.statusCode === 500) {
        toast.error(
          "Invalid login credentials. Please check your email and password.",
          {
            position: "top-center",
            autoClose: 3000,
            theme: "colored",
            style: { backgroundColor: "#F04438", color: "white" },
          }
        );
      } else if (userRole === "superAdmin" || userRole === "admin") {
        // Fixed the condition here
        navigate("/admin/dashboard");
        toast.success("Login successful", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#00989B", color: "white" },
        });
      } else {
        toast.error("An error occurred. Please try again later.", {
          position: "top-center",
          autoClose: 3000,
          theme: "colored",
          style: { backgroundColor: "#D92D20", color: "white" },
        });
      }

      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  return (
    <div className="py-4 text-sm font-normal bg-white font-switzer">
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {(formik) => (
          <form onSubmit={formik.handleSubmit}>
            <div className="formGroup">
              <CustomInput
                id="email"
                label="Email"
                type="text"
                name="email"
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.errors.email}
                wrapperClassName=""
                className="border border-gray-300 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[400px] bg-transparent font-switzer"
                placeholder="Enter Professional Email"
              />
            </div>

            <div className="formGroup">
              <div className="">
                <CustomInput
                  id="password"
                  label="Password"
                  type="password"
                  name="password"
                  value={formik.values.password}
                  onChange={formik.handleChange}
                  error={formik.errors.password}
                  className="border-transparent  font-switzer  rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[368px]"
                  wrapperClassName="inputWrapper"
                  placeholder="Enter Password"
                />
              </div>
            </div>
            <div className="flex justify-between">
              <div className="formGroup">
                {/* <CustomInput
                  id="remember"
                  label="Remember this device for 30days"
                  type="checkbox"
                  name="remember"
                  value={formik.values.remember}
                  onChange={formik.handleChange}
                  error={formik.errors.remember}
                  wrapperClassName=""
                  className=""
                  placeholder=""
                /> */}
              </div>
              <div>
                <Link to="/forgotPassword" className="textColor">
                  Forgot Password?
                </Link>
              </div>
            </div>

            <div className="pb-10">
              <button
                type="submit"
                className={`font-medium text-white text-base py-2 rounded-lg my-6 ${
                  loading
                    ? "bg-[#9FE6E8] px-48"
                    : !formik.isValid
                    ? "bg-[#9FE6E8] px-[10.5rem]"
                    : "bg-greenDark px-[10.5rem]"
                }`}
              >
                {loading ? (
                  <Bars
                    height={20}
                    width={20}
                    color="#fff"
                    ariaLabel="bars-loading"
                  />
                ) : (
                  "LOG IN"
                )}
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default LoginForm;
