import React, { useMemo } from 'react';
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from 'react-table';

import { EmptyState } from '../../../utilities/components/emptyState';
import Table from '../../../utilities/customTable/table';
import { PayrollSummaryData } from '../../../utilities/types/types';

type PayrollSummaryProps = {
  payroll: PayrollSummaryData[];
  rowCount: number;
};

function PayrollSummaryTable({ payroll, rowCount }: PayrollSummaryProps) {
  const data = useMemo(() => {
    return payroll.map((item: PayrollSummaryData) => ({
      taxDescription: item.taxDescription,
      monthly: item.monthly,
      annually: item.annually,
    }));
  }, [payroll]);

  const columns: Column<PayrollSummaryData>[] = useMemo(
    () => [
      {
        Header: 'Tax Description',
        accessor: 'taxDescription',
        Cell: ({ value }) => {
          if (value === 'pensionByEmployee') {
            return <span>Pension By Employee</span>;
          } else if (value === 'pensionByCompany') {
            return <span>Pension By Company</span>;
          } else {
            return <span>{value}</span>;
          }
        },
      },
      {
        Header: 'Monthly',
        accessor: 'monthly',
      },
      {
        Header: 'Annually',
        accessor: 'annually',
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, headerGroups, page, prepareRow } =
    useTable(
      {
        columns,
        data,
        initialState: { pageIndex: 0, pageSize: 10 },
      },
      useFilters,
      useGlobalFilter,
      usePagination,
      useRowSelect
    );

  return (
    <div className='mx-auto border-2 border-solid rounded-lg border-[#EAECF0]'>
      {data.length === 0 ? (
        <EmptyState
          title='No Payroll Summary found'
          subtitle=''
          message='Kindly “Create Expenses” to populate table data.'
          link=''
          actionLabel=''
          icon=''
          status='error'
        />
      ) : (
        <div>
          <Table
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            page={page}
            prepareRow={prepareRow}
            data={page}
          />
        </div>
      )}
    </div>
  );
}

export default PayrollSummaryTable;
