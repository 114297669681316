import React, { useMemo } from "react";
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from "react-table";
import { EmptyState } from "../../../utilities/components/emptyState";
import Table from "../../../utilities/customTable/table";
import RowRange from "../../../utilities/customTable/rowsCount";
import GlobalFilter from "../../../utilities/customTable/globalFilter";
import Pagination from "../../../utilities/customTable/pagination";
import { Leave } from "../../../utilities/types/types";

type LeaveTableProps = {
  leave: Leave[];
  rowCount: number;
};

const predefinedColors = [
  "#3498db",
  "#e74c3c",
  "#2ecc71",
  "#f39c12",
  "#9b59b6",
  "#34495e",
  "#1abc9c",
  "#e67e22",
  "#7f8c8d",
  "#c0392b",
];

function RequestLeave({ leave, rowCount }: LeaveTableProps) {
  const data = useMemo(() => {
    return leave.map((leaveItem, index) => ({
      _id: leaveItem._id,
      employeeName: leaveItem.employeeName,
      leaveType: leaveItem.leaveType,
      startDate: leaveItem.startDate,
      endDate: leaveItem.endDate,
      backStop: leaveItem.backStop,
      notes: leaveItem.notes,
      status: leaveItem.status,
      department: leaveItem.department,
      profileImage: leaveItem.profileImage,
      color: predefinedColors[index % predefinedColors.length],
    }));
  }, [leave]);

  const columns: Column<Leave>[] = useMemo(
    () => [
      {
        Header: "Employee Name",
        accessor: "employeeName",
        Cell: ({ row }: { row: any }) => {
          const fullName = row.original.employeeName;
          const capitalizedFullName = fullName
            .split(" ")
            .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          const initials = `${fullName.charAt(0).toUpperCase()}${fullName
            .charAt(1)
            .toUpperCase()}`;

          return (
            <div className="flex items-center space-r-2">
              {row.original.profileImage ? (
                <img
                  src={row.original.profileImage}
                  alt={capitalizedFullName}
                  className="w-8 h-8 rounded-full"
                />
              ) : (
                <div
                  className={`flex items-center justify-center w-8 h-8 text-white rounded-full flex-2`}
                  style={{ backgroundColor: row.original.color }}
                >
                  {initials}
                </div>
              )}
              <p className="flex-1 p-2 text-left text-[#158b8d]">
                {capitalizedFullName}
              </p>
            </div>
          );
        },
      },
      {
        Header: "Department",
        accessor: "department",
      },
      {
        Header: "Leave Type",
        accessor: "leaveType",
      },
      {
        Header: "Start Date",
        accessor: (row) => new Date(row.startDate).toLocaleDateString(),
      },
      {
        Header: "End Date",
        accessor: (row) => new Date(row.endDate).toLocaleDateString(),
      },
      {
        Header: "BackStop",
        accessor: "backStop",
      },
      {
        Header: "Status",
        accessor: "status",
        Cell: ({ cell: { value } }: { cell: { value: string } }) => (
          <div>
            <p
              style={{
                backgroundColor:
                  value === "review"
                    ? "green"
                    : value === "approved"
                    ? "#ECFDF3"
                    : value === "request"
                    ? "#FFF6ED"
                    : "#EFF8FF",
                borderColor: "transparent",
                textAlign: "center",
                width: "105px",
                padding: "0px 0px 0px 8px",
                borderRadius: "16px",
                color:
                  value === "request"
                    ? "#C4320A"
                    : value === "review"
                    ? "green"
                    : value === "approved"
                    ? "#027A48"
                    : "#175CD3",
              }}
            >
              {value.charAt(0).toUpperCase() + value.slice(1)}
            </p>
          </div>
        ),
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    setGlobalFilter,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable<Leave>(
    {
      data,
      columns,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <div className="mx-auto border-2 border-solid rounded-lg border-[#EAECF0]">
      <div>
        <div className="flex justify-start gap-2 px-6 py-5">
          <p className="text-lg text-grayText">Leave Requests</p>
          <div className="border rounded-2xl bg-[#E0F6F6] text-[#158B8D] px-2 py-[2px]">
            <RowRange
              pageIndex={pageIndex}
              pageSize={pageSize}
              rowCount={rowCount}
            />
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center lg:gap-[50%] xl:gap-[50%] md:[25%] my-3 mx-4">
        <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
      </div>

      {data.length === 0 ? (
        <EmptyState
          title="No leave data found"
          subtitle=""
          message="Kindly “Create Leave Requests” to populate table data."
          link=""
          actionLabel=""
          icon=""
          status="error"
        />
      ) : (
        <div>
          <Table
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            page={page}
            prepareRow={prepareRow}
            data={data}
            viewBasePath="/admin/view-leave"
            tooltip
            tooltipButtons={{
              view: true,
            }}
          />

          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            gotoPage={gotoPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
          />
        </div>
      )}
    </div>
  );
}

export default RequestLeave;
