import React from 'react';
import { EmptyState } from './components/emptyState';

function ComingSoon() {
  return (
    <div>
      <EmptyState title='Coming soon' status={'error'} />
    </div>
  );
}

export default ComingSoon;
