// import { data } from "../utilities/table/data/tableData";
import { useAppDispatch, useAppSelector } from "../../../redux/hook/authHook";
import {
  getAllEmployees,
  setAllEmployees,
} from "../../../redux/slices/employes/employesSlice";
import OnboardEmployeeTable from "../employeeTable/onboardedEmployeesTable";
import TitleSection from "../../../utilities/headers/titleSection";
import add from "../../../asset/add.svg";
import upload from "../../../asset/cloudUpload.svg";
import { useEffect } from "react";

function OnboardingIndex() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    // Dispatch the async thunk to fetch employee data when the component mounts
    dispatch(getAllEmployees()).then((response) => {
      dispatch(setAllEmployees(response.payload));
    });
  }, [dispatch]); // Make sure to add `dispatch` as a dependency
  const employees = useAppSelector((state) => state.employee.employees);

  const rowCount = employees.length;
  return (
    <div>
      <TitleSection
        title="Onboarding"
        subtitle=""
        greenButtonText="Add employee"
        grayButtonText="Bulk upload"
        greenButtonIcon={add}
        grayButtonIcon={upload}
        greenBtnLink="/admin/onboard"
        grayBtnLink="/admin/bulk-upload"
      />
      <OnboardEmployeeTable employees={employees} rowCount={rowCount} />
    </div>
  );
}

export default OnboardingIndex;
