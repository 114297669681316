import { FormValues } from '../admin/payroll/paygrades/newPaygrade';
import { status } from '../utilities/types/types';
import axiosService from './axios/axios';

const API_URL = `${process.env.REACT_APP_API_BASE_URL}admin/paygrade/`;


export default class Paygrade_API {

static async payGrades(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}get/pay-grade`,
    });
  }
  static async create(data: FormValues): Promise<any> {
    return await axiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}create`,
    });
  }
  static async updatePaygrade (data: status,paygradeId:string): Promise<any> {
    return await axiosService({
      method: 'PATCH',
      data: data,
      url: `${API_URL}disable/${paygradeId}`,
    });
  }
 

}