import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Expense_API from '../../../api/expenseService';
import { CategoryFormValue } from '../../../admin/expenseManagement/forms/expenseCategories';

export const categoryForm = createAsyncThunk(
  'categoryForm',
  async (
    data: CategoryFormValue,
    thunkAPI
  ) => {
    try {
      const response = await Expense_API.createCategory( data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

const categorySlice = createSlice({
  name: 'category',
  initialState: {
    categoryMessage: '',
    error: null,
  },
  reducers: {
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(categoryForm.fulfilled, (state, action) => {
      // Handle successful message sending
      state.categoryMessage = action.payload;
      state.error = null;
    });
    // builder.addCase(categoryForm.rejected, (state, action) => {
    //   // Handle rejection or error in sending message
    //   state.error = action.payload;
    // });
  },
});

export const { setErrors, resetForm } = categorySlice.actions;

export default categorySlice.reducer;
export type RootState = ReturnType<typeof categorySlice.reducer>;
