import React from 'react';
import { Link } from 'react-router-dom';
import { ReactSVG } from 'react-svg';
import pic from '../asset/screen.png';
import prev from '../asset/prev.svg';
import '../../styles/auth.css';
type Props = {
  preview?: boolean;
  children: React.ReactNode;
  signUp?: boolean;
};

function AuthLayout({ children, signUp }: Props) {
  return (
    <div className=''>
      <div
        className={`flex font-switzer  justify-center" w-full ${
          signUp ? '' : 'h-screen overflow-y-hidden'
        }`}
      >
        <div className='w-50%'>
          <div className=''>
            <div className='signupLeft'>
              <div className='leftText'>
                <div className='logo'>
                  <Link to='/'>
                    <svg
                      width='151'
                      height='32'
                      viewBox='0 0 151 32'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        fill-rule='evenodd'
                        clip-rule='evenodd'
                        d='M2.26894 24.2144H11.825L23.9725 29.8593L20.6369 23.9689C22.3303 23.0268 23.7526 21.5606 24.6323 19.6692C26.8463 14.904 24.7789 9.23713 20.0138 7.02316C16.2566 5.27837 11.9386 6.19471 9.19312 8.99883H3.02039C2.54754 8.99883 2.16267 9.38008 2.16267 9.85293C2.16267 10.3221 2.54754 10.707 3.02039 10.707H7.04146C7.21007 10.707 7.37136 10.795 7.45933 10.9416C7.55097 11.0882 7.55828 11.2678 7.4813 11.4218L7.47768 11.4254C7.39337 11.594 7.22473 11.7003 7.03779 11.7003C5.98578 11.7003 2.46689 11.7003 0.582806 11.7003C2.46322 4.95576 8.65798 0 16 0C24.8303 0 32 7.16977 32 16C32 24.8303 24.8303 32 16 32C10.1718 32 5.06573 28.877 2.26894 24.2144ZM0.245561 18.8114H6.70059C6.8912 18.8114 7.05978 18.9288 7.12943 19.1047C7.12943 19.1047 7.12943 19.1047 7.12943 19.1084C7.18808 19.2587 7.16607 19.4309 7.07077 19.5592C6.97546 19.6912 6.81784 19.7645 6.65656 19.7535C6.04075 19.7169 5.28199 19.6692 5.28199 19.6692C4.8128 19.6692 4.42794 20.0504 4.42794 20.5233C4.42794 20.9961 4.8128 21.3773 5.28199 21.3773C5.28199 21.3773 7.53629 21.4727 8.2694 21.502C8.40869 21.5093 8.54431 21.5753 8.63228 21.6853C8.63595 21.6889 8.63962 21.6926 8.63962 21.6962C8.76058 21.8465 8.78626 22.0481 8.70562 22.2204C8.62131 22.3963 8.44904 22.5027 8.25844 22.5027H1.37824C0.861402 21.3407 0.47649 20.1017 0.245561 18.8114ZM0.208915 13.4085H6.13608C6.2827 13.4085 6.42201 13.4744 6.51731 13.5844C6.61262 13.698 6.64925 13.8447 6.62725 13.9913C6.58327 14.2295 6.37801 14.4019 6.13608 14.4019C5.37732 14.4019 3.87444 14.4019 3.87444 14.4019C3.40159 14.4019 3.02039 14.7831 3.02039 15.2559C3.02039 15.7251 3.40159 16.11 3.87444 16.11H6.03711C6.28636 16.11 6.49895 16.3006 6.52461 16.5535C6.52461 16.5572 6.52461 16.5608 6.52461 16.5645C6.5356 16.7038 6.49163 16.8394 6.39999 16.942C6.30469 17.0447 6.1764 17.1033 6.03711 17.1033H0.0366463C0.0109876 16.7368 0 16.3702 0 16C0 15.1166 0.0696253 14.2515 0.208915 13.4085Z'
                        fill='#15AFB3'
                      />
                      <path
                        d='M51.848 22.312C53.312 22.312 54.608 22.024 55.688 21.448L58.304 23.632L59.672 21.976L57.488 20.152C58.88 18.688 59.672 16.528 59.672 13.864C59.672 8.63197 56.672 5.36797 51.848 5.36797C46.976 5.36797 44 8.63197 44 13.864C44 19.096 46.976 22.312 51.848 22.312ZM51.848 20.056C48.632 20.056 46.736 17.704 46.736 13.864C46.736 9.99997 48.632 7.62397 51.848 7.62397C55.04 7.62397 56.936 9.99997 56.936 13.864C56.936 15.832 56.432 17.416 55.52 18.496L52.616 16.072L51.248 17.752L53.648 19.768C53.12 19.96 52.52 20.056 51.848 20.056Z'
                        fill='#101828'
                      />
                      <path
                        d='M70.4714 16.696C70.4714 18.472 69.3194 20.176 67.4474 20.176C65.7434 20.176 64.9754 19.072 64.9754 17.224V9.20797H62.4074V17.752C62.4074 20.776 64.0394 22.312 66.6554 22.312C68.2874 22.312 69.6314 21.64 70.4714 20.272V22H73.0154V9.20797H70.4714V16.696Z'
                        fill='#101828'
                      />
                      <path
                        d='M76.1418 7.62397H78.7098V4.71997H76.1418V7.62397ZM76.1418 22H78.7098V9.20797H76.1418V22Z'
                        fill='#101828'
                      />
                      <path
                        d='M81.8137 7.62397H84.3817V4.71997H81.8137V7.62397ZM81.8137 22H84.3817V9.20797H81.8137V22Z'
                        fill='#101828'
                      />
                      <path
                        d='M98.3096 13.672C97.8776 10.48 95.6216 8.87197 92.7656 8.87197C89.1416 8.87197 86.8616 11.272 86.8616 15.616C86.8616 19.768 89.0936 22.312 92.7896 22.312C95.6216 22.312 97.8296 20.896 98.3816 17.704H95.8376C95.4536 19.336 94.3736 20.176 92.8376 20.176C90.6776 20.176 89.4776 18.52 89.4776 15.616C89.4776 12.664 90.6296 11.032 92.7656 11.032C94.2536 11.032 95.4296 11.848 95.7416 13.672H98.3096Z'
                        fill='#101828'
                      />
                      <path
                        d='M100.775 22H103.343V15.616L108.647 22H111.839L105.983 14.896L111.503 9.20797H108.431L103.343 14.632V4.71997H100.775V22Z'
                        fill='#101828'
                      />
                      <path
                        d='M118.033 22.312C121.681 22.312 124.009 19.936 124.009 15.616C124.009 11.44 121.753 8.87197 118.033 8.87197C114.385 8.87197 112.057 11.272 112.057 15.616C112.057 19.792 114.313 22.312 118.033 22.312ZM118.033 20.152C115.873 20.152 114.673 18.52 114.673 15.616C114.673 12.64 115.873 11.032 118.033 11.032C120.193 11.032 121.393 12.688 121.393 15.616C121.393 18.568 120.217 20.152 118.033 20.152Z'
                        fill='#101828'
                      />
                      <path
                        d='M129.077 27.28V20.536C129.893 21.712 131.093 22.312 132.557 22.312C135.893 22.312 137.981 19.912 137.981 15.568C137.981 11.392 135.941 8.87197 132.557 8.87197C131.093 8.87197 129.893 9.54397 129.077 10.744V9.20797H126.509V27.28H129.077ZM132.245 11.032C134.213 11.032 135.365 12.664 135.365 15.568C135.365 18.52 134.261 20.152 132.245 20.152C130.229 20.152 129.077 18.496 129.077 15.568C129.077 12.64 130.181 11.032 132.245 11.032Z'
                        fill='#101828'
                      />
                      <path
                        d='M142.518 12.568C142.518 11.608 143.334 10.912 144.75 10.912C146.382 10.912 147.39 11.584 147.678 13.384H150.246C149.838 10.192 147.726 8.87197 144.87 8.87197C141.63 8.87197 140.07 10.72 140.07 12.712C140.07 17.968 148.134 15.28 148.134 18.472C148.134 19.552 147.222 20.32 145.494 20.32C143.694 20.32 142.494 19.432 142.23 17.824H139.662C140.142 20.872 142.326 22.312 145.422 22.312C148.518 22.312 150.702 20.728 150.702 18.304C150.702 13.096 142.518 15.832 142.518 12.568Z'
                        fill='#101828'
                      />
                    </svg>
                  </Link>
                </div>

                <div className='leftTop'>
                  <div>
                    <a href='www.quiickops.com'>
                      <ReactSVG src={prev} className='svgImg' />
                    </a>
                  </div>
                  <div className=''>
                    <p className='rightTopP'>
                      Back to
                      <a
                        href='https://www.quiickops.com'
                        className='textColor pl-[2px]'
                        target='_blank'
                        rel='noopener noreferrer'
                      >
                        QuiickOps.com
                      </a>
                    </p>
                  </div>
                </div>
              </div>

              <main>{children}</main>
            </div>
          </div>
        </div>
        <div
          className={`bg-[#e0f6f6] pl-48 ${
            signUp ? 'min-h-screen pt-64 pb-96 image' : 'pb-1'
          }`}
          // style={{
          //   backgroundImage: signUp
          //     ? `url('../asset/Linepattern1.svg'), url('../asset/LinePat.svg')`
          //     : `url('../asset/logLine.svg'), url('../asset/logLine.svg')`,
          //   backgroundSize: 'cover',
          //   backgroundPosition: 'center',
          // }}
        >
          <div className='bg-center bg-no-repeat bg-cover w-400 h-400'>
            <div className='pt-10'>
              <img src={pic} alt='img' height={900} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AuthLayout;
