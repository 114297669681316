import React, { useMemo } from "react";
import {
  Column,
  useFilters,
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useTable,
} from "react-table";
import { PayrollData } from "../../../utilities/types/types";
import { EmptyState } from "../../../utilities/components/emptyState";
import Table from "../../../utilities/customTable/table";
import RowRange from "../../../utilities/customTable/rowsCount";
import Pagination from "../../../utilities/customTable/pagination";
import { format } from "date-fns";

type payrollTableProps = {
  payroll: PayrollData[];
  rowCount: number;
};

function RunPayrollTable({ payroll, rowCount }: payrollTableProps) {
  const data = useMemo(() => {
    return payroll.map((item) => ({
      employeeName: item.employeeName,
      grossPay: item.grossPay,
      employmentType: item.employmentType,
      startDate: item.startDate,
      companyBenefits: item.companyBenefits,
      companyTax: item.companyTax,
    }));
  }, [payroll]);

  const columns: Column<PayrollData>[] = useMemo(
    () => [
      {
        Header: "Employee Name",
        accessor: "employeeName",
        Cell: ({ row }: { row: any }) => {
          const fullName = `${row.original.employeeName} `;
          const capitalizedFullName = fullName
            .split(" ")
            .map((word: string) => word.charAt(0).toUpperCase() + word.slice(1))
            .join(" ");
          return (
            <div className="flex items-center space-r-2 ">
              <p className="flex-1 p-2 text-left">{capitalizedFullName}</p>
            </div>
          );
        },
      },
      {
        Header: "Gross Pay",
        accessor: "grossPay",
      },
      {
        Header: "Contract Type",
        accessor: "employmentType",
      },
      {
        Header: "Start Date",
        accessor: "startDate",
        Cell: ({ value }: { value: string }) => {
          const dateObject = new Date(value);

          // Check if the parsed dateObject is a valid Date
          const formattedDate =
            dateObject instanceof Date && !isNaN(dateObject.getTime())
              ? format(dateObject, "MM/dd/yyyy")
              : "Invalid Date";

          return <span>{formattedDate}</span>;
        },
      },
      {
        Header: "Company Benefit",
        accessor: "companyBenefits",
      },
      {
        Header: "Company Taxes",
        accessor: "companyTax",
      },
    ],
    []
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
  } = useTable<PayrollData>(
    {
      data,
      columns,
      initialState: { pageIndex: 0, pageSize: 10 },
    },
    useFilters,
    useGlobalFilter,
    usePagination,
    useRowSelect
  );

  const { pageIndex, pageSize } = state;

  return (
    <div className="mx-auto border-2 border-solid rounded-lg border-[#EAECF0]">
      <div className="">
        <div className="flex justify-start gap-2 px-6 py-5">
          <p className="text-lg text-[#101828] font-medium">All Payroll</p>
          <div className="border rounded-2xl bg-[#E0F6F6] text-[#158B8D] px-2 py-[2px]">
            <RowRange
              pageIndex={pageIndex}
              pageSize={pageSize}
              rowCount={rowCount}
            />
          </div>
        </div>
      </div>

      {data.length === 0 ? (
        <EmptyState
          title="No payroll data found"
          subtitle=""
          message="Kindly “Create payroll ” to populate table data."
          link=""
          actionLabel=""
          icon=""
          status="error"
        />
      ) : (
        <div>
          <Table
            getTableProps={getTableProps}
            getTableBodyProps={getTableBodyProps}
            headerGroups={headerGroups}
            page={page}
            prepareRow={prepareRow}
            data={data}
          />

          <Pagination
            pageIndex={pageIndex}
            pageSize={pageSize}
            previousPage={previousPage}
            nextPage={nextPage}
            canPreviousPage={canPreviousPage}
            canNextPage={canNextPage}
            pageOptions={pageOptions}
            gotoPage={gotoPage}
            pageCount={pageCount}
            setPageSize={setPageSize}
          />
        </div>
      )}
    </div>
  );
}

export default RunPayrollTable;
