import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Leave_API from '../../../api/leaveService';
import {  Leave } from '../../../utilities/types/types';

interface AllLeaveState {
  allLeave:Leave[];
}

export const getAllLeave = createAsyncThunk('all-leave', async () => {
  try {
    const response = await Leave_API.getAllLeave();
    return response.data;
  } catch (error) {
    throw error;
  }
});

// Create a slice
const allLeaveSlice = createSlice({
  name: 'all-Leave',
  initialState: {
    allLeave: [],
  } as AllLeaveState,
  reducers: {
    setAllLeave: (state, { payload }) => {
      state.allLeave = payload;
    },
  },
 
});
export const { setAllLeave } = allLeaveSlice.actions;

export default allLeaveSlice.reducer;
