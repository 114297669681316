import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Employee_API from '../../../api/employeeService';

interface ArchiveEmployee {
  firstName: string;
  lastName: string;
  middleName: string;
  staffId: number;
  email: string;
  phone: string;
  dOB: string;
  gender: string;
  contractType: string;
  department: string;
  jobTitle: string;
  unit: string;
  employmentType: string;
  employeeSupervisor: string;
  dateEmployed: string;
  hoursPerWeekMin: number;
  hoursPerWeekMax: number;
  payGroup: string;
  payGrade: string;
  adminRights: string[];
  groupRights: string[];
  message: string;
  details: boolean;
}

// Define the type for your slice state
interface ArchiveEmployeeState {
  archiveEmployee: ArchiveEmployee[];
}

// Create an async thunk to fetch employee data
export const getArchiveEmployees = createAsyncThunk('archive', async () => {
  try {
    const response = await Employee_API.archive();
    return response.data;
  } catch (error) {
    throw error;
  }
});

// Create a slice
const archiveEmployeeSlice = createSlice({
  name: 'archive',
  initialState: {
    archiveEmployee: [],
  } as ArchiveEmployeeState,
  reducers: {
    setArchiveEmployees: (state, { payload }) => {
      state.archiveEmployee = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(archiveEmployee.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setArchiveEmployees } = archiveEmployeeSlice.actions;

export default archiveEmployeeSlice.reducer;

// Selectors
// export const selectEmployees = (state:EmployeeState) => state.employees.employees;
// export const selectEmployeesStatus = (state:EmployeeState) =>
//   state.employees.status;
// export const selectEmployeesError = (state:EmployeeState) => state.employees.error;
