import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

export interface DataPoint {
  month: string;
  archivedEmployees: number;
  activeEmployees: number;
}

interface ChartProps {
  data: DataPoint[];
}

interface CustomPayload {
  name: string;
  color: string;
  value: number;
}

const CustomTooltip: React.FC<{
  label?: string;
  payload?: CustomPayload[];
}> = ({ label, payload }) => {
  return (
    <div className="p-2 bg-white">
      <p>{label}</p>
      {payload &&
        payload.map((entry, index) => (
          <p key={index} style={{ color: entry.color }}>
            {entry.name === "activeEmployees" ? "Active" : "Archived"}:{" "}
            {entry.value}
          </p>
        ))}
    </div>
  );
};

function Chart({ data }: ChartProps) {
  return (
    <ResponsiveContainer width="100%" height={300}>
      <BarChart
        height={300}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 20,
          bottom: 5,
        }}
      >
        <CartesianGrid stroke="#F2F4F7" strokeWidth={1} vertical={false} />
        <XAxis dataKey="month" />
        <YAxis hide />
        <Tooltip content={<CustomTooltip />} />
        <Legend
          iconType="circle"
          align="right"
          verticalAlign="top"
          payload={[
            {
              value: "Archived",
              type: "circle",
              id: "archived",
              color: "#15AFB2",
            },
            {
              value: "Active",
              type: "circle",
              id: "active",
              color: "#7FE4E7",
            },
          ]}
        />
        <Bar dataKey="archivedEmployees" stackId="a" fill="#15AFB2" />
        <Bar
          dataKey="activeEmployees"
          stackId="a"
          fill="#7FE4E7"
          radius={[8, 8, 0, 0]}
        />
      </BarChart>
    </ResponsiveContainer>
  );
}

export default Chart;
