import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CustomEarning } from '../../../utilities/types/types';
import Payroll_API from '../../../api/payroll';

interface CustomEarningsState {
  customEarning: CustomEarning[];
}

export const CustomEarnings = createAsyncThunk('earnings', async () => {
  try {
    const response = await Payroll_API.getCustomEarning();
    return response.data;
  } catch (error) {
    throw error;
  }
});

// Create a slice
const customEarningSlice = createSlice({
  name: 'earnings',
  initialState: {
    customEarning: [],
  } as CustomEarningsState,
  reducers: {
    setCustomEarnings: (state, { payload }) => {
      state.customEarning = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(customEarning.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setCustomEarnings } = customEarningSlice.actions;

export default customEarningSlice.reducer;
