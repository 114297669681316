import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Leave_API from '../../../api/leaveService';

// Thunk for deleting a leave category
export const leaveTypeDelete = createAsyncThunk(
  'leaveType/deleteLeaveType',
  async (categoryId: string, { rejectWithValue }) => {
    try {
      const response = await Leave_API.deleteLeaveType(categoryId);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error.response?.data || 'An error occurred');
    }
  }
);

// Create a slice
const leaveTypeSlice = createSlice({
  name: 'leaveType',
  initialState: {
    status: 'idle',
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(leaveTypeDelete.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(leaveTypeDelete.fulfilled, (state) => {
        state.status = 'succeeded';
        state.error = null;
      })
    //   .addCase(deleteLeaveType.rejected, (state, action) => {
    //     state.status = 'failed';
    //     state.error = action.payload;
    //   });
  },
});

export const {} = leaveTypeSlice.actions;

export default leaveTypeSlice.reducer;
