import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Auth_API from "../../../api/authService/authservice";
import { Passwords } from "../../../utilities/types/types";

export const passwordUpdate = createAsyncThunk("passwordUpdate", async (data: Passwords, thunkAPI) => {
  try {
    const response = await Auth_API.updatePassword(data);
    return response.data;
  } catch (error: any) {
    return thunkAPI.rejectWithValue(error.response?.data || error.message);
  }
});

const passwordSlice = createSlice({
  name: "password",
  initialState: {
    companyName: "",
    quickoppsLink: "",
    fullName: "",
    email: "",
    phone: "",
    password: "",
    newPassword: "",
    confirmPassword: "",
    details: false,
    error: null,
  },
  reducers: {
    setFormData: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetForm: state => {
      return {
        ...state,
        companyName: "",
        quickoppsLink: "",
        fullName: "",
        email: "",
        phone: "",
        password: "",
        newPassword: "",
        confirmPassword: "",
        details: false,
        error: null,
      };
    },
  },
});
// Export actions
export const { setFormData, resetForm } = passwordSlice.actions;

export default passwordSlice.reducer;
export type RootState = ReturnType<typeof passwordSlice.reducer>;
