import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { ExpenseId, ExpenseValues } from '../../../utilities/types/types';
import Expense_API from '../../../api/expenseService';

interface OneExpenseState {
  oneExpense: ExpenseValues;
}

export const getOne = createAsyncThunk(
  'oneExpense',
  async (expenseId: ExpenseId) => {
    try {
      const response = await Expense_API.getOneExpense(expenseId);
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Create a slice
const oneExpenseSlice = createSlice({
  name: 'oneExpense',
  initialState: {
    oneExpense: {} as ExpenseValues,
  } as OneExpenseState,
  reducers: {
    setOneExpense: (state, { payload }) => {
      state.oneExpense = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOne.pending, (state) => {
        // Handle pending state if needed
      })
      .addCase(getOne.fulfilled, (state, action) => {
        state.oneExpense = action.payload;
      })
      .addCase(getOne.rejected, (state, action) => {
        // Handle rejected state if needed
      });
  },
});

export const { setOneExpense } = oneExpenseSlice.actions;

export default oneExpenseSlice.reducer;
