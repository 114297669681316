import React from 'react';
import { Link } from 'react-router-dom';

import '../../../styles/signup.css';
import SignUpForm from '../authform';
import AuthLayout from '../authLayout';

function SignUp() {
  return (
    <>
      {/* <Head>
        <meta name="description" content="" />
        <title>Sign Up </title>
      </Head> */}
      <AuthLayout signUp>
        <div className='px-28 xl:px-[190px]'
          // className='formSection'
        >
          <div className='formText'>
            <h2 className='text-3xl font-medium pt-6 block lg:text-31'>Create an account</h2>
            <h2 className='formHeaderMob'>Manage workforce efficiently</h2>
            <p className='text-sm font-light block lg:text-xsm'>
              Already have an account?
              <Link to='/logIn' className='pLink'>
                Click to Sign In
              </Link>
            </p>
          </div>
          <div className=''>
            <SignUpForm />
          </div>
          <div>
            <p className='formPara'>
              Already have an account?
              <Link to='/logIn' className='pLink'>
                Click to Sign In
              </Link>
            </p>
          </div>
        </div>
      </AuthLayout>
    </>
  );
}

export default SignUp;
