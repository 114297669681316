import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Leave_API from "../../../api/leaveService";

export const updateLeaveType = createAsyncThunk(
  "LeaveType",
  async ({ data, categoryId }: { data: any; categoryId: string }, thunkAPI) => {
    try {
      const response = await Leave_API.updateLeaveType(data, categoryId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

const LeaveTypeSlice = createSlice({
  name: "LeaveType",
  initialState: {
    status: "",
    error: null,
  },
  reducers: {
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateLeaveType.fulfilled, (state, action) => {
      // Handle successful message sending
      state.status = action.payload;
      state.error = null;
    });
    // builder.addCase(LeaveType.rejected, (state, action) => {
    //   // Handle rejection or error in sending message
    //   state.error = action.payload;
    // });
  },
});

export const { setErrors, resetForm } = LeaveTypeSlice.actions;

export default LeaveTypeSlice.reducer;
export type RootState = ReturnType<typeof LeaveTypeSlice.reducer>;
