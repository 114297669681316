import React, { useEffect, useState } from "react";
import AllExpense from "./tables/allExpense";
import Index from "./overview";
import Tabs from "../../utilities/tabs";
import ReviewExpense from "./tables/reviewExpense";
import ReimburseExpenses from "./tables/reimbursedExpense";
import { useAppDispatch, useAppSelector } from "../../redux/hook/authHook";
import {
  getAllExpenses,
  setAllExpenses,
} from "../../redux/slices/expenses/allExpenses";
import {
  getApprovedExpenses,
  setApprovedExpenses,
} from "../../redux/slices/expenses/approveExpense";
import {
  getCompanyExpense,
  setCompanyExpenses,
} from "../../redux/slices/expenses/companyExpense";
import {
  getRequestedExpenses,
  setRequestedExpenses,
} from "../../redux/slices/expenses/requestExpense";
import RequestExpense from "./tables/requestExpenseTable";
import {
  getAllExpenseCategory,
  setAllExpenseCategory,
} from "../../redux/slices/expenses/expenseChart";
import {
  getEmployeesPaidExpense,
  setEmployeesPaidExpense,
} from "../../redux/slices/expenses/employeeExpenses";

function ExpenseIndex() {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAllExpenses()).then((response) => {
      dispatch(setAllExpenses(response.payload));
    });
    dispatch(getAllExpenseCategory()).then((response) => {
      dispatch(setAllExpenseCategory(response.payload));
    });
    dispatch(getApprovedExpenses()).then((response) => {
      dispatch(setApprovedExpenses(response.payload));
    });
    dispatch(getCompanyExpense()).then((response) => {
      dispatch(setCompanyExpenses(response.payload));
    });
    dispatch(getEmployeesPaidExpense()).then((response) => {
      dispatch(setEmployeesPaidExpense(response.payload));
    });
    dispatch(getRequestedExpenses()).then((response) => {
      dispatch(setRequestedExpenses(response.payload));
    });
  }, [dispatch]);

  const allExpenses = useAppSelector((state) => state.allExpenses.allExpense);
  const approvedExpenses = useAppSelector(
    (state) => state.approvedExpenses.approvedExpense
  );
  const requestedExpenses = useAppSelector(
    (state) => state.requestedExpenses.requestedExpense
  );
  const employeeExpenses = useAppSelector(
    (state) => state.employeeExpense.EmployeeExpense
  );
  const companyExpenses = useAppSelector(
    (state) => state.companyExpense.companyExpense
  );
  const allRowCount = allExpenses.length;
  const approvedRowCount = approvedExpenses.length;
  const requestedRowCount = approvedExpenses.length;
  const companyRowCount = companyExpenses.length;
  const employeeRowCount = employeeExpenses.length;
  const [activeTab, setActiveTab] = useState<number>(1);

  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };
  return (
    <div>
      <div>
        <Index />
      </div>
      <div className="">
        <div className="py-8">
          <Tabs
            tabs={[
              "All Expenses",
              "Request",
              "Approved",
              "Company",
              "Employee",
            ]}
            handleTabClick={handleTabClick}
            activeTab={activeTab}
            activeClassName="bg-[#E0F6F6] border-b text-center px-4 py-2"
            inactiveClassName="px-4 py-2"
          />
        </div>
        <div className="tab-content">
          {activeTab === 1 && (
            <AllExpense expense={allExpenses} rowCount={allRowCount} />
          )}
          {activeTab === 2 && (
            <RequestExpense
              expense={requestedExpenses}
              rowCount={requestedRowCount}
            />
          )}
          {activeTab === 3 && (
            <ReviewExpense
              expense={approvedExpenses}
              rowCount={approvedRowCount}
            />
          )}
          {activeTab === 4 && (
            <ReimburseExpenses
              expense={companyExpenses}
              rowCount={companyRowCount}
            />
          )}
          {activeTab === 5 && (
            <ReimburseExpenses
              expense={employeeExpenses}
              rowCount={employeeRowCount}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default ExpenseIndex;
