import React from "react";
import { Link } from "react-router-dom";
import { Button } from "../../../utilities/button";

interface StepFourProps {
  responseData: any;
}

const StepFour: React.FC<StepFourProps> = ({ responseData }) => {
  return (
    <div className="px-3">
      <div className="flex gap-[15%] py-7">
        <div className="pb-6 ">
          <p className=" text-greyDark">Net Pay</p>
          <p className="text-2xl font-medium">
            {responseData?.netPay.currencyType}
            {responseData?.netMonthly}
          </p>
        </div>
        <div className="pb-6">
          <p className=" text-greyDark">Total Benefit</p>
          <p className="text-2xl font-medium">
            {responseData?.netPay.currencyType}
            {responseData?.totalBenefit}
          </p>
        </div>
        <div className="pb-6">
          <p className=" text-greyDark">Total Statutory Contribution</p>
          <p className="text-2xl font-medium">
            {responseData?.netPay.currencyType}
            {responseData?.totalStatutoryContribution}
          </p>
        </div>
        <div className="pb-6">
          <p className=" text-greyDark">Total Earnings</p>
          <p className="text-2xl font-medium">
            {}
            {}
          </p>
        </div>
      </div>

      <div className=""></div>
      <div className="">
        <div className="flex justify-between pl-6 pr-[15%] py-8 border rounded-t-lg border-greyWhite  text-greyFaded font-medium">
          <p className="">Description</p>
          <p className=""> Setup</p>
          <p className="">Amount</p>
        </div>
        <div className="flex justify-between pl-6 pr-[15%] py-8 border-b border-x border-greyWhite ">
          <p className="">Basic</p>
          <p className=""> {/* {responseData?.basicAmount.currencyType} */}</p>
          <p className="">
            {responseData?.netPay.currencyType}
            {responseData?.basicAmount}
          </p>
        </div>
        <div className="flex justify-between pl-6 pr-[15%] py-8 border-b border-x border-greyWhite ">
          <p className="">Housing</p>{" "}
          <p className=""> {/* {responseData?.basicAmount.currencyType} */}</p>
          <p className="">
            {responseData?.netPay.currencyType}

            {responseData?.housingAmount}
          </p>
        </div>
        <div className="flex justify-between pl-6 pr-[15%] py-8 border-b border-x border-greyWhite ">
          <p className="">Transport</p>
          <p className=""> {/* {responseData?.basicAmount.currencyType} */}</p>

          <p className="">
            {responseData?.netPay.currencyType}
            {responseData?.transportAmount}
          </p>
        </div>
        <div className="flex justify-between pl-6 pr-[15%] py-8 border-b border-x border-greyWhite ">
          <p className="">Pension (Company Contribution)</p>
          <p className=""> {/* {responseData?.basicAmount.currencyType} */}</p>

          <p className="">
            {responseData?.netPay.currencyType}
            {responseData?.totalCompanyContribution}
          </p>
        </div>
        <div className="flex justify-between pl-6 pr-[15%] py-8 border-b border-x border-greyWhite ">
          <p className="">Pension (Employee Contribution)</p>
          <p className=""> </p>

          <p className="">
            {responseData?.netPay.currencyType}
            {responseData?.totalEmployeeDeduction}{" "}
          </p>
        </div>
        <div className="flex justify-between pl-6 pr-[15%] py-8 border-b border-x border-greyWhite ">
          <p className="">Tax (Annual)</p>
          <p className=""> {/* {responseData?.basicAmount.currencyType} */}</p>

          <p className="">
            {responseData?.netPay.currencyType}
            {responseData?.taxAnually}
          </p>
        </div>
        <div className="flex justify-between pl-6 pr-[15%] py-8 border-b border-x border-greyWhite ">
          <p className="">Tax (Monthly)</p>
          <p className=""> {/* {responseData?.basicAmount.currencyType} */}</p>

          <p className="">
            {responseData?.netPay.currencyType}
            {responseData?.taxMonthly}
          </p>
        </div>
        <div className="flex justify-between pl-6 pr-[15%] py-8 border-b border-x border-greyWhite ">
          <p className="">Net Pay (Annual)</p>
          <p className=""> {/* {responseData?.basicAmount.currencyType} */}</p>

          <p className="">
            {responseData?.netPay.currencyType}
            {responseData?.netAnually}
          </p>
        </div>
        <div className="flex justify-between pl-6 pr-[15%] py-8 border-b border-x border-greyWhite ">
          <p className="">Net Pay (Monthly)</p>
          <p className=""> {/* {responseData?.basicAmount.currencyType} */}</p>

          <p className="">
            {responseData?.netPay.currencyType}
            {responseData?.netMonthly}
          </p>
        </div>
      </div>
      <div className="flex justify-end py-8">
        <Link to={"/admin/paygrades"}>
          <Button className="border border-transparent bg-greenDark w-[179px] text-white mr-4 rounded-lg px-[16px] py-[10px]">
            Back to Paygrade
          </Button>
        </Link>
      </div>
    </div>
  );
};

export default StepFour;
