import uploadAxiosService from './axios/uploadAxios';

const API_URL = `${process.env.REACT_APP_API_BASE_URL}admin/`;

export default class UPLOAD_API {
  static async uploadImage(data: any): Promise<any> {
    return await uploadAxiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}upload`,
    });
  }
  static async uploadLogo(data: any): Promise<any> {
    return await uploadAxiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}upload/logo`,
    });
  }
  static async uploadExpense(data: any): Promise<any> {
    return await uploadAxiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}expense/upload`,
    });
  }

    static async uploadColor(data: any): Promise<any> {
    return await uploadAxiosService({
      method: 'PATCH',
      data: data,
      url: `${API_URL}update/colour`,
    });
  }
}
