import React, { useState, ReactElement } from "react";
import { useAppDispatch } from "../../redux/hook/authHook";
import { ReactSVG } from "react-svg";
import upload from "../../asset/cloudUpload.svg";
import { uploadLogo } from "../../redux/slices/auth/logoUpload";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";

interface LogoUploadProps {
  square?: ReactElement;
  circle?: ReactElement;
  userName?: string | null;
  image?: string | null;
}

function LogoUpload({ square, circle, userName, image }: LogoUploadProps) {
  const [selectedImage, setSelectedImage] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);

  const dispatch = useAppDispatch();
  const getCircleValue = () => {
    const initials = userName
      ? userName
          .split(" ")
          .map((word) => word.charAt(0))
          .join("")
      : "";

    return <p>{initials}</p>;
  };
  const handleImageChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setSelectedImage(file || null);
  };
  const navigate = useNavigate();

  const handleUpload = async () => {
    if (selectedImage) {
      const formData = new FormData();
      formData.append("file", selectedImage);

      try {
        setLoading(true);
        const response = await dispatch(uploadLogo(formData));
        if (response?.payload?.error) {
          toast.error("Try again", {
            position: "top-center",
            autoClose: 3000,
            theme: "colored",
            style: { backgroundColor: "#F04438", color: "white" },
          });
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (response?.payload?.statusCode === 200 || 201 || 204) {
          toast.success("Logo uploaded successfully", {
            position: "top-center",
            autoClose: 3000,
            theme: "colored",
            style: { backgroundColor: "#00989B", color: "white" },
          });
          navigate("/admin/dashboard");
          setLoading(false);
        }
      } catch (error) {
        // Handle error
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div className="">
      <div className="flex flex-row-reverse items-center justify-end gap-7">
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          className="hidden"
          id="imageInput"
        />
        <label
          htmlFor="imageInput"
          className={`px-4 py-2 gap-2 border border-[#D0D5DD] rounded-lg cursor-pointer ${
            selectedImage || image ? "bg-greenDark text-white" : ""
          }`}
        >
          {selectedImage || image ? (
            "Replace image"
          ) : (
            <div className="flex gap-2 ">
              <ReactSVG src={upload} />
              Upload Image
            </div>
          )}
        </label>
        {selectedImage || (image && square) ? (
          <div className="relative mt-4 aspect-w-1 aspect-h-1">
            <div className="p-4 mb-5 overflow-hidden border border-gray-500 aspect-content">
              <img
                src={
                  selectedImage
                    ? URL.createObjectURL(selectedImage)
                    : image || undefined
                }
                alt="Logo"
                className="object-cover w-64 h-full"
              />
            </div>
            <button
              className="px-4 py-2 gap-2 border border-[#D0D5DD] rounded-lg cursor-pointer flex"
              onClick={handleUpload}
            >
              {loading ? (
                <div className="flex items-center justify-center">
                  <ThreeDots
                    height="20"
                    width="80"
                    color="#15AFB2"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    radius="9"
                  />
                </div>
              ) : (
                "Upload Image"
              )}
            </button>
          </div>
        ) : selectedImage && circle ? (
          <div className="relative mt-4 aspect-w-1 aspect-h-1">
            <div className="flex items-center gap-3">
              <div className="p-4 mb-5 overflow-hidden border border-gray-500 rounded-full aspect-w-1 aspect-h-1">
                <img
                  src={
                    selectedImage
                      ? URL.createObjectURL(selectedImage)
                      : image || undefined
                  }
                  alt="Logo"
                  className="object-cover h-full rounded-full w-52"
                />
              </div>
              <div>
                <p>{userName}</p>
                <p>Account Owner</p>
              </div>
            </div>
            <button
              className="px-4 py-2 gap-2 border border-[#D0D5DD] rounded-lg cursor-pointer flex"
              onClick={handleUpload}
            >
              {loading ? (
                <div className="flex items-center justify-center">
                  <ThreeDots
                    height="20"
                    width="80"
                    color="#15AFB2"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    radius="9"
                  />
                </div>
              ) : (
                "Upload Image"
              )}
            </button>
          </div>
        ) : (
          <div>
            {square && (
              <div className="relative p-4 mt-4 text-lg font-semibold border border-gray-500 aspect-w-1 aspect-h-1 aspect-content">
                {square}
              </div>
            )}
            {circle && (
              <div className="flex items-center gap-2">
                <div className="relative p-4 mt-4 overflow-hidden text-lg font-medium border border-gray-500 rounded-full bg-greenLight text-greenDark aspect-w-1 aspect-h-1 aspect-content">
                  {getCircleValue()}
                </div>
                <div>
                  <p>{userName}</p>
                  <p>Account Owner</p>
                </div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default LogoUpload;
