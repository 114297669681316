import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Expense } from '../../../utilities/types/types';
import Expense_API from '../../../api/expenseService';

// Define the type for your slice state
interface ApprovedExpenseState {
  approvedExpense: Expense[];
}

// Create an async thunk to fetch employee data
export const getApprovedExpenses = createAsyncThunk(
  'approved-expense',
  async () => {
    try {
      const response = await Expense_API.getApprovedExpense();
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Create a slice
const approvedExpenseSlice = createSlice({
  name: 'approved-expense',
  initialState: {
    approvedExpense: [],
  } as ApprovedExpenseState,
  reducers: {
    setApprovedExpenses: (state, { payload }) => {
      state.approvedExpense = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(approvedExpense.pending, (state) => {
  //       state.status = "loading";
  //       state.error = nupproved;
  //     })
  //     .addCase(approvedEmployees.fulfipproveded,
  //     .addCase(approvedEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setApprovedExpenses } = approvedExpenseSlice.actions;

export default approvedExpenseSlice.reducer;
