import { CategoryFormValue } from '../admin/expenseManagement/forms/expenseCategories';
import { status, Expense, ExpenseId, ExpenseValues, SendMessageValues } from '../utilities/types/types';
import axiosService from './axios/axios';
import uploadAxiosService from './axios/uploadAxios';

export const API_URL = `${process.env.REACT_APP_API_BASE_URL}admin/expense/`;

export default class Expense_API {
  static async create(data: any): Promise<any> {
    return await uploadAxiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}create`,
    });
  }
  static async getAllExpense(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}`,
    });
  }
  static async getOneExpense(expenseId: ExpenseId): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}${expenseId}`,
    });
  }
  static async getApprovedExpense(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}`,
      params: {
        status: 'approved',
      },
    });
  }
  static async getPaidByCompany(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}get/reimbursed`,
      params: {
        paidBy: 'company',
      },
    });
  }
  static async getPaidByEmployee(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}get/reimbursed`,
      params: {
        paidBy: 'employee',
      },
    });
  }
  static async getRequestExpense(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}`,
      params: {
        status: 'request',
      },
    });
  }
  static async getRejectedExpense(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}`,
      params: {
        status: 'rejected',
      },
     
    });
  }
  static async expenseAnalytics(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}get/analytics`,
     
    });
  }
  static async createExpense(): Promise<any> {
    return await axiosService({
      method: 'POST',
      url: `${API_URL}create`,
    });
  }
  static async sendMessageExpense(expenseId: ExpenseId, data:SendMessageValues): Promise<any> {
    return await axiosService({
      method: 'PATCH',
      url: `${API_URL}message/${expenseId}`,
      data: data,
    });
  }

  // static async editExpense(): Promise<any> {
  //   return await axiosService({
  //     method: 'PATCH',
  //     url: `${API_URL}update/${ExpenseId}`,
  //   });
  // }
    static async customCategoryType(): Promise<any> {
      return await axiosService({
        method: 'GET',
        url: `${API_URL}category/custom-type`,
      });
    }
    static async defaultCategoryType(): Promise<any> {
      return await axiosService({
        method: 'GET',
        url: `${API_URL}category/default-type`,
      });
    }
    static async allCategories(): Promise<any> {
      return await axiosService({
        method: 'GET',
        url: `${API_URL}get/category`,
      });
    }
  static async updateExpense(
    data: ExpenseValues,
    expenseId: ExpenseId
  ): Promise<any> {
    return await axiosService({
      method: 'PATCH',
      data: data,
      url: `${API_URL}update/${expenseId}`,
    });
  }
  static async approveExpense(
    expenseId: ExpenseId
  ): Promise<any> {
    return await axiosService({
      method: 'PATCH',
      url: `${API_URL}approve/${expenseId}`,
    });
  }
  static async reimburseExpense(
   
    expenseId: ExpenseId
  ): Promise<any> {
    return await axiosService({
      method: 'PATCH',
      url: `${API_URL}reimburse/${expenseId}`,
    });
  }
  static async rejectExpense(
    data: Expense,
    expenseId: ExpenseId
  ): Promise<any> {
    return await axiosService({
      method: 'PATCH',
      data: data,
      url: `${API_URL}update/${expenseId}`,
    });
  }
  
  static async createCategory(data: CategoryFormValue): Promise<any> {
    return await axiosService({
      method: 'POST',
      data: data,
           url: `${API_URL}create/category`,
    });
  }

  static async getActivity(expenseId: ExpenseId): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}get/activity/${expenseId}`,
    
    });
  }
  static async getExpenseChart(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}get/count/category`,
    
    });
  }
  static async updateCategoriesStatus(data: status,categoryId: string,): Promise<any> {
    return await axiosService({
      method: 'PATCH',
      data: data,
      url: `${API_URL}category/disable/${categoryId}`,
    });
  }
  static async receiptUpload( formData: any, expenseId:string): Promise<any> {
  return await axiosService({
    method: 'PATCH', // Use POST method to send files
    data: formData,
    url: `${API_URL}upload/${expenseId}`, 
    // 6585fb1f9dfd5bfc38689500
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}


}
