import React from "react";

type RowRangeProps = {
  pageIndex: number;
  pageSize: number;
  rowCount: number;
};

const RowRange: React.FC<RowRangeProps> = ({
  pageIndex,
  pageSize,
  rowCount,
}) => {
  const firstRow = pageIndex * pageSize + 1;
  const lastRow = Math.min((pageIndex + 1) * pageSize, rowCount);

  return (
    <div className="text-sm text-gray-700">
      Showing {firstRow}-{lastRow} of {rowCount}
    </div>
  );
};

export default RowRange;
