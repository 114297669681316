import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Payroll_API from '../../../api/payroll';
import { GeneralValues } from '../../../utilities/types/types';

interface GeneralState {
  general: GeneralValues;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string | null;
}

export const getGeneralSettings = createAsyncThunk(
  'GeneralSettings',
  async () => {
    try {
      const response = await Payroll_API.getGeneral();
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const generalSlice = createSlice({
  name: 'GeneralSettings',
  initialState: {
    general: {},
    loading: 'idle',
    error: null,
  } as GeneralState,
  reducers: {
    setGeneral: (state, action) => {
      state.general = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getGeneralSettings.pending, (state) => {
        state.loading = 'pending';
        state.error = null;
      })
      .addCase(getGeneralSettings.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.general = action.payload;
      })
      .addCase(getGeneralSettings.rejected, (state, action) => {
        state.loading = 'failed';
        state.error =
          action.error.message ||
          'An error occurred while fetching user information.';
      });
  },
});

export default generalSlice.reducer;

export const { setGeneral } = generalSlice.actions;
