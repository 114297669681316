import React, { ChangeEvent } from 'react';

import { Button } from '../../../utilities/button';

import CustomInput from '../../../utilities/forms/customInput';
import { ExitManagement } from '../../../utilities/types/types';
import { FormikErrors } from 'formik';

interface SystemMessageProps {
  onNextStep: (values: ExitManagement) => void;
  values: ExitManagement;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
  errors?: FormikErrors<ExitManagement>;
}

const SystemMessage = ({
  onNextStep,
  values,
  setFieldValue,
  errors,
}: SystemMessageProps) => {
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };
  const handleSubmit = () => {
    onNextStep(values);
  };

  return (
    <div>
      <div>
        <h3 className='text-lg  text-greyDark pb-7'>
          Custom Message/System Message
        </h3>
      </div>
      <div>
        <form>
          <CustomInput
            id='exitMessageTitle'
            label='Exit Message Title'
            type='text'
            name='exitMessageTitle'
            value={values.exitMessageTitle}
            onChange={handleChange}
            error={errors?.exitMessageTitle}
            className='w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500'
            wrapperClassName=''
            placeholder='Enter message title'
          />
          <div className='py-5'>
            <CustomInput
              id='exitMessageBody'
              label='Exit Message Body'
              type='textarea'
              name='exitMessageBody'
              value={values.exitMessageBody}
              onChange={handleChange}
              error={errors?.exitMessageBody}
              className='w-full p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500'
              wrapperClassName=''
              placeholder='Enter Exit Message Body'
            />
          </div>

          <div className='flex justify-end'>
            <Button
              type='button'
              onClick={handleSubmit}
              className='border text-white bg-greenDark mr-4 rounded-lg px-[18px] py-[10px] text-left'
            >
              Next
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SystemMessage;
