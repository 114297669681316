import parse from "html-react-parser";
import { activityData } from "../../../utilities/types/types";
import { ReactSVG } from "react-svg";
import approve from "../../../asset/arrow-narrow-right.svg";
import message from "../../../asset/mail-03.svg";
import reimburse from "../../../asset/reverse-left.svg";
import { EmptyState } from "../../../utilities/components/emptyState";

interface ActivityProps {
  activity: activityData[];
}

const ActivityPage: React.FC<ActivityProps> = ({ activity }) => {
  const groupNotificationsByDate = () => {
    const groupedNotifications: { [date: string]: activityData[] } = {};

    activity.forEach((notification) => {
      let date: Date | string = notification.createdAt;

      if (typeof date === "string") {
        date = new Date(date);
      }

      // Convert to string with desired format
      date = date.toLocaleDateString("en-GB", {
        year: "numeric",
        month: "long",
        day: "numeric",
      });

      if (!groupedNotifications[date]) {
        groupedNotifications[date] = [];
      }
      groupedNotifications[date].push(notification);
    });

    return groupedNotifications;
  };

  const groupedNotifications = groupNotificationsByDate();
  const getInitials = (name: string): string => {
    const initials = name
      .split(" ")
      .map((part) => part.charAt(0))
      .join("")
      .toUpperCase();

    return initials;
  };

  const getIcon = (notificationType: string): React.ReactNode => {
    switch (notificationType) {
      case "Create a Message to":
        return <ReactSVG src={message} />;
      case "Approved":
        return <ReactSVG src={approve} />;
      case "Reimbursed":
        return <ReactSVG src={reimburse} />;
      case "Rejected":
        return <ReactSVG src="" />;
      default:
        return null;
    }
  };

  const calculateDaysAgo = (notificationDate: Date): number => {
    const currentDate = new Date();
    const timeDifference = currentDate.getTime() - notificationDate.getTime();
    const daysAgo = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    return daysAgo;
  };

  return (
    <div className="py-6">
      {activity.length === 0 ? (
        <EmptyState title="No Activity yet" message="" status="error" />
      ) : (
        Object.entries(groupedNotifications).map(([date, notifications]) => (
          <div key={date} className="py-6 border-b border-greyFaded ">
            <h2 className="pb-[6px] font-medium text-greyFaded">{date}</h2>
            <ul>
              {notifications.map((notification) => (
                <li
                  key={notification._id}
                  className="flex justify-between p-4 border border-greyWhite"
                >
                  <div className="flex gap-4">
                    <div className="relative">
                      <div className="flex items-center justify-center w-12 h-12 rounded-full bg-greenWhite text-greenDark">
                        {getInitials(notification.sender)}
                      </div>
                      <div className="absolute bottom-[5px] right-0">
                        {getIcon(notification.notification)}
                      </div>
                    </div>
                    <div>
                      <p>
                        <span className="pr-1 text-sm font-medium">
                          {notification.sender}
                        </span>
                        <span
                          className={
                            notification.notification === "Create a Message to"
                              ? "text-[#7A5AF8]"
                              : notification.notification === "Approved"
                              ? "text-[#12B76A]"
                              : notification.notification === "Reimbursed"
                              ? "text-[#0BA5EC]"
                              : notification.notification === "Rejected"
                              ? "text-red-500"
                              : ""
                          }
                        >
                          {notification.notification}
                        </span>
                        <span className="pl-1 text-sm font-medium">
                          {notification.receiver}
                        </span>
                      </p>
                      {notification.message && (
                        <div className="text-xs text-greyTint">
                          {parse(notification.message)}
                        </div>
                      )}
                    </div>
                  </div>
                  <p>
                    {calculateDaysAgo(new Date(notification.createdAt))} days
                    ago
                  </p>
                </li>
              ))}
            </ul>
          </div>
        ))
      )}
    </div>
  );
};

export default ActivityPage;
