import { ReactElement, useRef, useEffect, useState } from 'react';
import { ReactSVG } from 'react-svg';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../redux/hook/authHook';
import { getAdminInfo, setAdmin } from '../../redux/slices/auth/adminDetails';
import { logout } from '../../redux/slices/auth/authSlice';
import { toast } from 'react-toastify';
import profile from '../../asset/profile.svg';
import accordion from '../../asset/accordion.svg';

function Profile(): ReactElement {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getAdminInfo()).then((response) => {
      dispatch(setAdmin(response.payload));
    });
  }, [dispatch]);

  const handleLogout = () => {
    dispatch(logout())
      .then(() => {
        navigate('/org');
        toast.success('Logout successful', {
          position: 'top-center',
          autoClose: 3000,
          theme: 'colored',
          style: { backgroundColor: '#00989B', color: 'white' },
        });
      })
      .catch((error) => {
        console.error('Logout failed:', error);
        toast.error('An error occurred. Please try again later.', {
          position: 'top-center',
          autoClose: 3000,
          theme: 'colored',
          style: { backgroundColor: ' #D92D20', color: 'white' },
        });
      });
  };

  const admin = useAppSelector((state) => state.admin.admin);

  return (
    <div className='relative inline-block text-left' ref={dropdownRef}>
      <div
        className='flex items-center justify-center'
        onClick={toggleDropdown}
      >
        <div className='px-3'>
          {admin?.profileImage ? (
            <img
              src={admin?.profileImage}
              alt='Selected'
              className='object-cover w-10 h-10 rounded-full'
            />
          ) : (
            <ReactSVG src={profile} />
          )}
        </div>
        <div>
          <h3 className='truncate'>
            {admin?.firstName} {admin?.lastName}
          </h3>
          <p>
            {admin?.role &&
              admin.role
                .split(' ')
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(' ')}
          </p>
        </div>
        <div className='p-4'>
          <ReactSVG src={accordion} />
        </div>
      </div>
      {isOpen && (
        <div
          className='absolute right-0 w-48 mt-2 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none'
          style={{ zIndex: 999 }}
        >
          <div className='py-1'>
            {/* <Link
              to='#'
              className='block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100'
            >
              Profile
            </Link> */}
            <Link
              to='/admin/settings'
              className='block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100'
            >
              Settings
            </Link>
            <p
              onClick={handleLogout}
              className='block px-4 py-1 text-sm text-gray-700 hover:bg-gray-100'
            >
              Logout
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

export default Profile;
