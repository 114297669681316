import React, { useEffect, useState } from "react";
import CustomInput from "../../../../../utilities/forms/customInput";
import {
  DepartmentValues,
  FormStepValues,
  Paygrade,
} from "../../../../../utilities/types/types";
import { Button } from "../../../../../utilities/button";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/hook/authHook";
import {
  getAllDepartments,
  setAllDepartments,
} from "../../../../../redux/slices/auth/getAllDept";
import { fetchPaygrades } from "../../../../../redux/slices/paygrade/getPaygrade";
import {
  getAllEmployees,
  setAllEmployees,
} from "../../../../../redux/slices/employes/employesSlice";

interface OnboardProps {
  formData: FormStepValues;
  setFormData: React.Dispatch<React.SetStateAction<FormStepValues>>;
  onNextStep: () => void;
  errors: any;
}
const OnboardNew = ({
  formData,
  setFormData,
  onNextStep,
  errors,
}: OnboardProps) => {
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleNextStep = () => {
    onNextStep();
  };
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        dispatch(getAllEmployees()).then((response) => {
          dispatch(setAllEmployees(response.payload));
        });
        // await dispatch(getAllEmployees());
        const response = await dispatch(getAllDepartments());

        dispatch(setAllDepartments(response.payload));
        dispatch(fetchPaygrades());
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [dispatch]);
  const activeEmployees = useAppSelector((state) => state.employee.employees);

  const departments = useAppSelector((state) => {
    return state.department.allDepartment;
  });
  const paygrades = useAppSelector((state) => state.paygrades.data);

  const employeeOptions = activeEmployees?.map((activeEmployee) => ({
    value: activeEmployee._id,
    label: `${activeEmployee.firstName} ${activeEmployee.lastName}`,
  }));

  const extractPaygradeInfo = (paygrades: Paygrade[]) => {
    return paygrades?.map((paygrade) => ({
      value: paygrade._id,
      label: paygrade.gradeName,
    }));
  };
  const paygradeOptions = extractPaygradeInfo(paygrades);

  const departmentOptions = departments?.map((department) => ({
    value: department.department._id,
    label: department.department.departmentName,
  }));
  const [selectedDepartment, setSelectedDepartment] =
    useState<DepartmentValues | null>(null);

  const handleDepartmentChange = (
    selectedDepartmentValue: DepartmentValues | null
  ) => {
    setSelectedDepartment(selectedDepartmentValue);
  };

  return (
    <div>
      <h3 className="py-8 text-lg ">Basic Information</h3>
      <form>
        <div className="flex gap-6">
          <CustomInput
            id="firstName"
            label="First Name"
            type="text"
            name="firstName"
            value={formData.firstName}
            onChange={handleChange}
            error={errors.firstName}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[550px]"
            wrapperClassName=""
            placeholder="Enter First Name"
          />
          <CustomInput
            id="middleName"
            label="Middle Name"
            type="text"
            name="middleName"
            value={formData.middleName}
            onChange={handleChange}
            error={errors.middleName}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[550px] "
            wrapperClassName=""
            placeholder="Enter Middle Name"
          />
        </div>
        <div className="flex gap-6">
          <CustomInput
            id="lastName"
            label="Last Name"
            type="text"
            name="lastName"
            value={formData.lastName}
            onChange={handleChange}
            error={errors.lastName}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[550px]"
            wrapperClassName=""
            placeholder="Enter Last Name"
          />
          <CustomInput
            id="staffId"
            label="Staff Id"
            type="text"
            name="staffId"
            value={formData.staffId}
            onChange={handleChange}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[550px]"
            wrapperClassName=""
            placeholder="Enter Staff Id"
            error={errors.staffId}
          />
        </div>
        <div className="flex gap-6">
          <CustomInput
            id="email"
            label="Email Address"
            type="text"
            name="email"
            value={formData.email}
            onChange={handleChange}
            error={errors.email}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[550px]"
            wrapperClassName=""
            placeholder="Enter your Email Address"
          />
          <CustomInput
            id="phone"
            label="Phone Number"
            type="tel"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            error={errors.phone}
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500  w-[550px]"
            wrapperClassName=""
            placeholder="Enter Phone Number"
          />
        </div>
        <div className="flex gap-6">
          <CustomInput
            id="dOB"
            label="Date of Birth"
            type="date"
            name="dOB"
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500  w-[550px]"
            wrapperClassName=""
            placeholder="Select Date of birth"
            value={formData.dOB}
            onChange={handleChange}
            error={errors.dOB}
          />
          <CustomInput
            id="gender"
            label="Gender"
            type="select"
            name="gender"
            className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[550px]"
            wrapperClassName=""
            placeholder="Select gender"
            value={formData.gender}
            onChange={handleChange}
            error={errors.gender}
            selectOptions={[
              { value: "Male", label: "Male" },
              { value: "Female", label: "Female" },
            ]}
          />
        </div>

        <div className="py-5">
          <hr />
        </div>
        <div className="">
          <p className="text-lg pb-7">Job Information</p>
          <div className="flex gap-10">
            <CustomInput
              id="department"
              label="Department"
              type="select"
              className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[550px]"
              wrapperClassName=""
              placeholder="Select Department"
              name="department"
              value={formData.department}
              onChange={(e) => {
                handleChange(e);
                const selectedDepartmentValue =
                  departments.find(
                    (department) => department.department._id === e.target.value
                  ) || null;
                handleDepartmentChange(selectedDepartmentValue);
              }}
              error={errors.department}
              selectOptions={departmentOptions}
            />
            <CustomInput
              id="jobTitle"
              label="Job Title"
              type="text"
              name="jobTitle"
              className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[550px]"
              wrapperClassName=""
              placeholder="Enter Job Title"
              value={formData.jobTitle}
              onChange={handleChange}
              error={errors.jobTitle}
            />
          </div>
          <div className="flex gap-10">
            <CustomInput
              id="unit"
              label="Unit"
              type="select"
              name="unit"
              value={formData.unit}
              onChange={handleChange}
              error={errors.unit}
              selectOptions={(selectedDepartment?.department.units || []).map(
                (unit) => ({
                  value: unit,
                  label: unit,
                })
              )}
              className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[550px]"
              wrapperClassName=""
              placeholder="Select Job Unit "
            />
            <CustomInput
              id="employmentType"
              label="Employment Type"
              type="select"
              name="employmentType"
              value={formData.employmentType}
              onChange={handleChange}
              error={errors.employmentType}
              className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[550px]"
              wrapperClassName=""
              placeholder="Select Employment Type"
              selectOptions={[
                { value: "Fulltime", label: "Full-time" },
                { value: "Part-time", label: "Part-time" },
              ]}
            />
          </div>
          <div className="flex gap-10">
            <CustomInput
              id="employeeSupervisor"
              label="Employee Supervisor"
              type="select"
              name="employeeSupervisor"
              value={formData.employeeSupervisor}
              onChange={handleChange}
              error={errors.employeeSupervisor}
              selectOptions={employeeOptions} // Pass the list of options
              className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[550px]"
              wrapperClassName=""
              placeholder="Select Employee Supervisor "
            />
            <CustomInput
              id="dateEmployed"
              label="Date of Employment"
              type="date"
              name="dateEmployed"
              value={formData.dateEmployed}
              onChange={handleChange}
              error={errors.dateEmployed}
              className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500   w-[550px]"
              wrapperClassName=""
              placeholder="Enter Date of Employment"
            />
          </div>
          <div className="flex gap-10">
            <div className="">
              <p className="text-sm ">Hours per week</p>
              <div className="flex gap-6">
                <div className="flex gap-1">
                  <CustomInput
                    id="hoursPerWeekMin"
                    label=""
                    type="number"
                    name="hoursPerWeekMin"
                    value={formData.hoursPerWeekMin}
                    onChange={handleChange}
                    error={errors.hoursPerWeekMin}
                    className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500   w-[200px]"
                    wrapperClassName=""
                    placeholder="Min"
                  />
                  <p className="pt-5">min</p>
                </div>
                <p className="pt-5">to</p>

                <div className="flex gap-1">
                  <CustomInput
                    id="hoursPerWeekMax"
                    label=""
                    type="number"
                    name="hoursPerWeekMax"
                    value={formData.hoursPerWeekMax}
                    onChange={handleChange}
                    error={errors.hoursPerWeekMax}
                    className=" border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500  w-[200px]"
                    wrapperClassName=""
                    placeholder="Max"
                  />
                  <p className="pt-5">max</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>
          <p className="text-lg py-7">Pay Information</p>
          <div className="flex gap-10">
            <CustomInput
              id="payGrade"
              label="Pay Grade"
              type="select"
              name="payGrade"
              value={formData.payGrade}
              onChange={handleChange}
              error={errors.payGrade}
              className="border border-gray-400 rounded-md p-2 focus:outline-none focus:ring-2 focus:ring-gray-500 w-[550px]"
              wrapperClassName=""
              placeholder="Select Pay Grade"
              selectOptions={paygradeOptions}
            />
          </div>
        </div>
        <div className="py-5">
          <hr />
        </div>
        <div className="flex justify-end py-8">
          <Button
            type="button"
            onClick={handleNextStep}
            className="border text-white bg-greenDark mr-4 rounded-lg px-[18px] py-[10px] text-left"
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  );
};

export default OnboardNew;
