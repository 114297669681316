import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import TitleSection from '../../../utilities/headers/titleSection';
import SystemMessage from './systemMessage';
import ExitForm from './exitEmployeeForm';
import { EmployeeId, ExitManagement } from '../../../utilities/types/types';
import { Form, Formik } from 'formik';
import { useAppDispatch } from '../../../redux/hook/authHook';
import { archivedEmployeExit } from '../../../redux/slices/auth/archivedEmployeeForm';
import { toast } from 'react-toastify';
import loader from '../../../asset/images/loader 2.gif';

const initialValues: ExitManagement = {
  exitMessageBody: '',
  exitMessageTitle: '',
  exitRemark: '',
  lastDay: new Date(),
  selectExitReasons: '',
};
const ExitFormIndex = () => {
  const { employeeId } = useParams();
  const validEmployeeId = employeeId || 'defaultEmployeeId';
  const [step, setStep] = useState<number>(1);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePreviousStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const handleSubmit = async (
    values: ExitManagement,
    validEmployeeId: EmployeeId
  ) => {
    try {
      setIsLoading(true);
      await dispatch(
        archivedEmployeExit({
          data: values,
          employeeId: validEmployeeId,
        })
      );

      setIsLoading(false);
      navigate('/admin/exit');
      toast.success('Employee exited successfully', {
        position: 'top-center',
        autoClose: 3000,
        theme: 'colored',
        style: { backgroundColor: '#00989B', color: 'white' },
      });
    } catch (error: any) {
      setIsLoading(false);

      if (error?.response?.status === 400) {
        toast.error('Bad request. Please check your input.', {
          position: 'top-center',
          autoClose: 3000,
          theme: 'colored',
          style: { backgroundColor: '#F04438', color: 'white' },
        });
      } else {
        toast.error('An error occurred. Please try again later.', {
          position: 'top-center',
          autoClose: 3000,
          theme: 'colored',
          style: { backgroundColor: ' #D92D20', color: 'white' },
        });
      }
    }
  };

  const onCancelButtonClick = () => {
    navigate(-1);
  };

  return (
    <>
      <div className='px-8'>
        {isLoading && (
          <div className='fixed inset-0 z-50 flex items-center justify-center bg-black/40'>
            <img src={loader} alt='' width={800} height={800} />
          </div>
        )}

        <TitleSection
          title='Exit Employee '
          subtitle='Fill out required information and save.'
          grayButtonText='Cancel'
          onCancelButtonClick={onCancelButtonClick}
          greenButtonIcon=''
          grayButtonIcon=''
          greenBtnLink=''
          grayBtnLink=''
        />
        <ul className='flex space-x-4 '>
          <li
            className={
              step === 1
                ? 'text-greenDark border-b border-greenDark pb-4'
                : 'text-gray-600 pb-4'
            }
          >
            Custom Message/System Message
          </li>
          <li
            className={
              step === 2
                ? 'text-greenDark border-b border-greenDark pb-4'
                : 'text-gray-600 pb-4'
            }
          >
            Exit Employee
          </li>
        </ul>
        <div className='h-[1px] bg-gray-300 '></div>
        <div className='py-5 '>
          <Formik
            initialValues={initialValues}
            onSubmit={(values) => handleSubmit(values, validEmployeeId)}
          >
            {({ values, setFieldValue }) => (
              <Form>
                {step === 1 && (
                  <SystemMessage
                    values={values}
                    setFieldValue={setFieldValue}
                    onNextStep={handleNextStep}
                  />
                )}
                {step === 2 && (
                  <ExitForm
                    values={values}
                    onPrevStep={handlePreviousStep}
                    onSubmit={() => handleSubmit(values, validEmployeeId)}
                    setFieldValue={setFieldValue}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ExitFormIndex;
