import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Payroll_API from '../../../api/payroll';
import { payschedulDataValues } from '../../../utilities/types/types';

interface PayScheduleState {
  paySchedule: payschedulDataValues;
  loading: 'idle' | 'pending' | 'succeeded' | 'failed';
  error: string | null;
}

export const payScheduleData = createAsyncThunk(
  'PayScheduleSettings',
  async () => {
    try {
      const response = await Payroll_API.getPaySchedule();
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

const payScheduleDataSlice = createSlice({
  name: 'PayScheduleSettings',
  initialState: {
    paySchedule: {},
    loading: 'idle',
    error: null,
  } as PayScheduleState,
  reducers: {
    setPaySchedule: (state, action) => {
      state.paySchedule = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(payScheduleData.pending, (state) => {
        state.loading = 'pending';
        state.error = null;
      })
      .addCase(payScheduleData.fulfilled, (state, action) => {
        state.loading = 'succeeded';
        state.paySchedule = action.payload;
      })
      .addCase(payScheduleData.rejected, (state, action) => {
        state.loading = 'failed';
        state.error =
          action.error.message ||
          'An error occurred while fetching user information.';
      });
  },
});

export default payScheduleDataSlice.reducer;

export const { setPaySchedule } = payScheduleDataSlice.actions;
