// src/features/onboardingFormSlice.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import EmployeeAPI from '../../../api/employeeService';
import { EmployeeId, FormStepValues } from '../../../utilities/types/types';

interface OnboardingFormState {
  step: number;
  formData: FormStepValues;
  error: null | any;
}

const initialState: OnboardingFormState = {
  step: 1,
  formData: {
    firstName: '',
    lastName: '',
    middleName: '',
    staffId: 0,
    email: '',
    phone: '',
    dOB: '',
    gender: '',
    department: '',
    jobTitle: '',
    unit: '',
    employmentType: '',
    employeeSupervisor: '',
    dateEmployed: '',
    hoursPerWeekMin: 0,
    hoursPerWeekMax: 0,
    payGrade: '',
    adminRights: [] as never[], // Explicitly declare adminRights as never[]
    groupRights: [],
    message: '',
    details: false,
  },
  error: null,
};

export const editOnboardEmployee = createAsyncThunk(
  "editOnboardingForm/employee",
  async (
    { data, employeeId }: { data: FormStepValues; employeeId: EmployeeId },
    thunkAPI
  ): Promise<any> => {
    try {
      const response = await EmployeeAPI.updateEmployee(employeeId, data);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

const editOnboardingFormSlice = createSlice({
  name: "editOnboardingForm",
  initialState,
  reducers: {
    setStep: (state, action: PayloadAction<number>) => {
      state.step = action.payload;
    },
    setFormData: (state, action: PayloadAction<Partial<FormStepValues>>) => {
      state.formData = { ...state.formData, ...action.payload };
    },
    setErrors: (state, action: PayloadAction<any>) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.step = 1;
      state.formData = initialState.formData;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(editOnboardEmployee.fulfilled, (state, action) => {
      // Handle success, if needed
    });
    builder.addCase(editOnboardEmployee.rejected, (state, action) => {
      // Handle failure, if needed
    });
  },
});

// Export actions and reducer
export const { setStep, setFormData, setErrors, resetForm } =
  editOnboardingFormSlice.actions;

export default editOnboardingFormSlice.reducer;
export type RootState = ReturnType<typeof editOnboardingFormSlice.reducer>;