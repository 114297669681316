import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Payroll_API from '../../../api/payroll';
import { Payroll } from '../../../utilities/types/types';

interface PayrollState {
  payroll: Payroll | null;
}

export const getPayrollData = createAsyncThunk('runPayroll', async () => {
  try {
    const response = await Payroll_API.runPayroll();
    return response;
  } catch (error) {
    throw error;
  }
});

const runPayrollSlice = createSlice({
  name: 'runPayroll',
  initialState: {
    payroll: null,
  } as PayrollState,
  reducers: {
    setPayroll: (state, { payload }) => {
      state.payroll = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPayrollData.pending, (state) => {})
      .addCase(getPayrollData.fulfilled, (state, action) => {
        state.payroll = action.payload;
      })
      .addCase(getPayrollData.rejected, (state, action) => {});
  },
});

export const { setPayroll } = runPayrollSlice.actions;

export default runPayrollSlice.reducer;
