import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { CSVEntry } from '../../../admin/employes/onboarding/bulkEmployees';
import Employee_API from '../../../api/employeeService';

interface UploadCSVState {
  uploadedData: CSVEntry[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: { responseData: any | null; errorMessage: string } | null;
}

const initialState: UploadCSVState = {
  uploadedData: [],
  status: 'idle',
  error: null,
};

export const bulkEmployeesUpload = createAsyncThunk(
  'user/employeeUpload',
  async ({ jsonData }: { jsonData: CSVEntry[] }, thunkAPI) => {
    try {
      const response = await Employee_API.bulkUpload(jsonData);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue({
        responseData: error.response?.data || null,
        errorMessage: error.message,
      });
    }
  }
);

const employeeUploadSlice = createSlice({
  name: 'employeeUpload',
  initialState,
  reducers: {
    setUploadedData: (state, action) => {
      state.uploadedData = [...action.payload];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(bulkEmployeesUpload.pending, (state) => {
        state.status = 'loading';
      })
      .addCase(bulkEmployeesUpload.fulfilled, (state, action) => {
        state.status = 'succeeded';
        state.error = null; // Reset error on success
        // You can update state or handle the API response data here if needed
      })
      .addCase(bulkEmployeesUpload.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as {
          responseData: any | null;
          errorMessage: string;
        };
      });
  },
});

export const { setUploadedData } = employeeUploadSlice.actions;

export default employeeUploadSlice.reducer;
