import React from 'react';
import { PageButton } from '../button';
import prev from '../../asset/prev.svg';
import next from '../../asset/next.svg';
import { ReactSVG } from 'react-svg';

type PaginationProps = {
  pageIndex: number;
  pageSize: number;
  previousPage: () => void;
  nextPage: () => void;
  canPreviousPage: boolean;
  canNextPage: boolean;
  pageOptions: number[];
  gotoPage: (pageIndex: number) => void;
  pageCount: number;
  setPageSize: (pageSize: number) => void;
};

const Pagination: React.FC<PaginationProps> = ({
  pageIndex,
  previousPage,
  nextPage,
  canPreviousPage,
  canNextPage,
  gotoPage,
  pageCount,
}) => {
  const pageNumbers = Array.from(
    { length: pageCount },
    (_, index) => index + 1
  );

  return (
    <div className='flex items-center justify-between py-3 mx-6'>
      <div>
        <nav className='inline-flex -space-x-px rounded-md shadow-sm '>
          <PageButton
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className=''
          >
            <ReactSVG src={prev} />
            <p>Previous</p>
          </PageButton>
        </nav>
      </div>
      <div className=''>
        {pageNumbers.map((pageNumber) => (
          <button
            key={pageNumber}
            onClick={() => gotoPage(pageNumber - 1)}
            className={`${
              pageIndex === pageNumber - 1
                ? 'bg-[#E0F6F6] text-[#00989B] hover:bg-[#E0F6F6] border border-transparent rounded-full  py-2 px-4 '
                : 'bg-white hover:bg-gray-200 text-gray-800  py-2 px-4 border border-transparent rounded-full'
            }`}
          >
            {pageNumber}
          </button>
        ))}
      </div>
      <div className=''>
        <nav className='inline-flex -space-x-px rounded-md shadow-sm '>
          <PageButton
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className=''
          >
            <p>Next</p>
            <ReactSVG src={next} />
          </PageButton>
        </nav>
      </div>
    </div>
  );
};

export default Pagination;
