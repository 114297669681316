import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Form, Formik } from "formik";
import loader from "../../../../../asset/images/loader 2.gif";
import {
  EmployeeId,
  FormStepValues2,
} from "../../../../../utilities/types/types";
import {
  useAppDispatch,
  useAppSelector,
} from "../../../../../redux/hook/authHook";
import {
  getOneEmployee,
  setOneEmployee,
} from "../../../../../redux/slices/employes/getOneEmployee";
import TitleSection from "../../../../../utilities/headers/titleSection";
import Step1B from "./viewbio";
import AccessRightForm from "./viewAccess";
import ViewMessageOnboard from "./viewmessage";

const ViewOnboardingneww: React.FC = () => {
  const { employeeId } = useParams<EmployeeId>();

  const validEmployee = employeeId || "";
  const [loadingData, setLoadingData] = useState(true);
  function isValidDate(dateString: string | undefined | null): boolean {
    if (!dateString) {
      return false;
    }

    const timestamp = Date.parse(dateString);
    return !isNaN(timestamp);
  }
  const dispatch = useAppDispatch();
  useEffect(() => {
    const fetchEmployeeData = async () => {
      try {
        setLoadingData(true);
        const response = await dispatch(getOneEmployee(validEmployee));
        dispatch(setOneEmployee(response.payload));
        setLoadingData(false);
      } catch (error) {
        // Handle errors, e.g., show an error toast
      }
    };

    fetchEmployeeData();
  }, [dispatch, validEmployee]);
  const employee = useAppSelector((state) => state.oneEmployee.oneEmployee);
  const navigate = useNavigate();
  const handleCancelClick = () => {
    navigate(-1);
  };
  const [step, setStep] = useState<number>(1);
  const initialValues: FormStepValues2 = {
    firstName: employee.firstName || "",
    lastName: employee.lastName || "",
    middleName: employee.middleName || "",
    staffId: employee.staffId || 0,
    email: employee.email || "",
    phone: employee.phone || "",
    dOB: isValidDate(employee.dOB)
      ? new Date(Date.parse(employee.dOB))
      : new Date(),
    gender: employee.gender || "",
    department: employee.department || "",
    jobTitle: employee.jobTitle || "",
    unit: employee.unit || "",
    employmentType: employee.employmentType || "",
    employeeSupervisor: employee.employeeSupervisor || "",
    dateEmployed: isValidDate(employee.dateEmployed)
      ? new Date(Date.parse(employee.dateEmployed))
      : null,
    hoursPerWeekMin: employee.hoursPerWeekMin || 0,
    hoursPerWeekMax: employee.hoursPerWeekMax || 0,
    payGrade: employee.payGrade || "",
    adminRights: employee.adminRights || [],
    groupRights: employee.groupRights || [],
    message: employee.message || "",
    details: employee.details || false,
  };
  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };

  const handlePrevStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (values: FormStepValues2) => {};

  // const onSaveButtonClick = () => {
  //   // Save the form data to local storage
  //   localStorage.setItem('formData', JSON.stringify(formData));
  // };
  if (loadingData) {
    return (
      <div className="fixed inset-0 z-50 flex  bg-black/40 items-center justify-center">
        <img src={loader} alt="" width={800} height={800} />
      </div>
    );
  }

  return (
    <>
      <div className="px-8">
        {isLoading && (
          <div className="fixed inset-0 z-50 flex items-center justify-center bg-black/40">
            <img src={loader} alt="" width={800} height={800} />
          </div>
        )}

        <TitleSection
          title="Onboarded Employee "
          grayButtonText="Cancel"
          onCancelButtonClick={handleCancelClick}
          greenButtonIcon=""
          grayButtonIcon=""
          greenBtnLink=""
          grayBtnLink=""
        />
        <ul className="flex space-x-4">
          <li
            className={
              step === 1
                ? "text-greenDark border-b border-greenDark pb-4"
                : "text-gray-600 pb-4"
            }
          >
            Onboarding
          </li>
          <li
            className={
              step === 2
                ? "text-greenDark border-b border-greenDark pb-4"
                : "text-gray-600 pb-4"
            }
          >
            Access
          </li>
          <li
            className={
              step === 3
                ? "text-greenDark border-b border-greenDark pb-4"
                : "text-gray-600 pb-4"
            }
          >
            Message
          </li>
        </ul>
        <div className="py-5 ">
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {({ values, setFieldValue }) => (
              <Form>
                {step === 1 && (
                  <Step1B
                    employeeData={employee}
                    values={values}
                    setFieldValue={setFieldValue}
                    onNextStep={handleNextStep}
                  />
                )}
                {step === 2 && (
                  <AccessRightForm
                    values={values}
                    setFieldValue={setFieldValue}
                    onNextStep={handleNextStep}
                    onPrevStep={handlePrevStep}
                  />
                )}
                {step === 3 && (
                  <ViewMessageOnboard
                    values={values}
                    onPrevStep={handlePrevStep}
                    setFieldValue={setFieldValue}
                  />
                )}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default ViewOnboardingneww;
