/**
 * TIPS
 * Read up some react tips for improving performance
 * https://www.freecodecamp.org/news/how-to-work-with-react-the-right-way-to-avoid-some-common-pitfalls-fc9eb5e34d9e/
 */

import axios from 'axios';
import AuthModule from '../authService/Auth.module';

type Payload = {
  url: string;
  method: string;
  data?: any;
  signal?: any;
  params?: any;
  headers?: any;
};

/**
 * Helper for calling axios services
 */
const axiosService = async ({
  url,
  method,
  data,
  signal,
  params,
  headers,
}: Payload): Promise<any> => {
  try {
    const response = await axios({
      method,
      url,
      params,
      cancelToken: signal,
      data,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        ...(AuthModule.getToken() && {
          Authorization: `Bearer ${AuthModule.getToken()}`,
        }),
        ...(AuthModule.getOrganization() && {
          'x-quiickops-customerId':AuthModule.getOrganization()
        }),
        ...headers,
      },
    });

    return response.data;
  } catch (error) {
    // Handle errors, you may want to log or throw an exception based on your application needs
    console.error('Error in axiosService:', error);
    throw error;
  }
};

export default axiosService;
