import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import AuthModule from '../../../api/authService/Auth.module';
import ORG_API from '../../../api/authService/orgAuthservice';
import { OrgVerify } from '../../../utilities/types/types';

// Async Thunks for authentication
// export const signup = createAsyncThunk("signup", async (data: SignUpTypes, thunkAPI) => {
//   try {
//     const response = await Auth_API.(data);
//     return response.data;
//   } catch (error: any) {
//     return thunkAPI.rejectWithValue(error.response?.data || error.message);
//   }
// });

export const verifyOrganization = createAsyncThunk(
  'verify',
  async (data: OrgVerify, thunkAPI) => {
    try {
      const res = await ORG_API.verify(data);
      AuthModule.authenticateOrg(res);
      thunkAPI.dispatch(
        setOrg({
          error: '',
          organization: '',
        })
      );
      return res;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);
// Slice

const getOrganizationFromLocalStorage = (): string | null => {
  return localStorage.getItem('organization') || null;
};

export interface verifyOrgState {
  error: string;
  loading: boolean;
  organization: string | null;
}

const initialState: verifyOrgState = {
  error: '',
  loading: false,
  organization: getOrganizationFromLocalStorage(),
};

const verifyOrgSlice = createSlice({
  name: 'verifyOrg',
  initialState,
  reducers: {
    setOrg: (state, action: PayloadAction<Partial<verifyOrgState>>) => {
      state.loading = action.payload.loading ?? state.loading;
      state.error = action.payload.error ?? state.error;
      state.organization = action.payload.organization ?? state.organization;
    },
  },
  // extraReducers: (builder) => {
  //   builder;
  //   // ... (unchanged)
  // },
});

export const { setOrg } = verifyOrgSlice.actions;
export default verifyOrgSlice.reducer;

// Correct the RootState type
export type RootState = ReturnType<typeof verifyOrgSlice.reducer>;
