
import { OrgVerify } from '../../utilities/types/types';
import axiosService from '../axios/axios';

const API_URL = `${process.env.REACT_APP_API_BASE_URL}organization/`;

// const register = (data: SignUpTypes) => {
//   return axios.post(API_URL + "signup", data);
// };

export default class ORG_API {
 
  static async verify(data: OrgVerify): Promise<any> {
    return await axiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}verify`,
    });
  }
 
}


