// paygrades.ts

import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import Paygrade_API from '../../../api/paygradeService'
import { Paygrade } from '../../../utilities/types/types'


interface PaygradesState {
  data: Paygrade[];
  loading: boolean;
  error: string | null;
}

const initialState: PaygradesState = {
  data: [],
  loading: false,
  error: "",
};

export const fetchPaygrades = createAsyncThunk('paygrades/fetchPaygrades', async () => {
    try {
    const response = await Paygrade_API.payGrades();
    return response;
  } catch (error: any) {
    return Promise.reject(error.response?.data || error.message); 
  }
});


const paygrades = createSlice({
  name: 'paygrades',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPaygrades.pending, (state) => {
        state.error = null;
      })
      .addCase(fetchPaygrades.fulfilled, (state, action: PayloadAction<any>) => {
        state.loading = false;
        state.error = null;
        state.data = action.payload.data;
      })
      .addCase(fetchPaygrades.rejected, (state, action) => {
        state.loading = false;
        state.data = [];
        state.error = action.payload as string;
      });
  },
});


export const paygradesSelector = (state: { paygrades: PaygradesState }) => state.paygrades;

export default paygrades.reducer;