import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Expense } from '../../../utilities/types/types';
import Expense_API from '../../../api/expenseService';

// Define the type for your slice state
interface EmployeeExpenseState {
  EmployeeExpense: Expense[];
}

// Create an async thunk to fetch employee data
export const getEmployeesPaidExpense = createAsyncThunk(
  'reimbursed-expense',
  async () => {
    try {
      const response = await Expense_API.getPaidByEmployee();
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Create a slice
const EmployeeExpenseSlice = createSlice({
  name: 'reimbursed-expense',
  initialState: {
    EmployeeExpense: [],
  } as EmployeeExpenseState,
  reducers: {
    setEmployeesPaidExpense: (state, { payload }) => {
      state.EmployeeExpense = payload;
    },
  },
});
export const { setEmployeesPaidExpense } = EmployeeExpenseSlice.actions;

export default EmployeeExpenseSlice.reducer;
