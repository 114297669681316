import React from 'react';
import Select from 'react-select';

interface PayrollSelectProps {
  formik: any;
  options: { value: string; label: string }[];
  fieldName: string;
  className: string;
  label: string;
}

const PayrollSelect: React.FC<PayrollSelectProps> = ({
  formik,
  options,
  fieldName,
  className,
  label,
}) => {
  const { values, setFieldValue } = formik;

  const handleSelectChange = (
    selectedOption: { value: string; label: string } | null
  ) => {
    setFieldValue(fieldName, {
      ...values[fieldName],
      currencyType: selectedOption?.value || null,
    });
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const amount = /^\d+$/.test(inputValue) ? parseInt(inputValue, 10) : '';

    setFieldValue(fieldName, {
      amountOfGrossEarnings: amount || null,
      currencyType: values[fieldName]?.currencyType || null,
    });
  };

  return (
    <div>
      <label htmlFor={`${fieldName}_currencyType`}>{label}</label>
      <div className='flex items-center border rounded-l'>
        <p className='pl-3'>{values[fieldName]?.currencyType}</p>
        <input
          type='number'
          id={`${fieldName}_amountOfGrossEarnings`}
          name={`${fieldName}_amountOfGrossEarnings`}
          onChange={handleInputChange}
          value={values[fieldName]?.amountOfGrossEarnings || ''}
          className={className}
          step='0.01'
          min='0.00'
          max='100.00'
        />
        <Select
          id={fieldName}
          name={fieldName}
          value={options.find(
            (option) => option.value === values[fieldName]?.currencyType
          )}
          onChange={handleSelectChange}
          options={options}
        />
      </div>
    </div>
  );
};

export default PayrollSelect;
