import React from 'react';

interface StepProps {
  titles: string[]; // Corrected the prop name to 'titles' instead of 'title'
  activeIndex: number; // Optional, provide active step index from parent
  visitedTabClass?: string; // Optional, class for "visited" state
  inactiveTabClass?: string; // Optional, class for "inactive" state
}

const Step: React.FC<StepProps> = ({
  titles,
  activeIndex,
  visitedTabClass,
  inactiveTabClass,
}) => {
  return (
    <>
      <ul className='flex py-4 space-x-4'>
        {titles.map((title, index) => (
          <li
            key={index}
            className={`text-left font-medium ${
              activeIndex === index ? visitedTabClass : inactiveTabClass
            } `}
          >
            {title}
          </li>
        ))}
      </ul>
    </>
  );
};

export default Step;
