import React from 'react';
import AdminAccess from '../../access/admin/adminAccess';
import { FormStepValues } from '../../../../../utilities/types/types';
import Groupright from '../../access/group/groupAccess';
import { Button } from '../../../../../utilities/button';

interface AccessProps {
  formData: FormStepValues;
  setFormData: React.Dispatch<React.SetStateAction<FormStepValues>>;
  onNextStep: () => void;
  onPreviousStep: () => void;
  errors: any;
}

const AccessFormNew = ({
  formData,
  setFormData,
  onNextStep,
  onPreviousStep,
  errors,
}: AccessProps) => {
  const handleNextStep = () => {
    onNextStep();
  };
  return (
    <div>
      <form>
        <div>
          <AdminAccess
            selectedAdminRights={formData.adminRights}
            setSelectedAdminRights={(rights) =>
              setFormData((prevData) => ({
                ...prevData,
                adminRights: rights,
              }))
            }
          />
          <div className='py-5'>
            <hr />
          </div>
          <Groupright
            selectedGroupRights={formData.groupRights}
            setSelectedGroupRights={(rights) =>
              setFormData((prevData) => ({
                ...prevData,
                groupRights: rights,
              }))
            }
          />
        </div>
        <div className='py-5'>
          <hr />
        </div>
        <div className='flex justify-between pb-8'>
          <Button
            type='button'
            onClick={onPreviousStep}
            className='border text-grayText border-greyFade mr-4 rounded-lg px-[18px] py-[10px] text-left'
          >
            Previous
          </Button>
          <Button
            type='button'
            onClick={handleNextStep}
            className='border text-white bg-greenDark mr-4 rounded-lg px-[18px] py-[10px] text-left'
          >
            Next
          </Button>
        </div>
      </form>
    </div>
  );
};

export default AccessFormNew;
