import React, { useState } from "react";

import * as Yup from "yup";

import { useNavigate } from "react-router-dom";
import { FormStepValues } from "../../../../../utilities/types/types";
import TitleSection from "../../../../../utilities/headers/titleSection";
import OnboardNew from "./onboard";
import AccessFormNew from "./accessOnboard";
import MessageNew from "./messageOnboard";

interface FormErrors {
  [key: string]: string;
}
const OnboardingForm: React.FC = () => {
  const [step, setStep] = useState<number>(1);
  const [formData, setFormData] = useState<FormStepValues>({
    firstName: "",
    lastName: "",
    middleName: "",
    staffId: 0,
    email: "",
    phone: "",
    dOB: "",
    gender: "",
    department: "",
    jobTitle: "",
    unit: "",
    employmentType: "",
    employeeSupervisor: "",
    dateEmployed: "",
    hoursPerWeekMin: 0,
    hoursPerWeekMax: 0,
    payGrade: "",
    adminRights: [],
    groupRights: [],
    message: "",
    details: true,
  });

  const validationSchema = Yup.object({
    firstName: Yup.string()
      .min(3, "First Name must be at least 3 characters")
      .required("First Name is required"),
    lastName: Yup.string()
      .min(3, " Last Name must be at least 3 characters")
      .required(" Last Name is required"),
    staffId: Yup.string()
      .min(3, " Staff Id must be at least 3 characters")
      .required("Staff Id is required"),
    email: Yup.string().required("Email is required").email("Email is invalid"),
    gender: Yup.string().required("Please select an option"),
    hoursPerWeekMin: Yup.number()
      .required("Minimum hours per week is required")
      .positive("Minimum hours per week should be a valid number")
      .typeError("Minimum hours per week should be a valid number")
      .min(1, "Minimum hours should be at least 1"),
    hoursPerWeekMax: Yup.number()
      .required("Maximum hours per week is required")
      .positive("Minimum hours per week should be a valid number")
      .typeError("Maximum hours per week should be a valid number")
      .min(
        Yup.ref("hoursPerWeekMin"),
        "Maximum hours should be greater than or equal to minimum hours"
      ),
    phone: Yup.string()
      .required("Phone number is required")
      .matches(
        /^\+?[0-9]{10,11}$/,
        "Invalid phone number. Please enter a valid number with optional country code"
      ),

    dOB: Yup.date()
      .typeError("Date of Birth should be a valid date format")
      .required("Date of Birth is required"),
    dateEmployed: Yup.date()
      .typeError("Date of Employment should be a valid date format")
      .required("Date of Employment is required"),
    department: Yup.string().required("Please select an option"),
    unit: Yup.string().required("Please select an option"),

    employeeSupervisor: Yup.string().required("Please select an option"),
    jobTitle: Yup.string().required("Job Title is required"),
    employmentType: Yup.string().required("Please select an option"),
    payGrade: Yup.string().required("Please select an option"),
    message: Yup.string()
      .min(10, "Message must be at least 10 characters")
      .required("Message is required"),
    details: Yup.bool().oneOf([true], "Accept Terms is required"),
  });

  const handlePreviousStep = () => {
    setStep((prevStep) => prevStep - 1);
  };

  const [errors, setErrors] = useState<FormErrors>({});
  const getFieldsForCurrentStep = () => {
    switch (step) {
      case 1:
        return [
          "firstName",
          "lastName",
          "unit",
          "staffId",
          "email",
          "phone",
          "dOB",
          "gender",
          "department",
          "jobTitle",
          "unit",
          "employmentType",
          "employeeSupervisor",
          "payGrade",
          "hoursPerWeekMin",
          "hoursPerWeekMax",
          "dateEmployed",
          "",
          "",
        ];
      case 2:
        return [
          "hoursPerWeekMin",
          "hoursPerWeekMax",
          "payGrade",
          "adminRights",
          "groupRights",
        ];
      case 3:
        return ["message", "details"];
      default:
        return [];
    }
  };

  const validateStep = () => {
    try {
      // Extract the fields relevant to the current step
      const fieldsToValidate = getFieldsForCurrentStep();

      // Create a Yup schema for the fields relevant to the current step
      const stepValidationSchema = Yup.object(
        Object.fromEntries(
          Object.entries(validationSchema.fields).filter(([key]) =>
            fieldsToValidate.includes(key)
          )
        )
      );

      // Validate the fields
      stepValidationSchema.validateSync(formData, { abortEarly: false });
      setErrors({});
      return true;
    } catch (error) {
      console.error("Validation Error:", error);

      if (Yup.ValidationError.isError(error)) {
        const formErrors: FormErrors = {};
        error.inner.forEach((err) => {
          if (err.path) {
            formErrors[err.path] = err.message;
            console.error(`Field: ${err.path}, Error: ${err.message}`);
          }
        });
        setErrors(formErrors);
      }
      return false;
    }
  };

  const handleNextStep = () => {
    const isValid = validateStep();
    if (isValid) {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handleSubmit = async (data: FormStepValues) => {};

  const navigate = useNavigate();
  const handleCancelClick = () => {
    navigate(-1);
  };

  return (
    <>
      <div className="px-8">
        <TitleSection
          title="Onboard Employee "
          subtitle="Fill out required information and save."
          // greenButtonText='Save'
          grayButtonText="Cancel"
          // onSaveButtonClick={onSaveButtonClick}
          onCancelButtonClick={handleCancelClick}
          greenButtonIcon=""
          grayButtonIcon=""
          greenBtnLink=""
          grayBtnLink=""
        />
        <ul className="flex space-x-4">
          <li
            className={
              step === 1
                ? "text-greenDark border-b border-greenDark pb-4"
                : "text-gray-600 pb-4"
            }
          >
            Onboarding
          </li>
          <li
            className={
              step === 2
                ? "text-greenDark border-b border-greenDark pb-4"
                : "text-gray-600 pb-4"
            }
          >
            Access
          </li>
          <li
            className={
              step === 3
                ? "text-greenDark border-b border-greenDark pb-4"
                : "text-gray-600 pb-4"
            }
          >
            Message
          </li>
        </ul>
        <div>
          {step === 1 && (
            <OnboardNew
              formData={formData}
              setFormData={setFormData}
              onNextStep={handleNextStep}
              errors={errors}
            />
          )}
          {step === 2 && (
            <AccessFormNew
              formData={formData}
              setFormData={setFormData}
              onNextStep={handleNextStep}
              onPreviousStep={handlePreviousStep}
              errors={errors}
            />
          )}
          {step === 3 && (
            <MessageNew
              formData={formData}
              setFormData={setFormData}
              onSubmit={handleSubmit}
              onPreviousStep={handlePreviousStep}
              errors={errors}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default OnboardingForm;
