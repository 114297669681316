import React, { useState } from "react";
import { activityData } from "../types/types";
import Tabs from "../tabs";
import TextEditor from "../../admin/expenseManagement/forms/sendMessage";
import ActivityPage from "../../admin/expenseManagement/forms/actiivity";

interface CommsProps {
  validId: string;
  handleSubmit: (editorHtml: string, id: string) => void;
  activity: activityData[];
}

const Comms: React.FC<CommsProps> = ({ validId, handleSubmit, activity }) => {
  const [activeTab, setActiveTab] = useState<number>(1);

  const handleTabClick = (tabNumber: number) => {
    setActiveTab(tabNumber);
  };

  return (
    <div>
      <Tabs
        tabs={["Send Message", "Activity"]}
        handleTabClick={handleTabClick}
        activeTab={activeTab}
        activeClassName="border-b border-greenDark mb-5 px-1"
        inactiveClassName="border-b border-greyFade mb-5 px-1"
      />
      <div>
        {activeTab === 1 && (
          <TextEditor id={validId} handleSubmit={handleSubmit} />
        )}
        {activeTab === 2 && <ActivityPage activity={activity} />}
      </div>
    </div>
  );
};

export default Comms;
