import { EarningFormValue } from '../admin/payroll/settings/customEarning';
import {

  status,
  GeneralValues,
  PayScheduleValues,
} from '../utilities/types/types';
import axiosService from './axios/axios';

const API_URL =
  `${process.env.REACT_APP_API_BASE_URL}admin/payroll/`;

export default class Payroll_API {
  //   static async createPaygrade(data: ExpenseValue): Promise<any> {
  //     return await axiosService({
  //       method: 'POST',
  //       data: data,
  //       url: `${API_URL}create`,
  //     });
  //   }
  static async paySchedule(data: PayScheduleValues): Promise<any> {
    return await axiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}update/paySchedule`,
    });
  }
  static async general(data: GeneralValues): Promise<any> {
    return await axiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}update/settings/general`,
    });
  }
  static async getCustomEarning(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}get/custom-earning`,
    });
  }
  static async updateCustomEarning(data: EarningFormValue): Promise<any> {
    return await axiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}update/custom/earning`,
    });
  }
  static async updateCustomstatus(data: status,earningId: string,): Promise<any> {
    return await axiosService({
      method: 'PATCH',
      data: data,
      url: `${API_URL}update/earning-status/${earningId}`,
    });
  }
  static async getGeneral(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}get/general-settings`,
    });
  }
  static async getPaySchedule(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}/get/pay-schedule`,
    });
  }

  static async runPayroll(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}run-payroll`,
    });
  }
  // static async runPaygrade(): Promise<any> {
  //   return await axiosService({
  //     method: 'GET',
  //     url: `${API_URL}get/pay-grade`,
  //   });
  // }
  static async runPaySlip(): Promise<any> {
    return await axiosService({
      method: 'POST',
      url: `${API_URL}run-payslip`,
    });
  }

  //   static async getOneExpense(expenseId: ExpenseId): Promise<any> {
  //     return await axiosService({
  //       method: 'GET',
  //       url: `${API_URL}${expenseId}`,
  //     });
  //   }
  //   static async getApprovedExpense(): Promise<any> {
  //     return await axiosService({
  //       method: 'GET',
  //       url: `${API_URL}`,
  //       params: {
  //         status: 'approved',
  //       },
  //     });
  //   }
  //   static async getReimbursedExpense(): Promise<any> {
  //     return await axiosService({
  //       method: 'GET',
  //       url: `${API_URL}`,
  //       params: {
  //         status: 'reimbursed',
  //       },
  //     });
  //   }
  //   static async getRequestExpense(): Promise<any> {
  //     return await axiosService({
  //       method: 'GET',
  //       url: `${API_URL}`,
  //       params: {
  //         status: 'request',
  //       },
  //     });
  //   }
  //   static async expenseAnalytics(): Promise<any> {
  //     return await axiosService({
  //       method: 'GET',
  //       url: `${API_URL}get/analytics`,
  //     });
  //   }
  //   static async createExpense(): Promise<any> {
  //     return await axiosService({
  //       method: 'POST',
  //       url: `${API_URL}create`,
  //     });
  //   }
  //   // static async editExpense(): Promise<any> {
  //   //   return await axiosService({
  //   //     method: 'PATCH',
  //   //     url: `${API_URL}update/${ExpenseId}`,
  //   //   });
  //   // }
  //   //   static async archive(): Promise<any> {
  //   //     return await axiosService({
  //   //       method: 'GET',
  //   //       url: `${API_URL}get/archive`,
  //   //     });
  //   //   }
  //   static async updateExpense(
  //     data: ExpenseValues,
  //     expenseId: ExpenseId
  //   ): Promise<any> {
  //     return await axiosService({
  //       method: 'PATCH',
  //       data: data,
  //       url: `${API_URL}update/${expenseId}`,
  //     });
  //   }
  //   static async approveExpense(
  //     expenseId: ExpenseId
  //   ): Promise<any> {
  //     return await axiosService({
  //       method: 'PATCH',
  //       url: `${API_URL}approve/${expenseId}`,
  //     });
  //   }
  //   static async reimburseExpense(

  //     expenseId: ExpenseId
  //   ): Promise<any> {
  //     return await axiosService({
  //       method: 'PATCH',
  //       url: `${API_URL}reimburse/${expenseId}`,
  //     });
  //   }
  //   static async rejectExpense(
  //     data: Expense,
  //     expenseId: ExpenseId
  //   ): Promise<any> {
  //     return await axiosService({
  //       method: 'PATCH',
  //       data: data,
  //       url: `${API_URL}update/${expenseId}`,
  //     });
  //   }

  //   static async sendMessage(data: Expense, expenseId: ExpenseId): Promise<any> {
  //     return await axiosService({
  //       method: 'POST',
  //       data: data,
  //       url: `${API_URL}message/${expenseId}`,
  //     });
  //   }
  // }
}
