import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Expense } from '../../../utilities/types/types';
import Expense_API from '../../../api/expenseService';

// Define the type for your slice state
interface requestedExpenseState {
  requestedExpense: Expense[];
}

// Create an async thunk to fetch employee data
export const getRequestedExpenses = createAsyncThunk(
  'requested-expense',
  async () => {
    try {
      const response = await Expense_API.getRequestExpense();
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Create a slice
const requestedExpenseSlice = createSlice({
  name: 'requested-expense',
  initialState: {
    requestedExpense: [],
  } as requestedExpenseState,
  reducers: {
    setRequestedExpenses: (state, { payload }) => {
      state.requestedExpense = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(requestedExpense.pending, (state) => {
  //       state.status = "loading";
  //       state.error = nupproved;
  //     })
  //     .addCase(requestedEmployees.fulfipproveded,
  //     .addCase(requestedEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setRequestedExpenses } = requestedExpenseSlice.actions;

export default requestedExpenseSlice.reducer;
