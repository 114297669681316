import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { Expense, expenseCategories } from '../../../utilities/types/types';
import Expense_API from '../../../api/expenseService';

interface CustomCategoryState {
customCategory: expenseCategories[];
}

export const getCustomCategory = createAsyncThunk('custom-category', async () => {
  try {
    const response = await Expense_API.customCategoryType();
    return response.data;
  } catch (error) {
    throw error;
  }
});

// Create a slice
const customCategorySlice = createSlice({
  name: 'custom-category',
  initialState: {
    customCategory: [],
  } as CustomCategoryState,
  reducers: {
    setCustomCategory: (state, { payload }) => {
      state.customCategory = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(allExpense.pending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setCustomCategory } = customCategorySlice.actions;

export default customCategorySlice.reducer;
