import { CSVEntry } from '../admin/employes/onboarding/bulkEmployees';
import { EmployeeId, FormStepValues, FormStepValues2 } from '../utilities/types/types';
import axiosService from './axios/axios';

const API_URL = `${process.env.REACT_APP_API_BASE_URL}employees/`;

export default class Employee_API {
  static async onboard(data: FormStepValues2): Promise<any> {
    return await axiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}add`,
    });
  }

  static async onboard2(data: FormStepValues): Promise<any> {
    return await axiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}add`,
    });
  }

  static async getAll(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}`,
    });
  }

  static async getEmployee(employeeId: EmployeeId): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}${employeeId}/employee`,
    });
  }

  static async updateEmployee(employeeId: EmployeeId, values: FormStepValues): Promise<any> {
    return await axiosService({
      method: 'PATCH',
      data: values,
      url: `${API_URL}update/${employeeId}`,
    });
  }

  static async analytics(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}analytics`,
    });
  }

  static async active(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}active`,
    });
  }

  static async archive(): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}get/archive`,
    });
  }

  static async getChartData(startDate: string): Promise<any> {
    return await axiosService({
      method: 'GET',
      url: `${API_URL}get/count/month?startDate=${startDate}`,
    });
  }
  

  static async bulkUpload(data: CSVEntry[]): Promise<any> {
    return await axiosService({
      method: 'POST',
      data: data,
      url: `${API_URL}bulk`,
    });
  }
}
