import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import Payroll_API from '../../../api/payroll';
import { status } from '../../../utilities/types/types';

export const updateCustomEarningForm = createAsyncThunk(
  'customEarningForm',
  async (
    { data, earningId }: { data: status; earningId: string },
    thunkAPI
  ) => {
    try {
      const response = await Payroll_API.updateCustomstatus(data, earningId);
      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.response?.data || error.message);
    }
  }
);

const customEarningFormSlice = createSlice({
  name: 'customEarningForm',
  initialState: {
    status: '',
    error: null,
  },
  reducers: {
    setErrors: (state, action) => {
      state.error = action.payload;
    },
    resetForm: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateCustomEarningForm.fulfilled, (state, action) => {
      // Handle successful message sending
      state.status = action.payload;
      state.error = null;
    });
    // builder.addCase(customEarningForm.rejected, (state, action) => {
    //   // Handle rejection or error in sending message
    //   state.error = action.payload;
    // });
  },
});

export const { setErrors, resetForm } = customEarningFormSlice.actions;

export default customEarningFormSlice.reducer;
export type RootState = ReturnType<typeof customEarningFormSlice.reducer>;
