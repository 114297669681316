import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { EmployeeValues } from '../../../utilities/types/types';
import Expense_API from '../../../api/expenseService';

interface AllExpenseCategoryState {
  allExpenseCategory: EmployeeValues[];
}

export const getAllExpenseCategory = createAsyncThunk(
  'all-ExpenseCategory',
  async () => {
    try {
      const response = await Expense_API.getExpenseChart();
      return response.data;
    } catch (error) {
      throw error;
    }
  }
);

// Create a slice
const allExpenseCategorySlice = createSlice({
  name: 'all-ExpenseCategory',
  initialState: {
    allExpenseCategory: [],
  } as AllExpenseCategoryState,
  reducers: {
    setAllExpenseCategory: (state, { payload }) => {
      state.allExpenseCategory = payload;
    },
  },
  // extraReducers: (builder) => {
  //   builder
  //     .addCase(allEmployee.Countpending, (state) => {
  //       state.status = "loading";
  //       state.error = null;
  //     })
  //     .addCase(allEmployees.fulfilled,
  //     .addCase(allEmployees.rejected, (state, action) => {
  //       state.status = "failed";
  //     });
  // },
});
export const { setAllExpenseCategory } = allExpenseCategorySlice.actions;

export default allExpenseCategorySlice.reducer;
