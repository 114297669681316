import React, { ChangeEvent } from 'react';

import { FormikErrors } from 'formik';
import { FormStepValues2 } from '../../../../../utilities/types/types';
import CustomInput from '../../../../../utilities/forms/customInput';
import { useNavigate } from 'react-router-dom';

interface Step3Props {
  onPrevStep: () => void;
  values: FormStepValues2;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void; // Include setFieldValue in the props
  errors?: FormikErrors<FormStepValues2>;
}

const ViewMessageOnboard: React.FC<Step3Props> = ({
  onPrevStep,
  values,
  setFieldValue,
  errors,
}) => {
  const navigate = useNavigate();

  const handleCancelClick = () => {
    navigate(-1);
  };
  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setFieldValue(name, value);
  };
  return (
    <div>
      <div className='pt-8 '>
        <div>
          <h3 className='pb-4 text-lg font-medium'>Message</h3>
        </div>

        <form>
          <CustomInput
            id='message'
            label='Custom Message'
            type='textarea'
            disabled
            name='message'
            value={values.message}
            onChange={handleChange}
            error={errors?.message}
            className='w-full h-40 p-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-gray-500'
            wrapperClassName=''
            placeholder=''
          />
          {/* <CustomInput
            id='details'
            disabled
            label='Send login details'
            type='checkbox'
            name='details'
            checked={values.details}
            onChange={() => setFieldValue('details', !values.details)}
            error={errors?.details}
            className='pr-2'
            wrapperClassName=''
            placeholder=''
          /> */}
          <div className='h-[1px] bg-gray-300 mt-5 '></div>
          <div className='flex justify-between py-9'>
            <button
              type='button'
              onClick={onPrevStep}
              className='border text-grayText border-greyFade mr-4 rounded-lg px-[18px] py-[10px] text-left'
            >
              Previous
            </button>
            <button
              type='button'
              className='border text-grayText border-greyFade mr-4 rounded-lg px-[18px] py-[10px] text-left'
              onClick={handleCancelClick}
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ViewMessageOnboard;
